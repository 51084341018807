import React from "react";
import { Form, InputNumber } from "antd";

import HelpTextOrError from "../../HelpTextOrError";

import { FormFieldProps } from "../interface";
import { normalize, getStepFromDecimal, normalizeProperties } from "../utils";

import "./NumberField.css";

const NumberField: React.FC<FormFieldProps> = ({ className, data }) => {
  const { name, label, properties, initialValue } = data;
  const decimals = properties.decimals || 0;
  const step = getStepFromDecimal(decimals);
  const props = normalizeProperties(properties);

  return (
    <Form.Item
      name={name}
      initialValue={initialValue}
      normalize={normalize}
      rules={[
        {
          type: "number",
          required: properties.required
        }
      ]}
      className={className}
      label={label}
      help={<HelpTextOrError name={name} data={data} />}
    >
      <InputNumber type="number" step={step} precision={decimals} {...props} />
    </Form.Item>
  );
};

export default NumberField;
