import React from "react";
import { Card, Row, theme } from "antd";
import classnames from "classnames";

import { Field } from "../interface";
import FormSidebarItem from "./FormSidebarItem";
import { styles } from "./styles";
import { fieldsCategories, FieldCategory } from "./utils";

interface FormSidebarProps {
  items: Field[];
  className?: string;
}

const FormSidebar: React.FC<FormSidebarProps> = ({ className, items }) => {
  const { token } = theme.useToken();

  const cssStyle = styles(token);

  return (
    <Card
      className={classnames(className)}
      css={cssStyle.sidebar}
      size="small"
      title="Fields"
    >
      {fieldsCategories.map((fieldCategory: FieldCategory) => {
        const itemsByCategory = items.filter(
          (item: Field) => item.category === fieldCategory.key
        );

        if (itemsByCategory.length === 0) {
          return null;
        }

        return (
          <Row key={fieldCategory.key} gutter={[15, 5]}>
            {itemsByCategory.map((item: Field) => (
              <FormSidebarItem key={item.label} item={item} />
            ))}
          </Row>
        );
      })}
    </Card>
  );
};

export default FormSidebar;
