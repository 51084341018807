// tslint:disable max-line-length
import React from "react";

const MultiLine = () => (
  <svg width="1em" height="1em" viewBox="0 0 32 32">
    <path
      fillRule="evenodd"
      fill="currentColor"
      d="M30.718,32.000 L1.282,32.000 C0.575,32.000 -0.000,31.440 -0.000,30.752 L-0.000,1.247 C-0.000,0.559 0.575,-0.000 1.282,-0.000 L30.718,-0.000 C31.425,-0.000 32.000,0.559 32.000,1.247 L32.000,30.753 C32.000,31.440 31.425,32.000 30.718,32.000 ZM29.435,2.494 L2.565,2.494 L2.565,29.505 L29.435,29.505 L29.435,2.494 ZM6.668,7.228 L7.343,7.228 L9.419,7.228 L10.095,7.228 C10.433,7.228 10.706,7.494 10.706,7.822 L10.706,8.654 C10.706,8.982 10.433,9.247 10.095,9.247 L9.419,9.247 L9.419,18.679 L10.095,18.679 C10.433,18.679 10.706,18.945 10.706,19.273 L10.706,20.105 C10.706,20.433 10.433,20.699 10.095,20.699 L9.419,20.699 L7.343,20.699 L6.668,20.699 C6.330,20.699 6.057,20.433 6.057,20.105 L6.057,19.273 C6.057,18.945 6.330,18.679 6.668,18.679 L7.343,18.679 L7.343,9.247 L6.668,9.247 C6.330,9.247 6.057,8.982 6.057,8.654 L6.057,7.822 C6.057,7.494 6.330,7.228 6.668,7.228 Z"
    />
  </svg>
);

export default MultiLine;
