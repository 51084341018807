import { message } from "antd";

import { AxiosError, AxiosResponse } from "axios";

// Manage response errors
export const apiErrors = async (response: any) => {
  const errors = await response.json();
  const errorValues = Object.entries(errors);

  errorValues.forEach((error: any) =>
    message.error(`${String(error[0])} ${String(error[1]).toLowerCase()}`)
  );
};

export const showErrorMessage = (error: unknown) => {
  if (error instanceof AxiosError) {
    const data = error?.response?.data;
    if (data.hasOwnProperty("detail")) {
      return message.error(data.detail);
    } else if ("non_field_errors" in data) {
      data.non_field_errors.forEach((errorMessage) =>
        message.error(errorMessage)
      );
      return;
    } else if (typeof data === "object") {
      const errors = (data: AxiosResponse["data"]) =>
        new AxiosError(error.message, error.code, error.config, error.request, {
          ...(error.response as AxiosResponse),
          data
        });
      Object.entries(data).forEach(([key, value]) => {
        if (Array.isArray(value)) {
          if (typeof value[0] === "string") {
            message.error(
              `${key} - ${value.reduce((prev, next) => prev + ", " + next)}`
            );
          } else {
            showErrorMessage(errors(value));
          }
        } else {
          showErrorMessage(errors(value));
        }
      });
      return;
    }
    return message.error(error.message);
  }

  if (error instanceof Error) {
    return message.error(error.message);
  }

  message.error(String(error));
};
