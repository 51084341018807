import React from "react";
import { useDrag, DragSourceMonitor } from "react-dnd";
import { Col, theme } from "antd";
import { Field } from "../../interface";
import { DragTypes } from "../../utils";
import { mapTypeToIcon } from "../../utils";
import { styles } from "./styles";

interface FormSidebarItemProps {
  item: Field;
}

/**
 * FormSidebarItem render field by its type with label and icon
 */
const FormSidebarItem: React.FC<FormSidebarItemProps> = ({ item }) => {
  const [, drag] = useDrag(() => ({
    type: DragTypes.SIDEBAR_FIELD,
    item: { type: DragTypes.SIDEBAR_FIELD, data: item },
    collect: (monitor: DragSourceMonitor) => ({
      isDragging: monitor.isDragging()
    })
  }));

  const { token } = theme.useToken();

  const cssStyle = styles(token);

  const IconType = mapTypeToIcon(item.type);

  return (
    <Col span={24}>
      <div css={cssStyle.item} ref={drag}>
        <div css={cssStyle.icon}>
          <IconType />
        </div>
        <h4 css={cssStyle.title}>{item.label}</h4>
      </div>
    </Col>
  );
};

export default FormSidebarItem;
