import React from "react";
import { BrowserRouter } from "react-router-dom";
import { ConfigProvider, App as AntdApp } from "antd";
import { QueryClient } from "@tanstack/query-core";
import { QueryClientProvider } from "@tanstack/react-query";
import enUS from "antd/locale/en_US";
import queryString from "query-string";
import { QueryParamProvider } from "use-query-params";
import { ReactRouter6Adapter } from "use-query-params/adapters/react-router-6";

import { ErrorBoundary } from "components";
import { useApiConfig } from "hooks";
import { defaultTheme } from "theme";

import AppRouter from "./AppRouter";

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      refetchOnWindowFocus: false
    }
  }
});

const App: React.FC = () => {
  const apiConfig = useApiConfig();

  return (
    <BrowserRouter>
      <QueryParamProvider
        adapter={ReactRouter6Adapter}
        options={{
          searchStringToObject: queryString.parse,
          objectToSearchString: (value) =>
            queryString.stringify(value, {
              skipEmptyString: true,
              skipNull: true
            })
        }}
      >
        <QueryClientProvider client={queryClient}>
          <ErrorBoundary>
            <AntdApp>
              <ConfigProvider theme={defaultTheme} locale={enUS}>
                <AppRouter apiConfig={apiConfig} />
              </ConfigProvider>
            </AntdApp>
          </ErrorBoundary>
        </QueryClientProvider>
      </QueryParamProvider>
    </BrowserRouter>
  );
};

export default App;
