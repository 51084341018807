import React from "react";
import { Card, Form, Flex } from "antd";
import { NamePath } from "antd/lib/form/interface";

import { CollapseEmitterProvider } from "../../../../contexts";
import { CollapseTriggers } from "../../CollapseTriggers";
import StandaloneFieldError from "../../StandaloneFieldError";

import { FormFieldProps } from "../interface";
import { requiredListRule } from "../utils";

import DnDList from "./DnDList";

interface MultipleComponentFieldProps extends FormFieldProps {
  parentName?: NamePath;
}

const MultipleComponentField: React.FC<MultipleComponentFieldProps> = ({
  data,
  parentName
}) => {
  const { name, label, initialValue = [], properties } = data;
  const required = properties.required;

  return (
    <CollapseEmitterProvider>
      <Card
        style={{ marginBottom: 16 }}
        title={
          <Flex align="center" justify="space-between">
            <div>
              <Form.Item
                className="empty-form-item"
                label={label}
                required={required}
                extra={data.properties?.help_text}
              />
              <StandaloneFieldError name={name} />
            </div>
            <CollapseTriggers />
          </Flex>
        }
      >
        <Form.Item
          dependencies={[name]}
          noStyle
          // eslint-disable-next-line react/no-children-prop
          children={(form) => (
            <Form.List
              initialValue={initialValue as any[]}
              name={name}
              rules={required ? [requiredListRule] : []}
              // eslint-disable-next-line react/no-children-prop
              children={(list, { add, remove }) => (
                <DnDList
                  parentName={parentName}
                  name={name}
                  label={label}
                  listLength={list.length}
                  onAdd={(id) =>
                    add({
                      content_type_id: id,
                      data: {}
                    })
                  }
                  onRemove={remove}
                  onReorder={(order) => {
                    const prevValue: any[] = form.getFieldValue(name);
                    form.setFieldValue(
                      name,
                      prevValue.map((_, i) => prevValue[order[i]])
                    );
                  }}
                />
              )}
            />
          )}
        />
      </Card>
    </CollapseEmitterProvider>
  );
};

export default MultipleComponentField;
