import { Spin } from "antd";
import { useQuery } from "@tanstack/react-query";
import { queries } from "api";
import { ContentTypesContext } from "components/FormBuilder/contexts";

import { showErrorMessage } from "utils/errors";

export interface ContentTypesProviderProps {}

export const ContentTypesProvider = ({
  children
}: React.PropsWithChildren<ContentTypesProviderProps>) => {
  const { data, isLoading } = useQuery({
    ...queries.contentType.formsList(),
    onError: (error) => showErrorMessage(error)
  });

  return (
    <Spin size="large" spinning={isLoading}>
      {data ? (
        <ContentTypesContext.Provider
          value={{
            contentTypes: data
          }}
        >
          {children}
        </ContentTypesContext.Provider>
      ) : (
        <div style={{ height: "50vh" }} />
      )}
    </Spin>
  );
};
