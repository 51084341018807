// tslint:disable max-line-length
export const File = ({ size }: { size?: number }) => (
  <svg
    width={`${size || 1}rem`}
    height={`${size || 1}rem`}
    viewBox="0 0 409.6 409.6"
  >
    <g>
      <g>
        <path
          fillRule="evenodd"
          fill="currentColor"
          d="M256,0H85.335C66.483,0,51.2,15.263,51.2,34.135V375.47c0,18.831,15.283,34.13,34.135,34.13h238.93
			c18.836,0,34.135-15.263,34.135-34.135V102.4L256,0z M256,48.282l54.134,54.118H256V48.282z M324.265,375.465H85.335V34.135
			h136.53V102.4c0,18.836,15.283,34.135,34.135,34.135h68.265V375.465z"
        />
      </g>
    </g>
    <g>
      <g>
        <rect x="119.45" y="307.2" width="102.4" height="34.135" />
      </g>
    </g>
    <g>
      <g>
        <rect x="119.45" y="238.935" width="170.65" height="34.13" />
      </g>
    </g>
    <g>
      <g>
        <rect x="119.45" y="170.65" width="136.535" height="34.135" />
      </g>
    </g>
    <g>
      <g>
        <rect x="119.45" y="68.265" width="68.27" height="68.27" />
      </g>
    </g>
  </svg>
);
