import React from "react";
import ComponentFieldCollapse from "../../ComponentFieldCollapse";
import { FormField } from "../../interface";
import { FormFieldProps } from "../interface";


const ComponentField: React.FC<FormFieldProps> = ({ data }) => {
  const { name, label, initialValue, properties } = data;
  const subFormFields: FormField[] = properties?.mapping;

  if (!subFormFields)
    return (
      <h4>
        Type <code>{data.type}</code> with <code>{name}</code> does not have the
        needed <code>mapping</code> field in <code>properties</code>. Please
        contact support!
      </h4>
    );

  return (
    <ComponentFieldCollapse
      label={label}
      name={name}
      initialValue={initialValue}
      subFormFields={subFormFields}
      className="mb-15"
      items={undefined}
    />
  );
};

export default ComponentField;
