import React, { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { Spin } from "antd";
import { useMutation, useQuery } from "@tanstack/react-query";

import {
  ssoClient,
  setLocalStorageTokens,
  getLocalStorageTokens,
  removeLocalStorageTokens
} from "api/axios";
import { Tokens } from "../types";

export const SessionProvider = ({ children }: React.PropsWithChildren<{}>) => {
  const navigate = useNavigate();

  const { data, status, isFetching } = useQuery({
    queryKey: ["cookie"],
    queryFn: () =>
      ssoClient.get<Tokens>("/authorization/token/cookie/", {
        withCredentials: true
      })
  });

  const {
    mutate,
    data: mutationData,
    status: mutationStatus
  } = useMutation({
    mutationFn: (bodyData: { refresh: string }) =>
      ssoClient.post<Tokens>("/authorization/token/refresh/", bodyData)
  });

  useEffect(() => {
    if (status === "success" && data.data.access && data.data.refresh) {
      setLocalStorageTokens(data.data.access, data.data.refresh);
    }

    if (status === "error") {
      const { refresh } = getLocalStorageTokens();

      if (!!refresh) {
        mutate({ refresh });
      } else {
        removeLocalStorageTokens();
        window.open(`${window.location.origin}/authentication/login`);
      }
    }
  }, [data?.data, mutate, navigate, status]);

  // FIXME: Fix refresh token functionality
  useEffect(() => {
    if (
      mutationStatus === "success" &&
      mutationData.data.access &&
      mutationData.data.refresh
    ) {
      setLocalStorageTokens(
        mutationData.data.access,
        mutationData.data.refresh
      );
    }
    if (mutationStatus === "error") {
      removeLocalStorageTokens();
      window.open(`${window.location.origin}/authentication/login`);
    }
  }, [mutationStatus, mutationData]);

  if (isFetching) {
    return (
      <div className="root-spinner">
        <Spin />
      </div>
    );
  }

  return <>{children}</>;
};
