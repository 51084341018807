export class EventEmitter<EventName extends string, Events extends Record<EventName, (...args: any) => void>> {
  public events: Partial<Record<EventName, Array<Events[EventName]>>>;

  constructor(events?: Partial<Record<EventName, Array<Events[EventName]>>>) {
    this.events = events || {};
  }

  public hasSubscribers(keys?: EventName[]) {
    keys = keys || (Object.keys(this.events) as EventName[]);
    return keys.some((key) => this.events[key]?.length);
  }

  public subscribe<T extends EventName>(name: T, cb: Events[T]) {
    (this.events[name] || (this.events[name] = [])).push(cb);

    return {
      unsubscribe: () => this.unsubscribe(name, cb)
    };
  }
  public unsubscribe<T extends EventName>(name: T, cb: Events[T]) {
    this.events[name] && this.events[name]?.splice(Math.max(0, this.events[name]?.indexOf(cb) || 0), 1);
  }

  public emit<T extends EventName>(name: T, ...args: Parameters<Events[T]>): void {
    (this.events[name] || []).forEach((fn) => fn(...args as []));
  }
}
