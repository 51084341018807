// tslint:disable max-line-length
import React from "react";

const NumberList = () => (
  <svg width="1em" height="1em" viewBox="0 0 32 32">
    <path
      fillRule="evenodd"
      fill="currentColor"
      d="M10.125,28.562 L10.125,26.062 L32.000,26.062 L32.000,28.562 L10.125,28.562 ZM10.125,14.750 L32.000,14.750 L32.000,17.250 L10.125,17.250 L10.125,14.750 ZM10.125,3.125 L32.000,3.125 L32.000,5.625 L10.125,5.625 L10.125,3.125 ZM4.497,26.306 C5.723,27.179 6.294,27.996 6.294,28.875 C6.294,30.598 4.893,32.000 3.169,32.000 L-0.000,32.000 L-0.000,29.500 L3.169,29.500 C3.470,29.500 3.729,29.275 3.784,28.987 C3.552,28.669 2.703,28.056 1.931,27.661 L1.251,27.313 L1.250,25.975 L2.116,25.125 L-0.000,25.125 L-0.000,22.625 L6.375,22.625 L6.375,24.448 L4.488,26.300 C4.491,26.302 4.494,26.304 4.497,26.306 ZM-0.000,18.550 L0.360,18.184 C0.368,18.176 1.188,17.344 2.042,16.376 C3.339,14.906 3.696,14.278 3.786,14.088 C3.737,13.795 3.475,13.562 3.169,13.562 L-0.000,13.562 L-0.000,11.062 L3.169,11.062 C4.893,11.062 6.294,12.464 6.294,14.187 C6.294,14.855 5.919,15.730 4.090,17.832 C4.059,17.868 4.029,17.903 3.998,17.937 L6.375,17.937 L6.375,20.437 L-0.000,20.437 L-0.000,18.550 ZM1.937,-0.000 L4.437,-0.000 L4.437,8.750 L1.937,8.750 L1.937,-0.000 Z"
    />
  </svg>
);

export default NumberList;
