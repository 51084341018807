import { useRef, useState } from "react";
import { useNavigate } from "react-router-dom";
import { message, Table, MenuProps } from "antd";
import { useMutation, useQueryClient } from "@tanstack/react-query";

import api, { queries } from "api";
import { ContentType } from "types";
import { contentTypeMessages, showErrorMessage } from "utils";

import { ContentTypesModalForm } from "../ContentTypesModalForm";
import { ContentTypeModalState } from "../ContentTypesModalForm";

import { getTableColumns } from "./tableColumns";
import {
  normalizeContentTypes,
  TableContentType,
  showDeleteConfirm,
  showCountDeleteConfirm
} from "./utils";

interface Props {
  contentTypes: ContentType[];
  fetchContentTypes: () => void;
  loading?: boolean;
}

export const ContentTypesTable = ({
  contentTypes,
  fetchContentTypes,
  loading
}: Props) => {
  const navigate = useNavigate();
  const queryClient = useQueryClient();

  const [modalOpen, setModalOpen] = useState(false);
  const modalState = useRef<ContentTypeModalState>();

  const deleteMutation = useMutation(api.contentType.remove, {
    onSuccess: () => {
      queryClient.invalidateQueries(queries.contentType.all().queryKey);
      queryClient.invalidateQueries(queries.content.pinned().queryKey);
      message.success(contentTypeMessages.contentTypeSuccess("deleted"));
    },
    onError: (error) => showErrorMessage(error)
  });

  const checkMutation = useMutation(
    (contentType: TableContentType) => api.contentType.check(contentType.id),
    {
      onSuccess: async (response, contentType) => {
        if (response.count === 0) {
          showDeleteConfirm(contentType, deleteMutation.mutate);
        } else {
          showCountDeleteConfirm(contentType, response.count);
        }
      },
      onError: (error) => showErrorMessage(error)
    }
  );

  // Content Types table actions
  const getActionColumnMenu = (
    contentType: TableContentType
  ): MenuProps["items"] => [
    {
      label: "Edit",
      key: "edit",
      onClick: () => {
        modalState.current = {
          action: "edit",
          ...contentType
        };
        setModalOpen(true);
      }
    },
    {
      label: "Duplicate",
      key: "duplicate",
      onClick: () => {
        modalState.current = {
          action: "duplicate",
          ...contentType
        };
        setModalOpen(true);
      }
    },
    {
      label: "Delete",
      key: "delete",
      onClick: () => checkMutation.mutate(contentType)
    },
    {
      type: "divider"
    },
    {
      label: "Add content",
      key: "content",
      onClick: () => {
        navigate(`/content/create/${contentType.id}`);
      }
    }
  ];

  // Table columns and data
  const columns = getTableColumns(getActionColumnMenu);
  const data = normalizeContentTypes(contentTypes);

  return (
    <>
      <Table
        loading={loading || deleteMutation.isLoading}
        columns={columns}
        dataSource={data}
        pagination={false}
        bordered
      />
      <ContentTypesModalForm
        isOpen={modalOpen}
        onClose={() => {
          if (modalState?.current?.action === "edit") {
            fetchContentTypes();
          }
          setModalOpen(false);
        }}
        modalState={modalState.current}
      />
    </>
  );
};
