import { useState, useCallback } from "react";
import { useNavigate, useParams } from "react-router-dom";

import {
  useMutation,
  UseMutationResult,
  useQuery
} from "@tanstack/react-query";
import api, { queries } from "api";
import { AccessEntity, PermissionEntity } from "types";
import { showErrorMessage } from "utils";

export interface AccessFormParams {
  id?: string;
}

export enum StatusType {
  PENDING,
  RESOLVED,
  REJECTED
}

interface StateProps {
  error?: Error;
  data: AccessEntity[];
}

interface AccessEntitiesProps extends StateProps {
  handleChange: (permissions: PermissionEntity[], category: string) => void;
  handleSave: UseMutationResult<
    AccessEntity[] | undefined,
    any,
    AccessEntity[],
    unknown
  >;
  isError: boolean;
  isLoading: boolean;
  isFetching: boolean;
}

export const useAccessEntities = (): AccessEntitiesProps => {
  const navigate = useNavigate();
  const { id } = useParams<keyof AccessFormParams>();
  const [state, setState] = useState<StateProps>({ data: [] });

  const { isLoading, isFetching, isError } = useQuery({
    ...queries.access.users.byId(id),
    onSuccess: (data: AccessEntity[]) => setState({ data: data! }),
    onError: (error: Error) => setState({ error: error, data: [] })
  });

  const handleChange = useCallback(
    (permissions: PermissionEntity[], category: string) => {
      setState((state) => {
        const data: AccessEntity[] = state.data?.map((item: AccessEntity) => {
          return item.category === category ? { ...item, permissions } : item;
        });

        return { ...state, data };
      });
    },
    []
  );

  const handleSave = useMutation(
    (newData: AccessEntity[]) => api.access.updateUser(id, newData),
    {
      onError: (error) => showErrorMessage(error),
      onMutate: () =>
        setState((prevState) => ({ ...prevState, status: StatusType.PENDING })),
      onSuccess: () => {
        setState((prevState) => ({
          ...prevState,
          status: StatusType.RESOLVED
        }));
      },
      onSettled: () => navigate("/access")
    }
  );

  return { ...state, handleChange, handleSave, isError, isLoading, isFetching };
};
