import { NamePath } from "antd/lib/form/interface";
import { nanoid } from "nanoid";
import {
  Checkbox,
  Date,
  Email,
  MultiLine,
  NumberList,
  Phone,
  SingleLine,
  Url,
  SingleImage,
  File,
  Select,
  Layout,
  MultipleReference,
  Repeater,
  Component
} from "assets/svg";
import { Field, FormField } from "./interface";

// Drag and Drop types
export enum DragTypes {
  SIDEBAR_FIELD = "Field",
  PREVIEW_FIELD = "Preview Field"
}

export interface DragItem {
  type: DragTypes;
  data: Field;
}

// Normalized form items
export const normalizeFormItems = (
  formItems: FormField[] = [],
  sidebarItems: Field[] = []
) => {
  return formItems.map((formItem: FormField) => {
    // Find form properties by type
    const itemByType = sidebarItems.find(
      (sidebarField: Field) => sidebarField.type === formItem.type
    );

    const propertiesForm =
      itemByType && itemByType.hasOwnProperty("properties_form")
        ? itemByType.properties_form
        : [];

    const fieldTypeLabel =
      itemByType && itemByType.label ? itemByType.label : formItem.label;

    return {
      ...formItem,
      id: nanoid(), // specify unique id for drag and drop the same type
      fieldType: fieldTypeLabel, // field title from sidebar of fields
      isNameDisabled: true, // set disabled name on editing
      properties_form: [...propertiesForm]
    };
  });
};

// Map type to svg
const typeToSvg: Record<string, React.ComponentType> = {
  Phone,
  Email,
  Date,
  File,
  Select,
  MultipleReference,
  Number: NumberList,
  Boolean: Checkbox,
  String: SingleLine,
  Text: MultiLine,
  DateTime: Date,
  URL: Url,
  Image: SingleImage,
  Reference: Layout,
  Repeater,
  Component
};

export const mapTypeToIcon = (type: string) => {
  // FIXME: Add default icon for new types.
  return typeToSvg.hasOwnProperty(type) ? typeToSvg[type] : typeToSvg.String;
};

export const createFieldClassName = (field: FormField) =>
  `form-field__item field-item__${String(field.type).toLowerCase()}`;

export const makeNamePath = (...paths: (NamePath | undefined)[]) =>
  paths
    .map((p) => (p !== undefined ? (p instanceof Array ? p : [p]) : []))
    .flat(1);
