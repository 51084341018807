import React from "react";
import { AutoComplete, Form } from "antd";

import { regexpFromString } from "components/FormBuilder/utils/regexp";

import HelpTextOrError from "../../HelpTextOrError";
import { FormFieldProps } from "../interface";
import { normalize } from "../utils";

const EmailField: React.FC<FormFieldProps> = ({ data, className }) => {
  const { name, label, properties, initialValue } = data;

  return (
    <Form.Item
      name={name}
      initialValue={initialValue}
      normalize={normalize}
      rules={[
        {
          required: properties.required,
          pattern: properties.regex
            ? regexpFromString(properties.regex)
            : undefined
        },
        { type: "email" }
      ]}
      className={className}
      label={label}
      help={<HelpTextOrError name={name} data={data} />}
    >
      <AutoComplete
        placeholder={properties.placeholder}
        options={properties.default ? [{ value: properties.default }] : []}
      />
    </Form.Item>
  );
};

export default EmailField;
