import { createContext } from "react";
import { ApiConfig } from "types";
import {
  FormBuilderContextValue,
  FormGeneratorContextValue
} from "./interface";

export const APIContext = createContext<ApiConfig | undefined>(undefined);

export const FormBuilderContext = createContext<FormBuilderContextValue>({});

export const FormGeneratorContext = createContext<FormGeneratorContextValue>(
  {}
);
