import React, {
  createContext,
  PropsWithChildren,
  useContext,
  useEffect,
  useState
} from "react";
import { array, mixed, object, string } from "yup";

import { FormBuilderState } from "./store";
import { FormField } from ".";

export interface FieldsValidationContextValue {
  validate: () => Promise<FormField[] | null>;
  error: FormField | null;
}

interface FieldsValidationProps {
  state: FormBuilderState;
  setValidation?: React.Dispatch<
    React.SetStateAction<FieldsValidationContextValue | undefined>
  >; // For repeater
  onChangeItem: (item: FormField, watchChange?: boolean) => void;
}

const customRequired = (type: string) => {
  const required = {
    Repeater: {
      mapping: array().required()
    }
  };

  return required[type as keyof typeof required];
};

export const FieldsValidationContext =
  createContext<FieldsValidationContextValue>({
    validate: () => Promise.resolve(null),
    error: null
  });

export const FieldsValidation: React.FC<
  PropsWithChildren<FieldsValidationProps>
> = ({ state, setValidation, onChangeItem, ...props }) => {
  const [error, setError] =
    useState<FieldsValidationContextValue["error"]>(null);

  const propertiesSchema = (item: FormField) => {
    const values = item.properties_form || [];
    const requiredValue = values
      .flat()
      .map(({ name, properties }) => [
        name,
        properties?.required ? mixed().required() : mixed()
      ]);
    const returnSchema = Object.fromEntries(requiredValue);

    return returnSchema;
  };

  const validation = React.useCallback(
    (): Promise<FormField>[] =>
      state.formItems.map(async (formItem: FormField) => {
        const {
          id,
          fieldType,
          properties_form,
          isNameDisabled,
          type,
          ...item
        } = formItem;

        const schema = object({
          name: string().required(),
          label: string().required(),
          properties: object({
            ...propertiesSchema(formItem),
            ...customRequired(type)
          }).required()
        });

        try {
          return await schema.validate(item);
        } catch {
          return await Promise.reject(formItem);
        }
      }),
    [state.formItems]
  );

  const validate = React.useCallback(async () => {
    try {
      setError(null);
      return await Promise.all(validation());
    } catch (error) {
      const formItem = error as FormField;
      setError(formItem);
      return await Promise.reject(formItem);
    }
  }, [validation]);

  useEffect(() => {
    error && onChangeItem(error);
  }, [error, onChangeItem]);

  useEffect(() => {
    setValidation?.({ validate, error });
  }, [state, error, setValidation, validate]);

  return (
    <FieldsValidationContext.Provider value={{ validate, error }} {...props} />
  );
};

export const useValidation = () => useContext(FieldsValidationContext);
