import React from "react";
import { Form, DatePicker } from "antd";

import HelpTextOrError from "../../HelpTextOrError";

import { FormFieldProps } from "../interface";
import { normalizeDateTime } from "../utils";

const DateTimeField: React.FC<FormFieldProps> = ({ data, className }) => {
  const { name, label, properties, initialValue } = data;
  const defaultValue = normalizeDateTime(
    initialValue,
    properties.default_set_now
  );

  return (
    <Form.Item
      name={name}
      initialValue={defaultValue}
      rules={[
        {
          required: properties.required
        }
      ]}
      className={className}
      label={label}
      help={<HelpTextOrError name={name} data={data} />}
    >
      <DatePicker
        showTime
        format="DD/MM/YYYY HH:mm"
        placeholder={properties.placeholder}
      />
    </Form.Item>
  );
};

export default DateTimeField;
