import { useEffect, useState, useCallback, ChangeEvent } from "react";
import { useNavigate } from "react-router-dom";
import { Form, Input, Select, Checkbox, message, Modal } from "antd";
import { useMutation, useQueryClient } from "@tanstack/react-query";

import api, { queries } from "api";
import { MODAL_WIDTH } from "app-constants";
import { CreateContentType, EditContentType } from "types";
import {
  StatusType,
  statuses,
  contentTypeMessages,
  contentTypeCategories,
  showErrorMessage,
  ContentTypeCategory,
  capitalize
} from "utils";

import { TableContentType } from "./ContentTypesTable/utils";

const { Option } = Select;

export interface ContentTypesFormState {
  action: string;
  id: string;
  name?: string;
  status?: StatusType;
  category?: `${ContentTypeCategory}`;
}

export interface ContentTypeModalState extends TableContentType {
  action: "edit" | "duplicate";
  status: StatusType;
}

interface Props {
  isOpen: boolean;
  onClose: () => void;
  modalState?: ContentTypeModalState;
}

const formItemLayout = {
  labelCol: {
    xs: { span: 24 },
    sm: { span: 4 },
    md: { span: 4 },
    lg: { span: 4 },
    xl: { span: 4 }
  },
  wrapperCol: {
    xs: { span: 24 },
    sm: { span: 21 },
    md: { span: 21 },
    lg: { span: 21 },
    xl: { span: 21 }
  }
};

/**
 * TypesForm is used to create new content type
 */
export const ContentTypesModalForm = ({
  isOpen,
  onClose,
  modalState
}: Props) => {
  const navigate = useNavigate();
  const queryClient = useQueryClient();
  const [state, setState] = useState<ContentTypesFormState>({
    action: "create",
    id: ""
  });
  const [error, setError] = useState(false);

  const [form] = Form.useForm();

  const invalidateQueries = () => {
    queryClient.invalidateQueries(queries.contentType.all().queryKey);
    queryClient.invalidateQueries(queries.content.pinned().queryKey);
  };

  const editMutation = useMutation(
    ({
      contentTypeId,
      data
    }: {
      contentTypeId: string;
      data: EditContentType;
    }) => api.contentType.update(contentTypeId, data),
    {
      onSuccess: () => {
        invalidateQueries();
        navigate("/content-types");
        message.success(contentTypeMessages.contentTypeSuccess("edited"));
        onClose();
      },
      onError: (error) => showErrorMessage(error)
    }
  );

  const duplicateMutation = useMutation(
    ({
      contentTypeId,
      data
    }: {
      contentTypeId: string;
      data: CreateContentType;
    }) => api.contentType.duplicate(contentTypeId, data),
    {
      onSuccess: (response) => {
        invalidateQueries();
        navigate(`/content-types/${response.id}`);
        message.success(contentTypeMessages.contentTypeSuccess("duplicated"));
        onClose();
      },
      onError: (error) => showErrorMessage(error)
    }
  );

  const createMutation = useMutation(api.contentType.create, {
    onSuccess: (response) => {
      invalidateQueries();
      navigate(`/content-types/${response.id}`);
      message.success(contentTypeMessages.contentTypeSuccess("created"));
      onClose();
    },
    onError: (error) => showErrorMessage(error)
  });

  const contentTypeRequests = useCallback(
    async (values: CreateContentType) => {
      const { id, action } = state;
      switch (action) {
        case "edit":
          {
            const data: EditContentType = {
              name: values.name,
              description: values.description,
              status: values.status,
              pined: values.pined,
              category: values.category
            };

            editMutation.mutate({ contentTypeId: id, data: data });
          }
          break;

        case "duplicate":
          duplicateMutation.mutate({ contentTypeId: id, data: values });
          break;

        case "create":
        default: {
          createMutation.mutate(values);
        }
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [state]
  );

  const formSubmitHandler = useCallback(
    (values: CreateContentType) => {
      contentTypeRequests(values);
    },
    [contentTypeRequests]
  );

  const handleInputName = (e: ChangeEvent<HTMLInputElement>) => {
    const { value } = e.currentTarget;

    form.setFieldValue("name", value);

    if (state.action !== "edit") {
      const apiValue = value
        .split(" ")
        .map((item: string) => String(item).toLowerCase())
        .join("_");

      form.setFieldsValue({
        code_name: apiValue
      });
    }
  };

  useEffect(
    () => {
      if (modalState && modalState.action) {
        // Set action which is performed - edit or duplicate
        setState({
          id: modalState.id,
          name: modalState.name,
          action: modalState.action,
          status: modalState.status
        });

        // Set initial form fields value on editing or duplicating
        form.setFieldsValue({
          name: modalState.name,
          code_name: modalState.code_name,
          status: modalState.status,
          description: modalState.description,
          pined: modalState.pined,
          category: modalState.category
        });
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [modalState]
  );

  // Page title
  const pageTitle =
    state.action !== "create"
      ? `${capitalize(state.action)} "${state.name}"`
      : "Create new content type";

  return (
    <Modal
      open={isOpen}
      title={pageTitle}
      onCancel={() => onClose()}
      onOk={form.submit}
      okButtonProps={{
        disabled: error,
        loading:
          duplicateMutation.isLoading ||
          createMutation.isLoading ||
          editMutation.isLoading
      }}
      okText={capitalize(state.action)}
      width={MODAL_WIDTH}
    >
      <Form
        form={form}
        className="types-form"
        layout="horizontal"
        onFinish={formSubmitHandler}
        labelAlign="right"
        {...formItemLayout}
      >
        <Form.Item shouldUpdate noStyle>
          {({ getFieldsError }) => {
            const error = getFieldsError().some(
              (fieldError) => fieldError.errors.length
            );
            setError(error);
            return null;
          }}
        </Form.Item>
        <Form.Item noStyle dependencies={["name"]}>
          {(form) => (
            <Form.Item
              name="name"
              label="Name"
              rules={[
                {
                  required: true,
                  message: "Please input content type name!"
                }
              ]}
            >
              <Input
                type="text"
                placeholder="Enter name..."
                value={form.getFieldValue("name")}
                onChange={handleInputName}
              />
            </Form.Item>
          )}
        </Form.Item>

        <Form.Item
          name="code_name"
          label="API Name"
          rules={[
            {
              required: true,
              message: "Please input API name!"
            },
            {
              pattern: /^([a-z_]+)$/gm,
              message:
                "API name can contain only lowercase letters and underscore!"
            }
          ]}
        >
          <Input
            type="text"
            placeholder="Enter API name..."
            disabled={state.action === "edit"}
          />
        </Form.Item>

        <Form.Item name="status" label="Status" initialValue="draft">
          <Select placeholder="Please select status">
            {statuses.map((item) => (
              <Option key={item.value} value={item.value}>
                {item.label}
              </Option>
            ))}
          </Select>
        </Form.Item>

        <Form.Item name="category" label="Category" initialValue="collection">
          <Select placeholder="Please select category">
            {contentTypeCategories.map((item) => (
              <Option key={item.value} value={item.value}>
                {item.label}
              </Option>
            ))}
          </Select>
        </Form.Item>

        <Form.Item name="description" label="Description" initialValue="">
          <Input.TextArea placeholder="Enter description..." />
        </Form.Item>

        <Form.Item
          name="pined"
          label="Pined"
          initialValue={false}
          valuePropName="checked"
          style={{ margin: 0 }}
        >
          <Checkbox />
        </Form.Item>
      </Form>
    </Modal>
  );
};
