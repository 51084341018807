import { useNavigate } from "react-router-dom";
import { Button, Typography, Row, Col } from "antd";
import { useQuery } from "@tanstack/react-query";

import { queries } from "api";
import { Add } from "assets/svg";
import { Form } from "types";
import { showErrorMessage } from "utils";

import { FormsPageTable } from "../components";

export const FormsPage = () => {
  const navigate = useNavigate();

  const { data, isLoading } = useQuery({
    ...queries.forms.all(),
    onError: showErrorMessage
  });

  return (
    <>
      <Row className="mb-20" align="middle" justify="space-between">
        <Col>
          <Typography.Title className="mb-0" level={5}>{`Forms (${
            data?.length || 0
          })`}</Typography.Title>
        </Col>
        <Col>
          <Button
            type="primary"
            onClick={() => navigate("create")}
            icon={<Add />}
          >
            Add form
          </Button>
        </Col>
      </Row>

      <FormsPageTable
        handleClick={(form: Form) => navigate(`${form.id}`)}
        data={data || []}
        loading={isLoading}
      />
    </>
  );
};
