// Enum statuses
export enum Status {
  ALL = "all",
  DRAFT = "draft",
  PUBLISHED = "published"
}

// Status type is used for api, status from api can be only draft or published
export type StatusType = Status.PUBLISHED | Status.DRAFT;

interface StatusEntity {
  value: StatusType | Status.ALL;
  label: string;
}

export const statuses: StatusEntity[] = [
  {
    value: Status.PUBLISHED,
    label: "Published"
  },
  {
    value: Status.DRAFT,
    label: "Draft"
  }
];

// Filter By Status
export const filterByStatus: StatusEntity[] = [
  {
    value: Status.ALL,
    label: "All"
  },
  ...statuses
];

export const statusesType = {
  [Status.DRAFT]: { label: "Draft", color: "default" },
  [Status.PUBLISHED]: { label: "Published", color: "success" }
};
