import FormGenerator from "./FormGenerator";
import FormMediaUpload from "./FormMediaUpload";

// Export interfaces
export { FormMediaUpload };

export { FormMediaSelectModal } from "./FormMediaSelect";
export { MediaSwitch } from "./MediaSwitch";

export default FormGenerator;
