import { useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import {
  Button,
  Form,
  Input,
  Select,
  message,
  Spin,
  Divider,
  Space
} from "antd";
import { CloseOutlined, PlusOutlined } from "@ant-design/icons";
import { useMutation, useQueries, useQueryClient } from "@tanstack/react-query";

import api, { queries } from "api";
import { CKEDITOR_FILE_PATH } from "app-constants";
import { FormGenerator } from "components/FormBuilder";
import { useApiConfig } from "hooks";
import { CreateEditForm, Form as FormType, FormAction } from "types";
import { apiValue, ActionValueProps, formData } from "./utils";

const { Option } = Select;

export const FormsCreateUpdate = () => {
  const navigate = useNavigate();
  const [form] = Form.useForm();
  const [actions, setActions] = useState<ActionValueProps[]>([]);
  const { id } = useParams();
  const queryClient = useQueryClient();
  const apiConfig = useApiConfig();

  const ckeditorFilePath =
    process.env.PUBLIC_URL + "/" + apiConfig.appToken + CKEDITOR_FILE_PATH;

  const formQueries = useQueries({
    queries: [
      { ...queries.contentType.all() },
      { ...queries.actions.all() },
      {
        enabled: !!id,
        ...queries.forms.byId(id),
        onSuccess: (form: FormType) => {
          const actions = form.actions.map(
            (action: FormAction, index: number) => {
              return { key: index, value: action.code_name };
            }
          );
          setActions(actions);
        },
        cacheTime: 0
      }
    ]
  });
  const [contentTypeQuery, actionsQuery, formQuery] = formQueries;

  const mutation = useMutation(
    (value: CreateEditForm) => {
      return !!id ? api.forms.update(id, value) : api.forms.create(value);
    },
    {
      onSuccess: () => {
        queryClient.invalidateQueries(queries.forms.all().queryKey);
        if (!!id)
          queryClient.invalidateQueries(queries.forms.responses(id).queryKey);
        message.success(`Form was ${id ? "edited" : "created"} successfully!`);

        if (!id) navigate("/forms");
      }
    }
  );

  const setApiName = () => {
    if (!id) {
      const title = form.getFieldValue("title") || "";
      const value = apiValue(title);

      form.setFieldValue("code_name", value);
    }
  };

  if (formQueries.some((query) => query.isLoading && query.isFetching)) {
    return (
      <div className="page-spin">
        <Spin spinning />
      </div>
    );
  }

  return (
    <div className="page-form">
      <Form
        form={form}
        onFinish={(values) => {
          mutation.mutate(values);
        }}
        initialValues={formQuery.data || {}}
        labelCol={{ span: 24, offset: 0 }}
      >
        <Form.Item
          name="title"
          label="Name"
          rules={[
            {
              required: true,
              message: "Please input form name!"
            }
          ]}
        >
          <Input
            type="text"
            placeholder="Enter name..."
            onChange={setApiName}
          />
        </Form.Item>

        <Form.Item
          name="code_name"
          label="API Name"
          rules={[
            {
              required: true,
              message: "Please input API name!"
            },
            {
              pattern: /^([a-z_]+)$/gm,
              message:
                "API name can contain only lowercase letters and underscore!"
            }
          ]}
        >
          <Input type="text" placeholder="Enter API name..." disabled={!!id} />
        </Form.Item>

        <Form.Item
          name="content_type"
          label="Content Type"
          rules={[{ required: true, message: "Please select content type!" }]}
        >
          <Select
            showSearch
            placeholder="Please select content type"
            loading={contentTypeQuery.isLoading}
          >
            {contentTypeQuery.data?.map(({ id, name }) => (
              <Option key={id} value={id}>
                {name}
              </Option>
            ))}
          </Select>
        </Form.Item>

        <Form.Item label="Actions" />
        <Form.List
          name="actions"
          rules={[
            {
              validator: async (_, actions) => {
                if (!actions || actions.length < 1) {
                  return Promise.reject(
                    new Error("Please add at least one action!")
                  );
                }
              }
            }
          ]}
        >
          {(fields, { add, remove }, { errors }) => (
            <>
              {fields.map(({ key, name, ...restField }) => (
                <div key={key}>
                  <>
                    <Divider />

                    {fields.length > 1 && (
                      <div className="d-flex justify-content-end">
                        <CloseOutlined onClick={() => remove(name)} />
                      </div>
                    )}

                    <Form.Item
                      {...restField}
                      name={[name, "code_name"]}
                      label="Type"
                      rules={[
                        {
                          required: true,
                          message: "Please select action!"
                        }
                      ]}
                    >
                      <Select
                        placeholder="Please select action"
                        loading={actionsQuery.isLoading}
                        onChange={(e) => {
                          const action = actions.find((x) => x.key === key);
                          if (action) {
                            action.value = e;
                            setActions([...actions]);
                          } else {
                            setActions([...actions, { key, value: e }]);
                          }
                        }}
                      >
                        {actionsQuery.data?.map(({ label, code_name }) => (
                          <Option key={code_name} value={code_name}>
                            {label}
                          </Option>
                        ))}
                      </Select>
                    </Form.Item>
                  </>

                  {actionsQuery.data && !!actions.length && (
                    <>
                      {!!formData(actions, key, actionsQuery?.data)?.length && (
                        <Form.Item>
                          {/* TODO: this causes error in console because of
                          nested forms. We need a new component that renders
                          only form inputs without the form. */}
                          <FormGenerator
                            formId={"form"}
                            formData={
                              formQuery.data?.actions[name]?.data ?? null
                            }
                            formSchema={
                              formData(actions, key, actionsQuery?.data) || []
                            }
                            onValuesChange={(_, values) => {
                              form.setFieldValue(
                                ["actions", name, "data"],
                                values
                              );
                            }}
                            apiConfig={apiConfig}
                            formConfig={{ ckeditorFilePath }}
                          />
                        </Form.Item>
                      )}
                    </>
                  )}
                </div>
              ))}
              <Form.Item>
                <Button
                  type="dashed"
                  onClick={() => add()}
                  block
                  icon={<PlusOutlined />}
                >
                  Add action
                </Button>
                <Form.ErrorList errors={errors} />
              </Form.Item>
            </>
          )}
        </Form.List>

        <Space align="end" className="w-100 justify-content-end">
          {!id && <Button onClick={() => navigate("/forms")}>Cancel</Button>}
          <Button type="primary" htmlType="submit">
            {id ? "Update" : "Save"}
          </Button>
        </Space>
      </Form>
    </div>
  );
};
