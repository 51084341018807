import { Table } from "antd";
import { useQuery } from "@tanstack/react-query";

import { queries } from "api";
import { showErrorMessage } from "utils";

import { getTableColumns } from "./tableColumns";
import { normalizeUsers } from "./utils";

export const AccessUsersTable = () => {
  const { data: fetchData, isLoading } = useQuery({
    ...queries.access.users.all(),
    onError: (error) => showErrorMessage(error)
  });

  // Table columns and data
  const columns = getTableColumns();
  const data = normalizeUsers(fetchData ? fetchData.results : []);

  return (
    <Table
      loading={isLoading}
      columns={columns}
      dataSource={data}
      pagination={{ hideOnSinglePage: true }}
      bordered
    />
  );
};
