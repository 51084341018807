import React from "react";
import { Form, Input } from "antd";

import HelpTextOrError from "../../HelpTextOrError";
import { FormFieldProps } from "../interface";

const PhoneField: React.FC<FormFieldProps> = ({ data, className }) => {
  const { name, label, properties, initialValue } = data;

  return (
    <Form.Item
      name={name}
      initialValue={initialValue}
      rules={[
        {
          required: properties.required
        }
      ]}
      className={className}
      label={label}
      help={<HelpTextOrError name={name} data={data} />}
    >
      <Input type="tel" placeholder={properties.placeholder} />
    </Form.Item>
  );
};

export default PhoneField;
