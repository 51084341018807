import React, { useState } from "react";

import { Form } from "antd";
import { NamePath } from "antd/lib/form/interface";

export interface StandaloneFieldErrorProps {
  name: NamePath;
  renderErrors?: (errors: string[]) => React.ReactNode;
}

/**
 * Display error of some field in other part of the form
 */
const StandaloneFieldError: React.FC<StandaloneFieldErrorProps> = ({
  name,
  renderErrors = (errors) =>
    errors?.map((error, idx) => (
      <div key={idx} className="ant-form-item-explain-error">
        <small>{error}</small>
      </div>
    ))
}) => {
  const [, setPrevErrors] = useState<string[]>([]); // using for rerender

  return (
    <Form.Item
      shouldUpdate
      noStyle
      // eslint-disable-next-line react/no-children-prop
      children={(form) => {
        if (form.isFieldValidating(name)) {
          setTimeout(() => {
            // Wait field to finish validating, then rerender
            form
              .validateFields([name])
              .catch((e) => {
                console.error(e);
              }) // avoid errors in console
              .finally(() => {
                setPrevErrors(form.getFieldError(name));
              });
          }, 0); // Timeout to not update the state while rendering
        }

        const errors = form.getFieldError(name);
        return renderErrors(errors);
      }}
    />
  );
};

export default StandaloneFieldError;
