import React, { useContext, useEffect, useState } from "react";

import { EventEmitter } from "../utils";

interface CollapseEventEmitterEvents {
  collapse: () => void;
  expand: () => void;
  /**
   * When the collapses are subscribing/unsubscribing we need a way
   * to notify the collapse trigger to update (they hide or disable
   * depending on if there are or not subscribers)
   */
  "subscription-change": () => void;
}

export const CollapseEmitterContext = React.createContext(
  // Default global instance
  new EventEmitter<
    keyof CollapseEventEmitterEvents,
    CollapseEventEmitterEvents
  >()
);

export const CollapseEmitterProvider: React.FC<React.PropsWithChildren> = ({
  children
}) => {
  const parentEmitter = useContext(CollapseEmitterContext);
  const [emitter] = useState(
    new EventEmitter<
      keyof CollapseEventEmitterEvents,
      CollapseEventEmitterEvents
    >()
  );

  // Subscribe to parent and emit event to children
  useEffect(() => {
    const subscriptions = [
      parentEmitter.subscribe("collapse", () => {
        emitter.emit("collapse");
      }),
      parentEmitter.subscribe("expand", () => {
        emitter.emit("expand");
      })
    ];
    parentEmitter.emit("subscription-change");

    return () => {
      subscriptions.forEach((sub) => sub.unsubscribe());
      parentEmitter.emit("subscription-change");
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <CollapseEmitterContext.Provider value={emitter}>
      {children}
    </CollapseEmitterContext.Provider>
  );
};
