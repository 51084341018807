import { Suspense } from "react";
import { Navigate, Route, Routes, useLocation } from "react-router-dom";

import { CKEDITOR_FILE_PATH } from "app-constants";
import { AppLayout } from "components";
import { AccessForm } from "features/access/pages/AccessForm";
import { AccessPage } from "features/access/pages/AccessPage";
import { ContentFormPage } from "features/content/pages/ContentFormPage";
import { ContentListPage } from "features/content/pages/ContentListPage";
import { ContentTypeProvider } from "features/content-types/contexts";
import { ContentTypePage } from "features/content-types/pages/ContentTypePage";
import { ContentTypesPage } from "features/content-types/pages/ContentTypesPage";
import { FormsEditPage, FormsCreatePage } from "features/forms/pages";
import { FormsPage } from "features/forms/pages/FormsPage";
import LocalesPage from "features/locales/pages/LocalesPage";
import { MediaForm } from "features/media/pages/MediaForm";
import { MediaPage } from "features/media/pages/MediaPage";
import { SettingsPage } from "features/settings/pages/SettingsPage";
import { withCKEditorMediaRoute } from "hoc";
import { SessionProvider } from "./SessionProvider";

const AppRouter = () => {
  const location = useLocation();

  const background = location.state && location.state.background;

  if ([CKEDITOR_FILE_PATH].includes(location.pathname)) return null;

  return (
    <SessionProvider>
      <Suspense fallback={<div>Loading...</div>}>
        <ContentTypeProvider>
          <AppLayout>
            <Routes location={background || location}>
              <Route path="content-types">
                <Route index element={<ContentTypesPage />} />
                <Route path=":id" element={<ContentTypePage />} />
              </Route>
              <Route path="content">
                <Route index element={<ContentListPage />} />
                <Route path="create/:typeId" element={<ContentFormPage />} />
                <Route path="edit/:typeId/:id" element={<ContentFormPage />} />
              </Route>
              <Route path="media">
                <Route index element={<MediaPage />} />
              </Route>
              <Route path="access">
                <Route index element={<AccessPage />} />
                <Route path=":tab" element={<AccessPage />} />
                <Route path="edit/:group/:id" element={<AccessForm />} />
              </Route>
              <Route path="locales" element={<LocalesPage />} />
              <Route path="settings" element={<SettingsPage />} />
              <Route path="forms">
                <Route index element={<FormsPage />} />
                <Route path="create" element={<FormsCreatePage />} />
                <Route path=":id" element={<FormsEditPage />} />
                <Route path=":id/:tab" element={<FormsEditPage />} />
              </Route>
              <Route
                path="*"
                element={<Navigate to="/content-types" replace />}
              />
            </Routes>

            {background && (
              <Routes>
                <Route path="media-form">
                  <Route path="create" element={<MediaForm />} />
                  <Route path="edit/:id" element={<MediaForm />} />
                </Route>
              </Routes>
            )}
          </AppLayout>
        </ContentTypeProvider>
      </Suspense>
    </SessionProvider>
  );
};

export default withCKEditorMediaRoute(AppRouter, CKEDITOR_FILE_PATH);
