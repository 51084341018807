import React from "react";
import { Form, DatePicker } from "antd";
import moment, { Moment } from "moment";

import HelpTextOrError from "../../HelpTextOrError";

import { FormFieldProps } from "../interface";
import { DATE_FORMAT } from "../utils";

const DateField: React.FC<FormFieldProps> = ({ data, className }) => {
  const { name, label, properties, initialValue } = data;

  return (
    <Form.Item
      name={name}
      initialValue={initialValue}
      rules={[
        {
          required: properties.required
        }
      ]}
      className={className}
      normalize={(m: Moment) => {
        return m.format(DATE_FORMAT);
      }}
      getValueProps={(value) => ({ value: value ? moment(value) : undefined })}
      label={label}
      help={<HelpTextOrError name={name} data={data} />}
    >
      <DatePicker placeholder={properties.placeholder} format={DATE_FORMAT} />
    </Form.Item>
  );
};

export default DateField;
