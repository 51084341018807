import React from "react";
import { Radio } from "antd";
import { RadioChangeEvent } from "antd/es/radio";
import { RadioButtonsProps, RadioButton } from "./index";
import "./RadioButtons.css";

const RadioButtons: React.FC<RadioButtonsProps> = ({
  defaultValue,
  items,
  onChange
}) => {
  const handleChange = (e: RadioChangeEvent) => {
    const { value } = e.target;

    if (onChange) {
      onChange(value);
    }
  };

  return (
    <Radio.Group
      defaultValue={defaultValue}
      buttonStyle="solid"
      className="radio-buttons"
      onChange={handleChange}
    >
      {items.map((item: RadioButton) => (
        <Radio.Button key={item.value} value={item.value}>
          {item.label}
        </Radio.Button>
      ))}
    </Radio.Group>
  );
};

RadioButtons.defaultProps = {
  items: []
};

export default RadioButtons;
