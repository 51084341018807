import { message } from "antd";

// Handle content type errors
export interface ErrorProperty {
  [key: string]: string | string[];
}

const hasKeys = (obj: ErrorProperty) =>
  Object.keys(obj).length > 0 && obj.constructor === Object;

export const handleErrors = (errors: ErrorProperty[]) => {
  errors.forEach((error: ErrorProperty) => {
    if (hasKeys(error)) {
      Object.entries(error).forEach((entity) => {
        message.error(`${entity[0]}: ${entity[1]}`);
      });
    }
  });
};
