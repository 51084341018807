import { GlobalToken } from "antd";
import { css } from "@emotion/react";

export const styles = (token: GlobalToken) => ({
  builder: css`
    position: relative;
    width: 100%;
    padding: 10px;
    margin: 0 20px;
    .ant-modal-body .preview-drop-zone {
      --preview-margin: 15px;
    }
  `,
  dropZone: css`
    align-items: center;
    border: 1px dashed ${token.colorBorder};
    background-color: #fff;
    border-radius: 4px;
    color: ${token.colorText};
    cursor: not-allowed;
    display: flex;
    gap: 8px;
    font-size: 12px;
    font-weight: 600;
    height: 48px;
    justify-content: center;
    text-align: center;
    transition: all 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
    position: sticky;
    --preview-margin: 0px;

    &.preview-drop-zone {
      &--over {
        cursor: pointer;
        border-color: ${token.colorPrimary};
        color: ${token.colorPrimaryText};
      }
      &--top {
        top: var(--preview-margin);
        box-shadow: 0px 15px 29.4px 0.6px rgba(53, 114, 176, 0.22),
          0px 2px 20px 0px rgba(53, 114, 176, 0.15);
      }
      &--bottom {
        bottom: var(--preview-margin);
        box-shadow: 0px 15px 29.4px 0.6px rgba(53, 114, 176, 0.22),
          0px 2px 20px 0px rgba(53, 114, 176, 0.15);
      }
      &--added {
        animation: animation-added 0.5s;
        animation-iteration-count: 2;
      }
      svg {
        font-size: 18px;
      }

      @keyframes animation-added {
        from {
          border-color: ${token.colorPrimary};
          color: ${token.colorPrimaryText};
        }

        to {
          border-color: ${token.colorSuccessBorder};
          color: ${token.colorSuccessText};
        }
      }
    }
  `
});
