import moment from "moment";
import { UserEntity } from "types";

export interface TableAccess {
  id: string;
  key: string;
  name: string;
  email: string;
  lastLogin: string;
}

export function normalizeUsers(users: UserEntity[]) {
  return users?.map(
    ({ id, sso_id, first_name, last_name, last_login, email }) => {
      const formattedDate = last_login
        ? moment(last_login).format("DD/MM/YYYY HH:mm")
        : "";

      const name = `${first_name} ${last_name}`;

      return {
        name,
        email,
        id: sso_id,
        key: id,
        lastLogin: formattedDate
      };
    }
  );
}
