// tslint:disable max-line-length
import React from "react";

const SingleImage = () => (
  <svg width="1em" height="1em" viewBox="0 0 30 26">
    <path
      fillRule="evenodd"
      fill="currentColor"
      d="M26.481,26.000 L3.519,26.000 C1.578,26.000 -0.000,24.409 -0.000,22.455 L-0.000,3.545 C-0.000,1.590 1.578,-0.000 3.519,-0.000 L26.481,-0.000 C28.421,-0.000 30.000,1.590 30.000,3.545 L30.000,22.455 C30.000,24.409 28.421,26.000 26.481,26.000 ZM27.654,3.545 C27.654,2.894 27.128,2.364 26.481,2.364 L3.519,2.364 C2.872,2.364 2.346,2.894 2.346,3.545 L2.346,12.489 L7.198,5.343 L11.091,10.245 L12.855,7.690 L21.780,19.382 L27.654,19.382 L27.654,3.545 ZM27.654,21.745 L20.625,21.745 L12.950,11.691 L6.358,21.238 C5.988,21.774 5.257,21.907 4.726,21.534 C4.194,21.161 4.063,20.424 4.433,19.889 L9.693,12.269 L7.346,9.312 L2.346,16.675 L2.346,22.455 C2.346,23.107 2.871,23.636 3.519,23.636 L26.481,23.636 C27.129,23.636 27.654,23.107 27.654,22.455 L27.654,21.745 ZM18.622,7.800 C18.622,5.845 20.201,4.254 22.141,4.254 C24.081,4.254 25.660,5.845 25.660,7.800 C25.660,9.755 24.081,11.345 22.141,11.345 C20.201,11.345 18.622,9.755 18.622,7.800 ZM22.141,8.982 C22.788,8.982 23.314,8.452 23.314,7.800 C23.314,7.148 22.788,6.618 22.141,6.618 C21.494,6.618 20.968,7.148 20.968,7.800 C20.968,8.452 21.494,8.982 22.141,8.982 Z"
    />
  </svg>
);

export default SingleImage;
