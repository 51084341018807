import React, { useRef, useCallback } from "react";
import { useDrag, useDrop, DropTargetMonitor } from "react-dnd";
import { theme } from "antd";
import classNames from "classnames";
import { XYCoord } from "dnd-core";
import { Trash, Drag } from "assets/svg";
import { FormField } from "../../interface";
import { DragTypes, mapTypeToIcon } from "../../utils";
import { styles } from "./styles";

interface FormPreviewItemProps {
  isActive?: boolean;
  data: FormField;
  index: number;
  moveItem: (dragIndex: number, hoverIndex: number) => void;
  onClickItem: (id: string) => void;
  onDeleteItem: (id: string) => void;
}

interface DragItem {
  type: DragTypes;
  data: FormField;
  index: number;
}

const FormPreviewItem: React.FC<FormPreviewItemProps> = ({
  data,
  index,
  moveItem,
  onClickItem,
  onDeleteItem,
  isActive
}) => {
  const ref = useRef<HTMLDivElement>(null);
  const { token } = theme.useToken();

  const cssStyle = styles(token);

  const [, drop] = useDrop({
    accept: DragTypes.PREVIEW_FIELD,
    hover: (item: DragItem, monitor: DropTargetMonitor) => {
      if (!ref.current) {
        return;
      }
      const dragIndex = item.index;
      const hoverIndex = index || 0;

      // Don't replace items with themselves
      if (dragIndex === hoverIndex) {
        return;
      }

      // Determine rectangle on screen
      const hoverBoundingRect = ref.current.getBoundingClientRect();

      // Get vertical middle
      const hoverMiddleY =
        (hoverBoundingRect.bottom - hoverBoundingRect.top) / 2;

      // Determine mouse position
      const clientOffset = monitor.getClientOffset();

      // Get pixels to the top
      const hoverClientY = (clientOffset as XYCoord).y - hoverBoundingRect.top;

      // Only perform the move when the mouse has crossed half of the items height
      // When dragging downwards, only move when the cursor is below 50%
      // When dragging upwards, only move when the cursor is above 50%

      // Dragging downwards
      if (dragIndex < hoverIndex && hoverClientY < hoverMiddleY) {
        return;
      }

      // Dragging upwards
      if (dragIndex > hoverIndex && hoverClientY > hoverMiddleY) {
        return;
      }

      // Time to actually perform the action
      moveItem(dragIndex, hoverIndex);

      // Note: we're mutating the monitor item here!
      // Generally it's better to avoid mutations,
      // but it's good here for the sake of performance
      // to avoid expensive index searches.
      item.index = hoverIndex;
    }
  });

  const [{ isDragging }, drag, preview] = useDrag(() => ({
    type: DragTypes.PREVIEW_FIELD,
    item: { type: DragTypes.PREVIEW_FIELD, data, index },
    collect: (monitor: any) => ({
      isDragging: monitor.isDragging()
    })
  }));

  const opacity = isDragging ? 0.35 : 1;

  drag(drop(ref));

  const handleClick = useCallback(() => {
    if (data.id) {
      onClickItem(data.id);
    }
  }, [data.id, onClickItem]);

  const handleClickDelete = (e: React.MouseEvent) => {
    e.stopPropagation();
    if (data.id) {
      onDeleteItem(data.id);
    }
  };

  const IconType = mapTypeToIcon(data.type);

  return (
    <div
      ref={ref}
      className={classNames("form-builder__preview-item", {
        "preview-item--active": isActive
      })}
      css={cssStyle.builder}
      style={{ opacity }}
      onClick={handleClick}
    >
      <fieldset
        className="preview-item__fieldset"
        ref={preview}
        css={cssStyle.fieldset}
      >
        <legend className="preview-item__legend" css={cssStyle.legend}>
          {data.fieldType}
        </legend>
        <div className="preview-item__container" css={cssStyle.container}>
          <div className="preview-item__drag" css={cssStyle.drag}>
            <Drag />
          </div>
          <div className="preview-item__icon" css={cssStyle.icon}>
            <IconType />
          </div>
          <div className="preview-item__text" css={cssStyle.text.main}>
            <span
              className="preview-item__text-label"
              css={cssStyle.text.label}
            >
              {data.label}
            </span>
            <span className="preview-item__text-type" css={cssStyle.text.type}>
              {data.type}
            </span>
          </div>
          <div
            className="preview-item__actions"
            onClick={handleClickDelete}
            css={cssStyle.actions}
          >
            <Trash />
          </div>
        </div>
      </fieldset>
    </div>
  );
};

export default FormPreviewItem;
