import { useLocation, useNavigate } from "react-router-dom";
import { MenuProps, Table } from "antd";
import { useMutation } from "@tanstack/react-query";

import api from "api";
import { Media } from "types";
import { showErrorMessage } from "utils";

import { getTableColumns } from "./tableColumns";
import {
  normalizeMediaList,
  TableContentType,
  showDeleteConfirm
} from "./utils";

interface Props extends Partial<TableContentType> {
  mediaList: Media[];
  fetchMediaList: () => void;
  paginationConfig?: any;
  loading?: boolean;
}

/**
 * MediaPageTable shows the list of media files into a table
 */
export const MediaPageTable = ({
  mediaList,
  fetchMediaList,
  paginationConfig,
  loading,
  ...props
}: Props) => {
  const navigate = useNavigate();
  const location = useLocation();

  const deleteMutation = useMutation(api.media.remove, {
    onSuccess: () => fetchMediaList(),
    onError: (error) => showErrorMessage(error)
  });

  // Content Types table actions

  const getActionColumnMenu: (media: TableContentType) => MenuProps["items"] = (
    media
  ) => [
    {
      label: "Edit",
      key: "edit",
      onClick: () => {
        navigate(`/media-form/edit/${media.id}/`, {
          state: { background: location }
        });
      }
    },
    {
      label: "Delete",
      key: "delete",
      onClick: () => {
        const onOk = async () => {
          deleteMutation.mutate(media.id);
        };

        showDeleteConfirm(onOk);
      }
    }
  ];

  // Table columns and data
  const columns = getTableColumns(getActionColumnMenu, location);
  const data = normalizeMediaList(mediaList);

  return (
    <Table
      loading={loading || deleteMutation.isLoading}
      columns={columns}
      dataSource={data}
      pagination={{ ...paginationConfig, hideOnSinglePage: true }}
      bordered
      {...props}
    />
  );
};
