import { Select, Space } from "antd";

import { StatusType, statuses } from "utils";

const { Option } = Select;

interface Props {
  status: StatusType;
  onChange: (status: StatusType) => void;
}

/**
 * ContentFormStatus is used to change the status of content
 */
export const ContentFormStatus = ({ status, onChange }: Props) => {
  return (
    <Space>
      <span>Status</span>
      <Select
        style={{ width: 120 }}
        loading={!status}
        value={status}
        onChange={onChange}
      >
        {statuses.map((item) => (
          <Option key={item.value} value={item.value}>
            {item.label}
          </Option>
        ))}
      </Select>
    </Space>
  );
};
