export const regexpRegExp = /^\/(.*)\/[dgmsiuy]*$/;
export const flagsRegExp = /^\/.*\/([dgmsiuy]*)$/;

export const regexpFromString = (regexpString: string) => {
  const regexpMatch = regexpString?.match(regexpRegExp)?.[1];
  const flagsMatch = regexpString?.match(flagsRegExp)?.[1];
  try {
    return regexpMatch ? new RegExp(regexpMatch, flagsMatch) : undefined;
  } catch {
    return undefined;
  }
};
