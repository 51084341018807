import { DatePicker, Space } from "antd";
import dayjs, { Dayjs } from "dayjs";

const range = (start: number, end: number) => {
  const result: number[] = [];
  for (let i = start; i < end; i++) {
    result.push(i);
  }
  return result;
};

interface Props {
  publishDate?: Dayjs | null;
  onChange: (value: Dayjs | null, dateString: string) => void;
}

/**
 * ContentFormDate is used to change the date of publishing of content
 */
export const ContentPublishDate = ({ publishDate, onChange }: Props) => {
  return (
    <Space>
      <span>Publish</span>
      <DatePicker
        showTime
        style={{ width: 170 }}
        showToday={false}
        format="DD/MM/YYYY HH:mm"
        disabledDate={(current) => current.isBefore(dayjs(), "day")}
        disabledTime={(current) => {
          const isToday = current?.isSame(dayjs(), "day");
          const isThisHour = current?.isSame(dayjs(), "hour");

          return {
            disabledHours: () =>
              isToday ? range(0, dayjs().hour()) : range(0, 0),
            disabledMinutes: () =>
              isToday && isThisHour ? range(0, dayjs().minute()) : range(0, 0)
          };
        }}
        value={publishDate && dayjs(publishDate)}
        onChange={onChange as any}
      />
    </Space>
  );
};
