import React, { useState } from "react";
import { Button, Form } from "antd";

import { FieldsValidationContextValue } from "../../FieldsValidation";
import { FormFieldProps } from "../interface";
import { RepeaterModal } from ".";

const formId = "modal-repeater-field" + Date.now();

const ContentTypeMappingField: React.FC<FormFieldProps> = ({ ...props }) => {
  const [contentVisible, setContentVisible] = useState(false);
  const [loading, setLoading] = useState(false);
  const [validation, setValidation] = useState<FieldsValidationContextValue>();

  const handleClick = async () => {
    try {
      setLoading(true);
      await validation?.validate();
      setContentVisible(false);
    } finally {
      setLoading(false);
    }
  };

  return (
    <>
      <Form.Item
        name={props.data.name}
        initialValue={props.data.initialValue}
        rules={[{ required: true }]}
      >
        <Button onClick={() => setContentVisible(true)}>Edit Fields</Button>
      </Form.Item>

      <RepeaterModal
        open={contentVisible}
        onOk={handleClick}
        onCancel={handleClick}
        cancelButtonProps={{ style: { display: "none" } }}
        okButtonProps={{ loading }}
        formId={formId}
        setValidation={setValidation}
        {...props}
      />
    </>
  );
};

export default ContentTypeMappingField;
