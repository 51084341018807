import { Link } from "react-router-dom";
import { ColumnProps } from "antd/es/table";

import { TableAccess } from "./utils";

export const getTableColumns = (): ColumnProps<TableAccess>[] => {
  return [
    {
      title: "Name",
      dataIndex: "name",
      key: "name",
      render: (name: string, item) => (
        <Link to={`/access/edit/user/${item.id}`}>{name}</Link>
      )
    },
    {
      title: "Email",
      dataIndex: "email",
      key: "email"
    },
    {
      title: "Last Activity",
      dataIndex: "lastLogin",
      key: "lastLogin"
    },
    {
      title: "Actions",
      dataIndex: "",
      key: "actions",
      render: (item: TableAccess) => {
        return (
          <Link to={`/access/edit/user/${item.id}`}>Change Permissions</Link>
        );
      },
      width: 165
    }
  ];
};
