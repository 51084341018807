import React from "react";

// Capitalize string
export const capitalize = (s: string) => {
  return s.charAt(0).toUpperCase() + s.slice(1);
};

interface OptionsFormattingText {
  segment?: string;
  join?: string;
  editText?: string;
  element?: keyof React.ReactHTML;
}

type FormattingText = (text: string, options?: OptionsFormattingText) => React.ReactNode;

export const formattingText: FormattingText = (text, options) => {
  const { segment = "_", editText, join = "\n", element = "b" } = options || {};

  return React.createElement(
    "div",
    null,
    text
      .split(segment)
      .map((value, key) =>
        value === editText ? React.createElement(element, { key }, value.toUpperCase() + join) : value + join
      )
  );
};
