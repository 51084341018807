import { Modal } from "antd";

import moment from "moment";
import { ContentType } from "types";
import { ContentTypeCategory, StatusType } from "utils";

const { confirm } = Modal;

export interface TableContentType {
  id: string;
  key: string;
  name: string;
  fields: number;
  status: StatusType;
  code_name: string;
  description: string;
  updatedDate: string;
  updatedBy: string;
  category: `${ContentTypeCategory}`;
  pined?: boolean;
}

export function normalizeContentTypes(
  contentTypes: ContentType[]
): TableContentType[] {
  if (!contentTypes) {
    return [];
  }

  return contentTypes.map(
    ({
      id,
      name,
      mapping,
      modified_at,
      modified_by,
      status,
      description,
      code_name,
      pined,
      category
    }) => {
      const formattedDate = modified_at
        ? moment(modified_at).format("DD/MM/YYYY HH:mm")
        : "";

      const fields = mapping ? mapping.length : 0;
      const updatedBy = `${modified_by.first_name} ${modified_by.last_name}`;

      return {
        id,
        name,
        fields,
        status,
        description,
        code_name,
        updatedBy,
        key: id,
        updatedDate: formattedDate,
        pined,
        category
      };
    }
  );
}

// Confirm delete content type utils
export const showDeleteConfirm = (
  contentType: TableContentType,
  remove: (id: string) => void
) => {
  const text = `
      You are about to delete the content type ${contentType.name}.
      The applications, websites or other API clients might expect this content type to exist,
      so they might break after the content type is gone.
    `;

  confirm({
    title: "Delete content type",
    content: text,
    okText: "Confirm",
    okType: "danger",
    cancelText: "Cancel",
    okButtonProps: {
      className: "btn"
    },
    cancelButtonProps: {
      className: "btn btn-default"
    },
    async onOk() {
      // Delete content type and fetch new list of them
      remove(contentType.id);
    }
  });
};

export const showCountDeleteConfirm = (
  contentType: TableContentType,
  count: number
) => {
  const text = `
    We've found ${count} entries which use the content type ${contentType.name}.
    Content types that are currently used by entries can't be deleted.
    Delete all entries which use this content type before deleting it.
  `;

  confirm({
    title: "Delete content type",
    content: text,
    okText: "Ok",
    okType: "primary",
    okCancel: false
  });
};
