// tslint:disable max-line-length
import React from "react";

const Trash = () => (
  <svg width="1em" height="1em" viewBox="0 0 16 20">
    <path
      fillRule="evenodd"
      fill="currentColor"
      d="M2.470,17.876 C2.577,19.087 3.529,20.000 4.682,20.000 L11.318,20.000 C12.471,20.000 13.422,19.087 13.530,17.876 L14.591,5.977 L1.409,5.977 L2.470,17.876 ZM9.136,11.641 L9.053,14.180 L6.947,14.180 L6.864,11.641 L9.136,11.641 ZM6.813,10.078 L6.730,7.539 L9.270,7.539 L9.187,10.078 L6.813,10.078 ZM10.618,11.641 L12.598,11.641 L12.372,14.180 L10.536,14.180 L10.618,11.641 ZM9.002,15.742 L8.914,18.438 L7.086,18.438 L6.998,15.742 L9.002,15.742 ZM5.464,14.180 L3.628,14.180 L3.402,11.641 L5.381,11.641 L5.464,14.180 ZM3.945,17.729 L3.767,15.742 L5.515,15.742 L5.604,18.438 L4.682,18.438 C4.297,18.438 3.981,18.133 3.945,17.729 ZM11.318,18.438 L10.396,18.438 L10.484,15.742 L12.233,15.742 L12.055,17.729 C12.019,18.133 11.702,18.438 11.318,18.438 ZM12.738,10.078 L10.670,10.078 L10.753,7.539 L12.964,7.539 L12.738,10.078 ZM5.247,7.539 L5.330,10.078 L3.262,10.078 L3.036,7.539 L5.247,7.539 ZM11.518,2.849 L11.518,2.344 C11.518,1.051 10.522,-0.000 9.296,-0.000 L6.704,-0.000 C5.478,-0.000 4.481,1.051 4.481,2.344 L4.481,2.849 L-0.000,2.849 L-0.000,4.411 L16.000,4.411 L16.000,2.849 L11.518,2.849 ZM5.963,2.344 C5.963,1.913 6.295,1.562 6.704,1.562 L9.296,1.562 C9.705,1.562 10.037,1.913 10.037,2.344 L10.037,2.849 L5.963,2.849 L5.963,2.344 Z"
    />
  </svg>
);

export default Trash;
