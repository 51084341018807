import { GlobalToken } from "antd";
import { css } from "@emotion/react";

export const styles = (token: GlobalToken) => ({
  sidebar: css`
    max-width: 300px;
    width: 100%;
    min-height: 100%;
  `
});
