/**
 * When data will be converted to JSON (to be passed to API) data will be replaced with the return of `toJSON`
 */
export const makeJSONSerializable = (data: any) =>
  data
    ? {
        ...data,
        toJSON() {
          return data.id;
        }
      }
    : data;
