import { Avatar, Dropdown, Flex } from "antd";
import { LogoutOutlined } from "@ant-design/icons";
import { useMutation, useQuery } from "@tanstack/react-query";

import { mainClient, ssoClient, removeLocalStorageTokens } from "api";
import { SessionData } from "types";

export const UserProfile = () => {
  const { mutate: logoutMutate } = useMutation({
    mutationFn: () => ssoClient.get("/authorization/token/logout/"),
    onSuccess: () => {
      removeLocalStorageTokens();
      window.open(`${window.location.origin}/authentication/login`);
    }
  });

  // const { data } = useQuery({
  //   queryKey: ["user"],
  //   queryFn: () =>
  //     mainClient.post<SessionData>("/users/login/user/", {
  //       domain_name: "tesseract"
  //     })
  // });

  const { data } = useQuery({
    queryKey: ["user"],
    queryFn: () => mainClient.post<SessionData>("/users/login/user/")
  });

  return (
    <Dropdown
      menu={{
        items: [
          {
            key: "logout",
            icon: <LogoutOutlined />,
            label: "Logout",
            onClick: () => logoutMutate()
          }
        ]
      }}
    >
      <Flex gap={8}>
        <Avatar
          size="small"
          src={data?.data.user.photo}
          style={{ backgroundColor: "#ff5900" }}
        >
          {[
            data?.data.user.first_name?.[0],
            data?.data.user.last_name?.[0]
          ]?.join("")}
        </Avatar>
        {data?.data.user.first_name || ""} {data?.data.user.last_name || ""}
      </Flex>
    </Dropdown>
  );
};
