import React from "react";
import { Form, Select } from "antd";
import { LabeledValue } from "antd/lib/select";

import HelpTextOrError from "../../HelpTextOrError";
import { FormFieldProps } from "../interface";

const { Option } = Select;

const SelectField: React.FC<FormFieldProps> = ({ data, className }) => {
  const { name, label, properties, initialValue } = data;
  const options: LabeledValue[] = properties.hasOwnProperty("choices")
    ? properties.choices
    : [];

  return (
    <Form.Item
      name={name}
      initialValue={initialValue}
      rules={[
        {
          required: properties.required
        }
      ]}
      className={className}
      label={label}
      help={<HelpTextOrError name={name} data={data} />}
    >
      <Select placeholder={properties.placeholder} allowClear={true}>
        {options.map((option: LabeledValue) => (
          <Option key={option.key} value={option.key}>
            {option.label}
          </Option>
        ))}
      </Select>
    </Form.Item>
  );
};

export default SelectField;
