import { MenuProps, Table } from "antd";

import { TableSetting, normalizeSettingsList } from "features/settings/utils";
import { Setting } from "types";

import { getTableColumns } from "./tableColumns";

interface Props {
  settings: Setting[];
  actions?: (actions: TableSetting) => MenuProps["items"];
  loading?: boolean;
}

export const SettingsPageTable = ({ settings, actions, loading }: Props) => {
  const tableColumns = getTableColumns(actions);
  const data = normalizeSettingsList(settings);

  return (
    <Table
      loading={loading}
      columns={tableColumns}
      dataSource={data}
      pagination={false}
      bordered
    />
  );
};
