import { GlobalToken } from "antd";
import { css } from "@emotion/react";

export const styles = (token: GlobalToken) => ({
  builder: css`
    &:not(:last-child) {
      margin-bottom: 15px;
    }
    &.preview-item--active .preview-item__fieldset {
      background-color: ${token.colorBgLayout};
      box-shadow: 0 3px 4px 0 rgba(111, 116, 126, 0.16);
    }
    &:hover .preview-item__fieldset {
      box-shadow: 0 3px 4px 0 rgba(111, 116, 126, 0.16);
    }
  `,
  fieldset: css`
    padding: 6px 10px 10px 10px;
    border: 1px dotted ${token.colorBorder};
    border-radius: 7px;
    background-color: ${token.colorWhite};
    transition: box-shadow 0.3s linear;
  `,
  container: css`
    display: flex;
    align-items: center;
    border: 1px dashed ${token.colorBgMask};
    border-radius: 4px;
    padding: 0 10px;
    margin-bottom: 0;
    cursor: pointer;
    overflow: hidden;
    line-height: 1;
  `,
  legend: css`
    width: auto;
    margin-bottom: 0;
    padding: 0 5px;
    font-size: 11px;
    color: ${token.colorTextBase};
    font-weight: ${token.fontWeightStrong};
    text-transform: uppercase;
    line-height: 1.2;
  `,
  icon: css`
    font-size: 21px;
    line-height: 1;
    margin-right: 15px;
  `,
  text: {
    main: css`
      font-size: 13px;
      font-weight: 400;
    `,
    label: css`
      font-weight: bold;
      color: ${token.colorText};
      margin-right: 10px;
    `,
    type: css`
      font-weight: 400;
      color: ${token.colorTextTertiary};
    `
  },
  actions: css`
    margin-left: auto;
    margin-right: -10px;
    padding: 10px;
    color: ${token.colorTextBase};
    cursor: pointer;
    font-size: 16px;
    display: flex;
    align-content: center;
    text-align: center;
  `,
  drag: css`
    margin-right: 10px;
    color: ${token.colorTextBase};
    font-size: 16px;
    cursor: grab;
    svg {
      margin-top: 1px;
    }
  `
});
