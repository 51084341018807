import { Table } from "antd";

import { PermissionEntity } from "types";

import { getTableColumns } from "./tableFormTable";

interface Props {
  onChangeAccess: (items: PermissionEntity[], category: string) => void;
  items: PermissionEntity[];
  category: string;
}

export const AccessFormTable = ({ onChangeAccess, items, category }: Props) => {
  const columns = getTableColumns(onChangeAccess, items, category);

  return (
    <Table dataSource={items} columns={columns} pagination={false} bordered />
  );
};
