// tslint:disable max-line-length
import React from "react";

const Checkbox = () => (
  <svg width="1em" height="1em" viewBox="0 0 32 32">
    <path
      fillRule="evenodd"
      fill="currentColor"
      d="M30.545,32.000 L1.455,32.000 C0.651,32.000 -0.000,31.349 -0.000,30.545 L-0.000,1.455 C-0.000,0.651 0.651,-0.000 1.455,-0.000 L30.545,-0.000 C31.349,-0.000 32.000,0.651 32.000,1.455 L32.000,30.545 C32.000,31.349 31.349,32.000 30.545,32.000 ZM29.091,2.909 L2.909,2.909 L2.909,29.091 L29.091,29.091 L29.091,2.909 ZM8.301,17.243 L11.576,20.518 L23.699,8.396 C24.267,7.828 25.188,7.828 25.756,8.396 C26.324,8.964 26.324,9.885 25.756,10.453 L12.605,23.604 C12.332,23.877 11.962,24.030 11.576,24.030 C11.190,24.030 10.820,23.877 10.548,23.604 L6.244,19.300 C5.676,18.732 5.676,17.811 6.244,17.243 C6.812,16.675 7.733,16.675 8.301,17.243 Z"
    />
  </svg>
);

export default Checkbox;
