import React, { useContext, useEffect, useState } from "react";

import { Form, Select } from "antd";
import { LabeledValue } from "antd/es/select";

import HelpTextOrError from "../../HelpTextOrError";
import { APIContext } from "../../context";

import { fetchReferenceOptions } from "../api";
import { FormFieldProps } from "../interface";

const { Option } = Select;

const MultipleReferenceField: React.FC<FormFieldProps> = ({
  data,
  className
}) => {
  const { name, label, properties, initialValue = [] } = data;

  // Set api config and get references from api
  const apiConfig = useContext(APIContext);

  // Search option
  const [search, setSearch] = useState<string>("");

  const handleSearch = (value: string) => setSearch(value);

  // Options
  const [options, setOptions] = useState<LabeledValue[]>([]);

  useEffect(() => {
    if (apiConfig) {
      fetchReferenceOptions(apiConfig, properties, {
        search
      }).then((response: LabeledValue[] | null) => {
        if (response) {
          setOptions(response);
        }
      });
    }
  }, [search, apiConfig, properties]);

  return (
    <Form.Item
      name={name}
      initialValue={initialValue}
      rules={[
        {
          required: properties.required
        }
      ]}
      className={className}
      label={label}
      help={<HelpTextOrError name={name} data={data} />}
    >
      <Select
        placeholder={properties.placeholder}
        onSearch={handleSearch}
        filterOption={false}
        showSearch={true}
        allowClear={true}
        mode="multiple"
      >
        {options.map((option: LabeledValue) => (
          <Option key={option.key} value={option.key}>
            {option.label || option.key}
          </Option>
        ))}
      </Select>
    </Form.Item>
  );
};

export default MultipleReferenceField;
