import React from "react";
import { FormFieldProps } from "./interface";

const NoTypeField: React.FC<FormFieldProps> = ({ data }) => {
  return (
    <h4>
      Type <code>{data.type}</code> with <code>{data.name}</code> name are not
      valid. Please contact support!
    </h4>
  );
};

export default NoTypeField;
