import React, { useContext, useEffect, useState, useCallback } from "react";
import { Form, Select } from "antd";
import { LabeledValue } from "antd/es/select";
import { APIContext } from "../../context";
import { FormFieldProps } from "../interface";
import { helpMessage, isValidURL } from "../utils";
import { normalizeResponseOptions } from "./utils";

const { Option } = Select;

export const getApplicationIdFromUrl = (): string => {
  return window.location.pathname
    .split("/")
    .filter((i) => i !== "")
    .slice(4, 5)
    .join();
};

const DocumentReferenceField: React.FC<FormFieldProps> = ({
  data,
  className,
  onChange
}) => {
  const [form] = Form.useForm();
  const { setFieldsValue } = form;
  const { name, label, properties } = data;
  const help = helpMessage(form, data);
  const initialValue =
    properties.multiple && !data.initialValue ? [] : data.initialValue;
  const application_id = getApplicationIdFromUrl();

  // Set api config and get references from api
  const apiConfig = useContext(APIContext);

  // Search option
  const [, setSearch] = useState<string | null>(null);

  const handleSearch = (value: string) => setSearch(value);

  // Options
  const [options, setOptions] = useState<LabeledValue[]>([]);

  // Fetch document's data
  const fetchDocumentData = useCallback(async () => {
    if (apiConfig) {
      const { tokens, baseUrl } = apiConfig;
      const { options: srcOptions } = properties.source;

      const reqInit: RequestInit = {
        method: "GET",
        headers: {
          Authorization: `Token ${tokens?.access}`
        }
      };

      let endpoint = srcOptions.endpoint;

      if (!isValidURL(srcOptions.endpoint)) {
        endpoint = `${baseUrl}${srcOptions.endpoint}`;
      }
      if (application_id) {
        endpoint = decodeURI(endpoint).replace(
          "{application_id}",
          application_id
        );
      }

      try {
        const res = await fetch(`${endpoint}`, reqInit);

        if (res.ok) {
          const resData = await res.json();
          const response = normalizeResponseOptions(resData, srcOptions.data);
          setOptions(response as LabeledValue[]);
        }
      } catch (e) {
        throw e;
      }
    }
  }, [apiConfig, application_id, properties.source]);

  useEffect(() => {
    fetchDocumentData();
  }, [apiConfig, fetchDocumentData, properties]);

  const handleChange = (value: string) => {
    setFieldsValue({ [name]: value });

    // Invoke the form onChange handler to trigger changes
    // It's done because on change select don't invoke the form's onChange
    if (onChange) {
      onChange();
    }
  };

  return (
    <Form.Item
      name={name}
      initialValue={initialValue}
      rules={[
        {
          required: properties.required
        }
      ]}
      className={className}
      label={label}
      help={help}
    >
      <Select
        placeholder={properties.placeholder}
        onChange={handleChange}
        onSearch={handleSearch}
        filterOption={false}
        showSearch={true}
        allowClear={true}
        mode={!properties.multiple ? undefined : "multiple"}
      >
        {options.map((option: LabeledValue) => (
          <Option key={option.key} value={option.key}>
            {option.label || option.key}
          </Option>
        ))}
      </Select>
    </Form.Item>
  );
};

export default DocumentReferenceField;
