import React, { useCallback, useEffect, useState } from "react";
import { useDrop, DropTargetMonitor } from "react-dnd";
import { Card, theme } from "antd";
import cn from "classnames";
import update from "immutability-helper";
import { DownBox } from "assets/svg";
import { FormField } from "../interface";
import { DragTypes, DragItem } from "../utils";
import FormPreviewItem from "./FormPreviewItem";
import { styles } from "./styles";
import { normalizedFormItem, scrollparent } from "./utils";

interface FormPreviewProps {
  currentItem?: FormField | null;
  items: FormField[];
  onAddItems: (items: FormField[], item?: FormField) => void;
  onClickItem: (id: string) => void;
  onDeleteItem: (id: string) => void;
}

enum DropType {
  "TOP" = "preview-drop-zone--top",
  "BOTTOM" = "preview-drop-zone--bottom"
}

/**
 * FormPreview component renders the form fields that are
 * dragged from sidebar and can be reordered between them
 * @param items - form data from api
 */
const FormPreview: React.FC<FormPreviewProps> = ({
  currentItem,
  items,
  onAddItems,
  onClickItem,
  onDeleteItem
}) => {
  const [dropZone, setDropZone] = useState<HTMLDivElement | null>(null);
  const [bottomZone, setBottomZone] = useState<HTMLDivElement | null>(null);
  const [topZone, setTopZone] = useState<HTMLDivElement | null>(null);
  const [dropType, setDropType] = useState<DropType | null>(null);
  const [added, setAdded] = useState(false);
  const { token } = theme.useToken();

  const cssStyle = styles(token);

  const handleDrop = (item: DragItem) => {
    const draggedItem = normalizedFormItem(item.data);
    setAdded(true);

    onAddItems([...items, draggedItem], draggedItem);
  };

  const moveItem = useCallback(
    (dragIndex: number, hoverIndex: number) => {
      const dragItem = items[dragIndex];
      onAddItems(
        update(items, {
          $splice: [
            [dragIndex, 1],
            [hoverIndex, 0, dragItem]
          ]
        })
      );
    },
    [items, onAddItems]
  );

  const [{ isOver }, drop] = useDrop({
    accept: DragTypes.SIDEBAR_FIELD,
    drop: (item: DragItem) => handleDrop(item),
    collect: (monitor: DropTargetMonitor) => ({
      isOver: monitor.isOver()
    })
  });

  useEffect(() => {
    if (!bottomZone || !topZone || !dropZone) return;

    const rootMargin =
      getComputedStyle(dropZone).getPropertyValue("--preview-margin").trim() ||
      "0px";

    const options = {
      root: scrollparent(dropZone),
      rootMargin: `-${rootMargin}`,
      threshold: 1.0
    };
    let isSelected = false;
    let dropType: DropType | null = null;

    const checkIsIntersecting = (entries: IntersectionObserverEntry[]) => {
      entries.forEach(({ target, isIntersecting }) => {
        if (!isIntersecting && target === bottomZone && !isSelected) {
          setDropType(DropType.BOTTOM);
          dropType = DropType.BOTTOM;
          isSelected = true;
        } else if (!isIntersecting && target === topZone && !isSelected) {
          setDropType(DropType.TOP);
          dropType = DropType.TOP;
          isSelected = true;
        } else {
          if (
            (dropType === DropType.BOTTOM && target === bottomZone) ||
            (dropType === DropType.TOP && target === topZone)
          ) {
            setDropType(null);
            dropType = null;
            isSelected = false;
          }
        }
      });
    };

    const observer = new IntersectionObserver(checkIsIntersecting, options);

    observer.observe(bottomZone);
    observer.observe(topZone);

    return () => {
      observer.unobserve(bottomZone);
      observer.unobserve(topZone);
    };
  }, [bottomZone, topZone, dropZone]);

  useEffect(() => {
    if (!dropZone) return;

    const handleAnimation = () => setAdded(false);

    dropZone.addEventListener("animationend", handleAnimation);

    return () => dropZone.removeEventListener("animationend", handleAnimation);
  }, [dropZone]);

  return (
    <Card className="form-builder__preview" size="small" css={cssStyle.builder}>
      {items.map((item: FormField, index: number) => {
        // Set item active is it was clicked
        const isActive = currentItem ? currentItem.id === item.id : false;

        return (
          <FormPreviewItem
            key={item.id}
            data={item}
            index={index}
            moveItem={moveItem}
            onClickItem={onClickItem}
            onDeleteItem={onDeleteItem}
            isActive={isActive}
          />
        );
      })}
      <div ref={setTopZone} />
      <div
        className={cn(
          "preview-drop-zone",
          {
            "preview-drop-zone--over": isOver,
            "preview-drop-zone--added": added
          },
          [dropType]
        )}
        ref={(element) => {
          drop(element);
          setDropZone(element);
        }}
        css={cssStyle.dropZone}
      >
        <DownBox />
        Drop fields here
      </div>
      <div ref={setBottomZone} />
    </Card>
  );
};

export default FormPreview;
