import React, { useMemo } from "react";
import { Link, useLocation, useNavigate } from "react-router-dom";

import { Menu } from "antd";
import ProLayout, { PageContainer } from "@ant-design/pro-layout";
import { useQuery } from "@tanstack/react-query";
import queryString from "query-string";

import { queries } from "api";
import { proLayoutTheme } from "theme";
import { contentTypeCategories, menuIcons, showErrorMessage } from "utils";
import { UserProfile } from "./UserProfile";
import { useActivePathname } from "./useActivePathname";

const { ContentTypes, Media, Locales, Records } = menuIcons;

export const AppLayout = ({ children }: React.PropsWithChildren<{}>) => {
  const { pathname, search } = useLocation();
  const navigate = useNavigate();
  const activeKeys = useActivePathname();

  const searchParam = queryString.parse(search);

  const { data: sidebarComponents = [] } = useQuery({
    ...queries.content.pinned(),
    onError: (error) => showErrorMessage(error)
  });

  // TODO: Remove Forms, Access, and Settings from the default menu items
  const defaultMenuItems = useMemo(
    () => [
      {
        key: "content-types",
        icon: ContentTypes,
        label: "Content Types",
        onClick: () => navigate("/content-types"),
        onTitleClick: () => navigate("/content-types"),
        children: contentTypeCategories.map(({ label, value }) => ({
          key: value,
          label: (
            <Link
              to={queryString.stringifyUrl({
                url: "/content-types",
                query: {
                  type: value,
                  ...(pathname === "/content-types" && {
                    status: searchParam.status
                  })
                }
              })}
            >
              {label}
            </Link>
          )
        }))
      },
      {
        key: "content",
        icon: Records,
        label: "Content",
        onTitleClick: () => navigate("/content"),
        children: sidebarComponents?.map(({ id, name, description }) => ({
          key: String(id),
          label: (
            <Link to={`/content?type_id=${id}`}>{name || description}</Link>
          )
        }))
      },
      // {
      //   icon: Forms,
      //   key: "forms",
      //   label: <Link to="/forms">Forms</Link>
      // },
      {
        icon: Media,
        key: "media",
        label: <Link to="/media">Media</Link>
      },
      // {
      //   icon: Access,
      //   key: "access",
      //   label: <Link to="/access">Access</Link>
      // },
      {
        icon: Locales,
        key: "locales",
        label: <Link to="/locales">Locales</Link>
      }
      // {
      //   icon: Settings,
      //   key: "settings",
      //   label: <Link to="/settings">Settings</Link>
      // }
    ],
    [sidebarComponents, navigate, pathname, searchParam]
  );

  return (
    <ProLayout
      title="Digital Culture Hub CMS"
      logo={null}
      menuContentRender={() => (
        <Menu
          items={defaultMenuItems}
          defaultOpenKeys={activeKeys}
          defaultSelectedKeys={activeKeys}
          mode="inline"
          inlineIndent={18}
        />
      )}
      siderWidth={250}
      fixSiderbar
      layout="mix"
      splitMenus={false}
      navTheme="light"
      contentWidth="Fluid"
      location={{
        pathname
      }}
      token={proLayoutTheme}
      avatarProps={{ render: () => <UserProfile /> }}
    >
      <PageContainer title={false} content={children} />
    </ProLayout>
  );
};
