// tslint:disable max-line-length
import React from "react";

const Select = () => (
  <svg height="1em" width="1em" viewBox="0 0 512 512">
    <path
      fillRule="evenodd"
      fill="currentColor"
      d="m452 0h-392c-33.085938 0-60 26.914062-60 60v392c0 33.085938 26.914062 60 60 60h392c33.085938 0 60-26.914062 60-60v-392c0-33.085938-26.914062-60-60-60zm20 452c0 11.027344-8.972656 20-20 20h-392c-11.027344 0-20-8.972656-20-20v-208h432zm-432-248v-144c0-11.027344 8.972656-20 20-20h392c11.027344 0 20 8.972656 20 20v144zm363.476562-124.144531 28.253907 28.3125-66.550781 66.402343-67.226563-66.320312 28.09375-28.476562 38.972656 38.449218zm15.523438 254.144531h-326v-40h326zm0 90h-326v-40h326zm0 0"
    />
  </svg>
);

export default Select;
