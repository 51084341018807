// tslint:disable max-line-length
import React from "react";

const Date = () => (
  <svg width="1em" height="1em" viewBox="0 0 32 32">
    <path
      fillRule="evenodd"
      fill="currentColor"
      d="M28.250,32.000 L3.750,32.000 C1.682,32.000 -0.000,30.318 -0.000,28.250 L-0.000,6.250 C-0.000,4.182 1.682,2.500 3.750,2.500 L5.250,2.500 L5.250,-0.000 L7.750,-0.000 L7.750,2.500 L24.250,2.500 L24.250,-0.000 L26.750,-0.000 L26.750,2.500 L28.250,2.500 C30.318,2.500 32.000,4.182 32.000,6.250 L32.000,28.250 C32.000,30.318 30.318,32.000 28.250,32.000 ZM29.500,6.250 C29.500,5.561 28.939,5.000 28.250,5.000 L26.750,5.000 L26.750,7.500 L24.250,7.500 L24.250,5.000 L7.750,5.000 L7.750,7.500 L5.250,7.500 L5.250,5.000 L3.750,5.000 C3.061,5.000 2.500,5.561 2.500,6.250 L2.500,9.250 L29.500,9.250 L29.500,6.250 ZM29.500,11.750 L2.500,11.750 L2.500,28.250 C2.500,28.939 3.061,29.500 3.750,29.500 L28.250,29.500 C28.939,29.500 29.500,28.939 29.500,28.250 L29.500,11.750 ZM19.750,19.375 L27.250,19.375 L27.250,26.875 L19.750,26.875 L19.750,19.375 ZM22.250,24.375 L24.750,24.375 L24.750,21.875 L22.250,21.875 L22.250,24.375 ZM24.750,14.375 L27.250,14.375 L27.250,16.875 L24.750,16.875 L24.750,14.375 ZM19.750,14.375 L22.250,14.375 L22.250,16.875 L19.750,16.875 L19.750,14.375 ZM14.750,24.375 L17.250,24.375 L17.250,26.875 L14.750,26.875 L14.750,24.375 ZM14.750,19.375 L17.250,19.375 L17.250,21.875 L14.750,21.875 L14.750,19.375 ZM14.750,14.375 L17.250,14.375 L17.250,16.875 L14.750,16.875 L14.750,14.375 ZM9.750,24.375 L12.250,24.375 L12.250,26.875 L9.750,26.875 L9.750,24.375 ZM9.750,19.375 L12.250,19.375 L12.250,21.875 L9.750,21.875 L9.750,19.375 ZM9.750,14.375 L12.250,14.375 L12.250,16.875 L9.750,16.875 L9.750,14.375 ZM4.750,24.375 L7.250,24.375 L7.250,26.875 L4.750,26.875 L4.750,24.375 ZM4.750,19.375 L7.250,19.375 L7.250,21.875 L4.750,21.875 L4.750,19.375 ZM4.750,14.375 L7.250,14.375 L7.250,16.875 L4.750,16.875 L4.750,14.375 Z"
    />
  </svg>
);

export default Date;
