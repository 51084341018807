import React, { useContext, useEffect } from "react";

import { Button, Tooltip } from "antd";
import { ExpandOutlined, ShrinkOutlined } from "@ant-design/icons";

import { CollapseEmitterContext } from "../../contexts";
import { useRerender } from "../../hooks";

export const CollapseTriggers = () => {
  const rerender = useRerender();
  const collapseEmitter = useContext(CollapseEmitterContext);

  useEffect(() => {
    const subscriber = collapseEmitter.subscribe("subscription-change", () => {
      rerender();
    });

    return () => {
      subscriber.unsubscribe();
    };
  }, [collapseEmitter, rerender]);

  return collapseEmitter.hasSubscribers(["collapse", "expand"]) ? (
    <Button.Group>
      <Tooltip title="Expand all">
        <Button
          title="Expand all"
          onClick={() => collapseEmitter.emit("expand")}
          icon={<ExpandOutlined />}
        />
      </Tooltip>
      <Tooltip title="Collapse all">
        <Button
          onClick={() => collapseEmitter.emit("collapse")}
          icon={<ShrinkOutlined />}
        />
      </Tooltip>
    </Button.Group>
  ) : null;
};
