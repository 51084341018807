export const menuIcons: Record<string, JSX.Element> = {
  ContentTypes: (
    <svg viewBox="0 0 409.6 409.6" width="1em" height="1em">
      <g fill="currentColor">
        <g>
          <path
            d="M153.6,0H34.1C15.4,0,0,15.4,0,34.1v119.5c0,18.8,15.4,34.1,34.1,34.1h119.5c18.8,0,34.1-15.4,34.1-34.1V34.1
              C187.7,15.4,172.4,0,153.6,0z M153.6,153.6H34.1V34.1h119.5V153.6z"
          />
        </g>
      </g>
      <g fill="currentColor">
        <g>
          <path
            d="M153.6,221.9H34.1C15.4,221.9,0,237.2,0,256v119.5c0,18.8,15.4,34.1,34.1,34.1h119.5c18.8,0,34.1-15.4,34.1-34.1V256
              C187.7,237.2,172.4,221.9,153.6,221.9z M153.6,375.5H34.1V256h119.5V375.5z"
          />
        </g>
      </g>
      <g>
        <g fill="currentColor">
          <rect x="221.9" width="85.3" height="34.1" />
        </g>
      </g>
      <g>
        <g fill="currentColor">
          <rect x="221.9" y="136.5" width="119.5" height="34.1" />
        </g>
      </g>
      <g>
        <g fill="currentColor">
          <rect x="221.9" y="68.3" width="187.7" height="34.1" />
        </g>
      </g>
      <g>
        <g fill="currentColor">
          <rect x="221.9" y="221.9" width="187.7" height="34.1" />
        </g>
      </g>
      <g>
        <g fill="currentColor">
          <rect x="221.9" y="358.4" width="119.5" height="34.1" />
        </g>
      </g>
      <g>
        <g fill="currentColor">
          <rect x="221.9" y="290.1" width="85.3" height="34.1" />
        </g>
      </g>
    </svg>
  ),
  Records: (
    <svg width="1em" height="1em" viewBox="0 0 512 512">
      <g fill="currentColor">
        <g>
          <rect x="162" y="236" width="350" height="40" />
        </g>
      </g>
      <g fill="currentColor">
        <g>
          <rect x="162" y="50" width="350" height="40" />
        </g>
      </g>
      <g fill="currentColor">
        <g>
          <rect x="162" y="417" width="350" height="40" />
        </g>
      </g>
      <g fill="currentColor">
        <g>
          <rect x="31" width="40" height="140" />
        </g>
      </g>
      <g>
        <g>
          <path
            fill="currentColor"
            d="M64,287c0.5-0.6,1-1.1,1.5-1.7c29.3-33.6,35.3-47.6,35.3-58.3c0-27.6-22.4-50-50-50H0v40h50.7c4.9,0,9.1,3.7,9.9,8.4
         c-1.4,3-7.2,13.1-27.9,36.6C19,277.5,5.9,290.8,5.8,291L0,296.8V327h102v-40H64z"
          />
        </g>
      </g>
      <g>
        <g>
          <path
            fill="currentColor"
            d="M102,362H0v40h33.9L20,415.6l0,21.4l10.9,5.6c12.3,6.3,25.9,16.1,29.6,21.2c-0.9,4.6-5,8.2-9.8,8.2H0v40h50.7
         c27.6,0,50-22.4,50-50c0-14.1-9.1-27.1-28.8-41.1c0,0-0.1-0.1-0.1-0.1l30.2-29.6V362z"
          />
        </g>
      </g>
    </svg>
  ),
  Access: (
    <svg width="1em" height="1em" viewBox="0 0 18 18">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        fill="currentColor"
        d="M7.4,11.6c-0.4,0.4-1.1,0.4-1.5,0s-0.4-1.1,0-1.5l0,0l9.5-9.5c0.4-0.4,1.1-0.4,1.5,0s0.4,1.1,0,1.5l0,0
 L7.4,11.6z M7.7,9.8h4.2c0.6,0,1.1,0.5,1.1,1.1c0,0.6-0.5,1.1-1.1,1.1H6.7c-0.6,0-1.1-0.5-1.1-1.1l0,0V5.6c0-0.6,0.5-1.1,1.1-1.1
 S7.7,5,7.7,5.6c0,0,0,0,0,0L7.7,9.8L7.7,9.8z M15.9,9c0-0.6,0.5-1.1,1.1-1.1S18,8.4,18,9c0,0,0,0,0,0v6c0,1.7-1.4,3-3,3c0,0,0,0,0,0
 H3c-1.7,0-3-1.4-3-3l0,0V3c0-1.7,1.4-3,3-3c0,0,0,0,0,0h6.5c0.6,0,1.1,0.5,1.1,1.1c0,0.6-0.5,1.1-1.1,1.1H3C2.5,2.1,2.1,2.5,2.1,3
 c0,0,0,0,0,0V15c0,0.5,0.4,0.9,0.9,0.9H15c0.5,0,0.9-0.4,0.9-0.9V9z"
      />
    </svg>
  ),
  Media: (
    <svg viewBox="0 0 512 512" width="1em" height="1em">
      <path
        fill="currentColor"
        d="M494.4,99.6c-3.8-3.8-7.9-6.9-12.4-9.5V60c0-16-6.2-31.1-17.6-42.4C453.1,6.2,438,0.1,422,0.1c0,0,0,0,0,0L89,0
      C55.9,0,29,26.8,29,59.9v30.7C11.6,101.2,0,120.3,0,142v310c0,33.1,26.9,60,60,60h392c33.1,0,60-26.9,60-60V142.1
      C512,126,505.8,111,494.4,99.6z M89,39.9L422,40c5.3,0,10.4,2.1,14.1,5.9c3.8,3.8,5.9,8.8,5.9,14.1v22.1L69,82V59.9
      C69,48.9,78,39.9,89,39.9L89,39.9z M256.3,253.9l44,57.1h-83.7L256.3,253.9z M167.8,311H94.1l66.7-97.4l40,50L167.8,311z M472,452
      c0,11-9,20-20,20H60c-11,0-20-9-20-20V351h432V452z M350.7,311l-96-124.9l-30.1,43.2l-66.4-83L45.6,311H40V142c0-11,9-20.1,20-20.1
      l392,0.1h0c5.3,0,10.4,2.2,14.1,5.9c3.8,3.8,5.9,8.8,5.9,14.1V311H350.7z M306,431H206v-40h100V431z M408,198c0,16.6-13.4,30-30,30
      s-30-13.4-30-30s13.4-30,30-30S408,181.4,408,198z"
      />
    </svg>
  ),
  Locales: (
    <svg viewBox="0 0 512 512" width="1em" height="1em">
      <path
        fill="currentColor"
        d="M256,512c-42.4,0-209.5-132.2-209.5-302.5C46.5,93.8,140.3,0,256,0s209.5,93.8,209.5,209.5C465.5,379.8,298.4,512,256,512z
	 M256,46.5c-90,0-162.9,72.9-162.9,162.9c0,142,135.2,243.4,162.9,256c27.7-11.9,162.9-113.3,162.9-256
	C418.9,119.5,346,46.5,256,46.5z M256,325.8c-64.3,0-116.4-52.1-116.4-116.4S191.7,93.1,256,93.1s116.4,52.1,116.4,116.4
	S320.3,325.8,256,325.8z M256,139.6c-38.6,0-69.8,31.3-69.8,69.8s31.3,69.8,69.8,69.8s69.8-31.3,69.8-69.8S294.6,139.6,256,139.6z"
      />
    </svg>
  ),
  Settings: (
    <svg
      stroke="currentColor"
      fill="currentColor"
      strokeWidth="0"
      viewBox="0 0 1024 1024"
      height="1em"
      width="1em"
    >
      <path
        d="M924.8 625.7l-65.5-56c3.1-19 4.7-38.4 4.7-57.8s-1.6-38.8-4.7-57.8l65.5-56a32.03 32.03 0 0 0 9.3-35.2l-.9-2.6a443.74 
        443.74 0 0 0-79.7-137.9l-1.8-2.1a32.12 32.12 0 0 0-35.1-9.5l-81.3 28.9c-30-24.6-63.5-44-99.7-57.6l-15.7-85a32.05 32.05 0
        0 0-25.8-25.7l-2.7-.5c-52.1-9.4-106.9-9.4-159 0l-2.7.5a32.05 32.05 0 0 0-25.8 25.7l-15.8 85.4a351.86 351.86 0 0 0-99 57.4l-81.9-29.1a32
        32 0 0 0-35.1 9.5l-1.8 2.1a446.02 446.02 0 0 0-79.7 137.9l-.9 2.6c-4.5 12.5-.8 26.5 9.3 35.2l66.3 56.6c-3.1 18.8-4.6 38-4.6
        57.1 0 19.2 1.5 38.4 4.6 57.1L99 625.5a32.03 32.03 0 0 0-9.3 35.2l.9 2.6c18.1 50.4 44.9 96.9 79.7 137.9l1.8 2.1a32.12 32.12 0 0 0
        35.1 9.5l81.9-29.1c29.8 24.5 63.1 43.9 99 57.4l15.8 85.4a32.05 32.05 0 0 0 25.8 25.7l2.7.5a449.4 449.4 0 0 0 159 0l2.7-.5a32.05
        32.05 0 0 0 25.8-25.7l15.7-85a350 350 0 0 0 99.7-57.6l81.3 28.9a32 32 0 0 0 35.1-9.5l1.8-2.1c34.8-41.1
        61.6-87.5 79.7-137.9l.9-2.6c4.5-12.3.8-26.3-9.3-35zM788.3 465.9c2.5 15.1 3.8 30.6 3.8 46.1s-1.3 31-3.8 46.1l-6.6 40.1 74.7
        63.9a370.03 370.03 0 0 1-42.6 73.6L721 702.8l-31.4 25.8c-23.9 19.6-50.5 35-79.3 45.8l-38.1 14.3-17.9 97a377.5 377.5 0 0
        1-85 0l-17.9-97.2-37.8-14.5c-28.5-10.8-55-26.2-78.7-45.7l-31.4-25.9-93.4
        33.2c-17-22.9-31.2-47.6-42.6-73.6l75.5-64.5-6.5-40c-2.4-14.9-3.7-30.3-3.7-45.5 0-15.3 1.2-30.6 3.7-45.5l6.5-40-75.5-64.5c11.3-26.1
        25.6-50.7 42.6-73.6l93.4 33.2 31.4-25.9c23.7-19.5 50.2-34.9 78.7-45.7l37.9-14.3 17.9-97.2c28.1-3.2 56.8-3.2 85 0l17.9 97 38.1
        14.3c28.7 10.8 55.4 26.2 79.3 45.8l31.4 25.8 92.8-32.9c17 22.9 31.2 47.6 42.6 73.6L781.8 426l6.5 39.9zM512 326c-97.2 0-176
        78.8-176 176s78.8 176 176 176 176-78.8 176-176-78.8-176-176-176zm79.2 255.2A111.6 111.6 0 0 1 512 614c-29.9 0-58-11.7-79.2-32.8A111.6
        111.6 0 0 1 400 502c0-29.9 11.7-58 32.8-79.2C454 401.6 482.1 390 512 390c29.9 0 58 11.6 79.2 32.8A111.6 111.6 0 0 1 624
        502c0 29.9-11.7 58-32.8 79.2z"
      />
    </svg>
  ),
  Forms: (
    <svg width="1em" height="1em" viewBox="0 0 18 18">
      <path
        fillRule="evenodd"
        fill="currentColor"
        d="M15.891,-0.000 L2.109,-0.000 C0.946,-0.000 -0.000,0.946 -0.000,2.109 L-0.000,15.891 C-0.000,17.054 0.946,18.000 2.109,18.000 L15.891,18.000 C17.054,18.000 18.000,17.054 18.000,15.891 L18.000,2.109 C18.000,0.946 17.054,-0.000 15.891,-0.000 ZM16.594,15.891 C16.594,16.278 16.278,16.594 15.891,16.594 L2.109,16.594 C1.722,16.594 1.406,16.278 1.406,15.891 L1.406,8.578 L16.594,8.578 L16.594,15.891 ZM1.406,7.172 L1.406,2.109 C1.406,1.722 1.722,1.406 2.109,1.406 L15.891,1.406 C16.278,1.406 16.594,1.722 16.594,2.109 L16.594,7.172 L1.406,7.172 ZM14.185,2.807 L15.178,3.803 L12.838,6.137 L10.475,3.806 L11.463,2.805 L12.833,4.156 L14.185,2.807 ZM14.730,11.742 L3.270,11.742 L3.270,10.336 L14.730,10.336 L14.730,11.742 ZM14.730,14.906 L3.270,14.906 L3.270,13.500 L14.730,13.500 L14.730,14.906 Z"
      ></path>
    </svg>
  )
};
