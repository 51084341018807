import { ColumnProps } from "antd/es/table";

import { TableFormResponse } from "features/forms/utils";
import { capitalize } from "utils";

import { normalizeTitle, normalizeValue } from "./utils";

export const getTableColumns = (
  data: TableFormResponse[]
): ColumnProps<TableFormResponse>[] => {
  if (!data || !data[0]) return [];

  const dynamicColumns: Array<ColumnProps<TableFormResponse>> = [];

  const keys = Object.keys(data[0]).filter(
    (key) => !["id", "key", "created"].includes(key)
  );

  keys.forEach((key) =>
    dynamicColumns.push({
      title: capitalize(normalizeTitle(key)),
      dataIndex: key,
      key: key,
      render: (value) => normalizeValue(value),
      width: 200
    })
  );

  return [
    ...dynamicColumns,
    {
      title: "Created",
      key: "created",
      dataIndex: "created",
      width: 200
    }
  ];
};
