import React from "react";
import { InternalNamePath } from "antd/lib/form/interface";

import { FormInstance } from "rc-field-form/es/interface"; // Importing from rc-form-field because of Form.Item children renderer typing

import FormFields from "..";
import { FormField } from "../../interface";
import { createFieldClassName } from "../../utils";

export const renderFormItem = (
  item: FormField,
  namePrefix: InternalNamePath,
  form: FormInstance
) => {
  const fieldClassName = createFieldClassName(item);

  // Take form item component by type and fill with data
  const FormItemField = FormFields.hasOwnProperty(item.type)
    ? FormFields[item.type]
    : FormFields.Default;

  const itemData = {
    ...item,
    name: [...namePrefix, item.name]
  };

  return (
    <FormItemField data={itemData} form={form} className={fieldClassName} />
  );
};
