import moment from "moment";
import { FormData, FormTabs, FormField, FormSchema } from "../../interface";

const DATE_FORMAT = "YYYY-MM-DD";

const getItemByKey = (key: string, items: FormField[]) => {
  return items.find((itemForm: FormField) => itemForm.name === key);
};

// Check if schema has tabs
export const isFormTabsArray = (schema: any[]): schema is FormTabs[] => {
  return schema[0] && schema[0].form;
};

export const getFieldType = (key: string, schema: FormSchema) => {
  if (isFormTabsArray(schema)) {
    const keyType = schema
      .map((item: FormTabs) => getItemByKey(key, item.form))
      .find((item: any) => item);

    return keyType && keyType.type;
  } else {
    const keyType = getItemByKey(key, schema);

    return keyType && keyType.type;
  }
};

// Normalize form values
export const normalizeFormValues = (values: FormData, items: FormSchema) => {
  const normalizedValues = { ...values };

  const normalizeData = (normalizedValues: Record<string, any>) => {
    Object.keys(normalizedValues).forEach((key: string) => {
      const keyType = getFieldType(key, items);
      const keyValue = normalizedValues[key];
      normalizedValues[key] = keyValue;

      if (Array.isArray(keyValue)) {
        keyValue.forEach((v) => typeof v !== "string" && normalizeData(v));
        return;
      } else if (typeof keyValue === "object" && keyValue !== null) {
        normalizeData(keyValue);
        return;
      }

      // If key values is undefined set it to null
      if (typeof keyValue === "undefined") {
        normalizedValues[key] = null;
      }

      if (keyType === "Date" && moment.isMoment(keyValue)) {
        normalizedValues[key] = moment(keyValue).format(DATE_FORMAT);
      } else if (keyType === "DateTime" && moment.isMoment(keyValue)) {
        normalizedValues[key] = moment(keyValue).toISOString();
      }
    });
  };

  normalizeData(normalizedValues);

  return normalizedValues;
};
