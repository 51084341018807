import { useState } from "react";
import { MenuProps, Space, Typography, message } from "antd";
import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";

import api, { queries } from "api";
import {
  SettingsPageUpdateModal,
  SettingsPageTable
} from "features/settings/components";
import { TableSetting } from "features/settings/utils";
import { showErrorMessage } from "utils";

interface SettingsModalState {
  visible: boolean;
  setting?: TableSetting;
}

export const SettingsPage = () => {
  const [modalState, setModalState] = useState<SettingsModalState>({
    visible: false
  });

  const queryClient = useQueryClient();

  const { data, isLoading } = useQuery({
    ...queries.settings.all(),
    onError: (error) => showErrorMessage(error)
  });

  const { mutate } = useMutation(
    (data: TableSetting) =>
      api.settings.update(modalState.setting!.id, {
        code_name: data.codeName,
        value: data.value
      }),

    {
      onSuccess: () => {
        message.success("Setting was successfully updated!");
        queryClient.invalidateQueries(queries.settings.all().queryKey);
        setModalState({ visible: false, setting: undefined });
      },
      onError: (error) => showErrorMessage(error)
    }
  );

  const getActionColumnMenu: (setting: TableSetting) => MenuProps["items"] = (
    setting
  ) => [
    {
      label: "Edit",
      key: "edit",
      onClick: () => {
        setModalState({ visible: true, setting });
      }
    }
  ];

  return (
    <>
      <Space className="mb-20">
        <Typography.Title level={5} className="mb-0">
          {`Settings (${data?.length || 0})`}
        </Typography.Title>
      </Space>
      <SettingsPageTable
        loading={isLoading}
        settings={data ?? []}
        actions={getActionColumnMenu}
      />
      {modalState.visible && modalState.setting && (
        <SettingsPageUpdateModal
          visible={modalState.visible}
          onOk={(data: TableSetting) => mutate(data)}
          onCancel={() => setModalState({ visible: false, setting: undefined })}
          setting={modalState.setting}
        />
      )}
    </>
  );
};
