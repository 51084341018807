import React from "react";
import { DndProvider } from "react-dnd";
import { HTML5Backend } from "react-dnd-html5-backend";

import { Button, List } from "antd";
import { PlusOutlined } from "@ant-design/icons";
import { NamePath } from "antd/lib/form/interface";

import { useListOrder } from "components/FormBuilder/hooks/useListOrder";
import { makeNamePath } from "components/FormBuilder/utils/form";

import { FormField } from "../../interface";
import DnDListItem from "./DnDListItem";

export interface DnDListProps {
  name: NamePath;
  label: string;

  fields: FormField[];
  /**
   * Length of the Form.List value
   */
  listLength: number;

  onRemove: React.Dispatch<number>;
  onAdd: () => void;
  onReorder: (order: number[]) => void;
}

const DnDList: React.FC<DnDListProps> = ({
  listLength,
  label,
  name,
  fields,
  onRemove,
  onAdd,
  onReorder
}) => {
  const listOrder = useListOrder(listLength);

  return (
    <DndProvider backend={HTML5Backend}>
      <List
        size="small"
        itemLayout="horizontal"
        dataSource={listOrder.currentOrder}
        renderItem={({ id, index: initialIndex }, index) => (
          <DnDListItem
            key={id}
            label={label}
            name={makeNamePath(name, initialIndex)}
            itemType={makeNamePath(name).join(".")}
            fields={fields}
            index={initialIndex}
            onMovePreview={listOrder.handleMovePreview}
            onMoveFinish={() => {
              onReorder(listOrder.currentOrder.map((item) => item.index));
              listOrder.handleFinish();
            }}
            onRemove={() => {
              onRemove(initialIndex);
              listOrder.handleRemove(index);
            }}
          />
        )}
        footer={
          <Button
            onClick={() => {
              onAdd();
              listOrder.handleAdd();
            }}
            icon={<PlusOutlined />}
          >
            Add {String(label || "").toLowerCase()}
          </Button>
        }
      />
    </DndProvider>
  );
};

export default DnDList;
