// tslint:disable max-line-length
import React from "react";

const Email = () => (
  <svg width="1em" height="1em" viewBox="0 0 32 24">
    <path
      fillRule="evenodd"
      fill="currentColor"
      d="M28.250,-0.000 L3.750,-0.000 C1.682,-0.000 -0.000,1.631 -0.000,3.636 L-0.000,20.364 C-0.000,22.369 1.682,24.000 3.750,24.000 L28.250,24.000 C30.318,24.000 32.000,22.369 32.000,20.364 L32.000,3.636 C32.000,1.631 30.318,-0.000 28.250,-0.000 ZM21.316,11.926 L29.500,4.610 L29.500,19.369 L21.316,11.926 ZM28.250,2.424 C28.252,2.424 28.254,2.424 28.256,2.424 L16.000,13.382 L3.744,2.424 C3.746,2.424 3.748,2.424 3.750,2.424 L28.250,2.424 ZM2.500,19.369 L2.500,4.610 L10.686,11.928 L2.500,19.369 ZM3.750,21.576 C3.743,21.576 3.736,21.575 3.729,21.575 L12.529,13.575 L16.000,16.679 L19.473,13.574 L28.271,21.575 C28.264,21.575 28.257,21.576 28.250,21.576 L3.750,21.576 Z"
    />
  </svg>
);

export default Email;
