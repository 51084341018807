import { useCallback, useEffect, useMemo, useState } from "react";
import {
  Radio,
  Space,
  Dropdown,
  DropdownProps,
  Row,
  Col,
  Typography,
  Input
} from "antd";
import { useQuery } from "@tanstack/react-query";
import {
  useQueryParams,
  StringParam,
  NumberParam,
  withDefault
} from "use-query-params";

import api, { queries } from "api";
import { AddContent, ContentListTable } from "features/content/components";
import {
  getContentTypes,
  useContentType
} from "features/content-types/contexts";
import { cssDropdown } from "style";
import { filterByStatus, showErrorMessage, Status } from "utils";

const PAGE_SIZE = 25;

export const ContentListPage = () => {
  const [filterByType, setFilterByType] = useState<string>("All");
  const [query, setQuery] = useQueryParams({
    search: withDefault(StringParam, ""),
    type_id: StringParam,
    status: StringParam,
    page: withDefault(NumberParam, 1)
  });

  // Content types list
  const [{ contentTypesTuple }, dispatchContentType] = useContentType();

  // Fetch content type list
  const fetchContentTypes = useCallback(
    () => getContentTypes(dispatchContentType, api.contentType.getAll()),
    [dispatchContentType]
  );

  // Set sort by content types with "All" value
  const sortByContentTypes = useMemo(
    () => [
      {
        key: "",
        label: "All"
      },
      ...contentTypesTuple.map(({ key, title }) => ({ key, label: title }))
    ],
    [contentTypesTuple]
  );

  const updateFilterByType = useCallback(
    (typeId?: string | null) => {
      setFilterByType(
        sortByContentTypes.find((item) => item.key === typeId)?.label || "All"
      );
    },
    [sortByContentTypes]
  );

  useEffect(() => {
    updateFilterByType(query.type_id);
  }, [query.type_id, updateFilterByType]);

  useEffect(() => {
    if (!contentTypesTuple.length) {
      fetchContentTypes();
    }
  }, [contentTypesTuple.length, fetchContentTypes]);

  // Fetch content list
  const {
    data: content,
    refetch: refetchContentList,
    isLoading
  } = useQuery({
    ...queries.content.search({
      ...query,
      per_page: PAGE_SIZE
    }),
    onError: (error) => showErrorMessage(error)
  });

  const dropDownProps: DropdownProps = {
    menu: {
      items: sortByContentTypes,
      selectable: true,
      style: cssDropdown,
      onSelect: ({ key }) => {
        setQuery({ type_id: key, page: 1 });
        updateFilterByType(key);
      }
    },
    trigger: ["click"],
    placement: "bottomRight"
  };

  return (
    <>
      <Row gutter={[20, 10]} className="mb-20" justify="space-between">
        <Col xs={24} md={24} lg={24} xl={12}>
          <div className="d-flex align-items-center gap-8">
            <Typography.Title level={5} className="mb-0">
              {`Content (${content?.total || "0"})`}
            </Typography.Title>

            <Input.Search
              defaultValue={query.search}
              onSearch={(value) => setQuery({ search: value, page: 1 })}
              className="mw-300"
              placeholder="Search"
            />
          </div>
        </Col>
        <Col>
          <Space>
            <Radio.Group
              defaultValue={query.status || Status.ALL}
              options={filterByStatus}
              buttonStyle="solid"
              optionType="button"
              onChange={(event) =>
                setQuery({
                  status:
                    event.target.value !== Status.ALL ? event.target.value : "",
                  page: 1
                })
              }
            />

            <Dropdown.Button {...dropDownProps}>{filterByType}</Dropdown.Button>

            <AddContent
              contentTypes={contentTypesTuple}
              contentType={query.type_id}
            />
          </Space>
        </Col>
      </Row>

      <ContentListTable
        loading={isLoading}
        contentList={content?.results || []}
        fetchContentList={refetchContentList}
        paginationConfig={{
          current: query.page,
          total:
            content && content.total ? content.total : content?.results.length,
          pageSize: PAGE_SIZE,
          onChange: (current: number) => setQuery({ page: current })
        }}
      />
    </>
  );
};
