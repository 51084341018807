import { FieldProperties, FormField } from "../interface";

export interface FieldItemProps extends FormField {
  initialValue?: any;
}

export interface FormFieldProps {
  className?: string;
  data: FieldItemProps;
  onChange?: (event?: any, properties?: FieldProperties) => void;
  currentItem?: FormField | null;
  changeCode: (code: string) => void;
}

// Fields
export interface MediaData {
  uid: string;
  url: string;
  name: string;
  size: number;
  type: string;
}

export interface Media {
  id: string;
  name: string;
  content_type: string;
  url: string;
  status: Status.PUBLISHED | Status.DRAFT;
  modified_at: string;
  modified_by: ModifiedByType;
}

export enum Status {
  ALL = "all",
  DRAFT = "draft",
  PUBLISHED = "published"
}

export interface ModifiedByType {
  id: string;
  first_name?: string;
  last_name?: string;
}
