export enum FIELDS_CATEGORIES {
  BASIC = "basic",
  ADVANCED = "advanced",
  SPECIAL = "special"
}

export interface FieldCategory {
  label: string;
  key: FIELDS_CATEGORIES;
}

export const fieldsCategories: FieldCategory[] = [
  {
    label: "Basic Fields",
    key: FIELDS_CATEGORIES.BASIC
  },
  {
    label: "Advanced Fields",
    key: FIELDS_CATEGORIES.ADVANCED
  },
  {
    label: "Special Fields",
    key: FIELDS_CATEGORIES.SPECIAL
  }
];
