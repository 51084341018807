import React from "react";
import { FormField } from "../interface";

interface ContentType {
  id: string;
  name: string;
  mapping: FormField[];
}

interface ContentTypesContextValue {
  contentTypes: ContentType[];
}

export const ContentTypesContext =
  React.createContext<ContentTypesContextValue | null>(null);
