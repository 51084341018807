import { Button, Dropdown, MenuProps } from "antd";
import { EllipsisOutlined } from "@ant-design/icons";

import { ColumnProps } from "antd/es/table";
import { TableLocales } from "features/locales/utils";

const getTableColumns = (
  getActionColumnMenu: (locale: TableLocales) => MenuProps["items"]
) => {
  const columns: Array<ColumnProps<TableLocales>> = [
    {
      title: "Label",
      dataIndex: "label",
      key: "label"
    },
    {
      title: "Value",
      dataIndex: "value",
      key: "value"
    },
    {
      title: "Updated",
      dataIndex: "updatedDate",
      key: "updatedDate"
    },
    {
      title: "",
      dataIndex: "",
      key: "actions",
      align: "right",
      render: (_, item) => (
        <Dropdown
          placement="bottomRight"
          trigger={["click"]}
          menu={{ items: getActionColumnMenu(item) }}
        >
          <Button icon={<EllipsisOutlined />} type="text" />
        </Dropdown>
      )
    }
  ];

  return columns;
};

export default getTableColumns;
