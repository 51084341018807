import React, { useEffect, useRef } from "react";
import { Form, Card, Input } from "antd";

import { formattingText } from "components/FormBuilder/utils/strings";
import { ApiConfig } from "types";
import { useValidation } from "../FieldsValidation";
import FormFields from "../FormFields";
import { FormFieldProps } from "../FormFields/interface";
import { APIContext } from "../context";
import { FormField } from "../interface";
import { createFieldClassName } from "../utils";

interface FormSidebarProps {
  className?: string;
  currentItem?: FormField | null;
  formId?: string;
  onChangeItem: (item: FormField, watchChange?: boolean) => void;
  onSubmit?: () => void;
  hasGenericFields?: boolean;
  apiConfig?: ApiConfig;
}

/**
 * FormProperties shows properties settings of the field.
 * Each field has its properties, based on it,
 * this component will render those properties by their type.
 * @param item - form field data
 */
const FormProperties: React.FC<FormSidebarProps> = ({
  className,
  formId,
  currentItem,
  onChangeItem,
  onSubmit,
  hasGenericFields = true,
  apiConfig = {}
}) => {
  const [form] = Form.useForm();
  const { setFieldsValue, getFieldsValue } = form;
  const firstRender = useRef(false);

  const validation = useValidation();
  const { validate, error } = validation;

  useEffect(() => {
    if (firstRender.current) return;
    error && form?.validateFields();
    firstRender.current = true;
  }, [error, firstRender, form, validation]);

  const formSubmitHandler = () => validate().then(() => onSubmit && onSubmit());

  const handleChangeForm: FormFieldProps["onChange"] = (_, properties) => {
    const { name, label, ...values } = getFieldsValue();

    if (currentItem && currentItem.properties) {
      const changedItem: FormField = {
        ...currentItem,
        name: name || currentItem.name,
        label: label || currentItem.label,
        properties: properties || {
          ...currentItem.properties,
          ...values
        }
      };

      onChangeItem(changedItem, !properties);
    }
  };

  const changeCode = (code: string) => {
    if (currentItem && currentItem.properties) {
      const changedItem: FormField = {
        ...currentItem,
        properties: {
          ...currentItem.properties,
          script: code
        }
      };

      onChangeItem(changedItem);
    }
  };

  if (currentItem === null) {
    return (
      <Card title="Field Properties" size="small" style={{ minWidth: 300 }}>
        <h4>Select a field to change the properties</h4>
        <Form form={form} id={formId} onFinish={formSubmitHandler} />
      </Card>
    );
  }

  const handleChangeLabel = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { value } = e.currentTarget;
    const nameValue = value
      .split(" ")
      .map((str: string) => String(str).toLowerCase())
      .join("_");

    if (currentItem && !currentItem.isNameDisabled) {
      setFieldsValue({ name: nameValue });
    }
  };

  // Render generic from properties
  const renderGenericProperties = (item: FormField) => {
    return (
      <>
        <Form.Item
          className="form-field__item"
          label="Label"
          name="label"
          initialValue={item.label}
          rules={[{ required: true, transform: (value) => value.trim() }]}
        >
          <Input placeholder="Enter field label" onChange={handleChangeLabel} />
        </Form.Item>

        <Form.Item
          className="form-field__item"
          label="Name"
          name="name"
          initialValue={item.name}
          rules={[{ required: true, transform: (value) => value.trim() }]}
        >
          <Input
            placeholder="Enter field name"
            disabled={item.isNameDisabled && item.name !== ""}
          />
        </Form.Item>
      </>
    );
  };

  // Render from item by type
  const renderFormItem = (item: FormField) => {
    const { id, properties, properties_form = [] } = item;

    return properties_form.map((formItem: FormField, index: number) => {
      const key = `key-${id}-${index}`;
      const fieldClassName = createFieldClassName(formItem);

      // Take form item component by type and fill with data
      const FormItemField = FormFields.hasOwnProperty(formItem.type)
        ? FormFields[formItem.type]
        : FormFields.Default;

      const data = {
        ...formItem,
        label: formattingText(formItem.label, { editText: "id" }),
        initialValue: properties[formItem.name]
      };

      return (
        <FormItemField
          key={key}
          data={data}
          form={form}
          className={fieldClassName}
          onChange={handleChangeForm} // only for manually invoke form onChange, select field
          currentItem={currentItem}
          changeCode={changeCode}
        />
      );
    });
  };

  const hasFields =
    currentItem &&
    currentItem.properties_form &&
    currentItem.properties_form.length > 0;

  return (
    <APIContext.Provider value={{ ...apiConfig }}>
      <Card title="Field Properties" size="small" style={{ minWidth: 300 }}>
        <section className="field-properties__content">
          <Form
            form={form}
            id={formId}
            layout="vertical"
            onFinish={formSubmitHandler}
            onFieldsChange={() => handleChangeForm()}
          >
            {currentItem && hasGenericFields
              ? renderGenericProperties(currentItem)
              : null}
            {hasFields && (
              <>
                <h4>
                  <em>{currentItem.label}</em> Properties
                </h4>
                {renderFormItem(currentItem)}
              </>
            )}
          </Form>
        </section>
      </Card>
    </APIContext.Provider>
  );
};

export default FormProperties;
