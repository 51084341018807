import moment from "moment";
import { Setting } from "types";

export interface TableSetting {
  key: string;
  id: string;
  codeName: string;
  value: string;
  updatedDate: string;
}

export function normalizeSettingsList(settings: Setting[]): TableSetting[] {
  return settings.map(({ id, code_name, value, modified_at }) => {
    const updatedDate = modified_at
      ? moment(modified_at).format("DD/MM/YYYY HH:mm")
      : "";

    return {
      key: id,
      id,
      codeName: code_name,
      value,
      updatedDate
    };
  });
}
