import { ApiConfig } from "types";

export type Entries<T> = {
  [K in keyof T]: [K, T[K]];
}[keyof T][];

export type ContentTypeFormat = Record<
  string,
  { plugins: string[]; accept: string }
>;

export const contentTypeFormat: ContentTypeFormat = {
  video: {
    plugins: ["html5video"],
    accept: "video/*"
  },
  audio: {
    plugins: ["html5audio"],
    accept: "audio/*"
  },
  image: {
    plugins: ["image"],
    accept: "image/*"
  }
};

export interface Form {
  id: string;
  actions: FormAction[];
  created_at: string;
  modified_at: string;
  code_name: string;
  title: string;
  app_token: string;
  content_type: string;
}

export interface FormAction {
  code_name: string;
  data: Record<string, any>;
}

export const getForms = async (
  api: ApiConfig,
  queryParams = new URLSearchParams()
): Promise<any> => {
  const reqInit: RequestInit = {
    method: "GET",
    headers: {
      Authorization: `Token ${api.tokens?.access}`
    }
  };

  try {
    const res = await fetch(`${api.baseUrl}/forms/?${queryParams}`, reqInit);
    const data = (await res.json()) as Form[] & {
      exception?: string;
      detail?: string;
    };

    if (res.ok) {
      const response = data.map(({ code_name, title }) => ({
        id: code_name,
        name: title
      }));
      return response;
    } else {
      await Promise.reject(
        data?.exception ||
          data?.detail ||
          "Something Went Wrong. Please contact support"
      );
    }
  } catch (e) {
    throw new Error(e as string);
  }
};
