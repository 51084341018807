import { useRef, useState } from "react";
import { useParams, useNavigate } from "react-router-dom";
import {
  message,
  Spin,
  Tooltip,
  Typography,
  Button,
  Space,
  Row,
  Col
} from "antd";
import { useMutation, useQuery } from "@tanstack/react-query";

import api, { queries } from "api";
import { Edit } from "assets/svg";
import { FormBuilder, FormField } from "components/FormBuilder";
import { ContentTypesModalForm } from "features/content-types/components";
import { ContentTypeModalState } from "features/content-types/components/ContentTypesModalForm";
import { normalizeContentTypes } from "features/content-types/components/ContentTypesTable/utils";
import { contentTypeMessages, showErrorMessage } from "utils";

import { ErrorProperty, handleErrors } from "./utils";

const formId = "form-builder-properties";

/**
 * ContentTypePage is used to build a form by drag and drop
 * fields with FormBuilder
 */
export const ContentTypePage = () => {
  const { id } = useParams();
  const navigate = useNavigate();
  const [modalOpen, setModalOpen] = useState(false);
  const modalState = useRef<ContentTypeModalState>();

  // Fetch sidebar components
  const { data: sidebarComponents } = useQuery({
    ...queries.contentType.components(),
    onError: (error) => showErrorMessage(error)
  });

  // Fetch content type
  const {
    data: contentType,
    refetch: refetchContentType,
    isLoading
  } = useQuery({
    ...queries.contentType.byId(id),
    enabled: !!id,

    onError: (error) => showErrorMessage(error)
  });

  // Save content type
  const saveMutation = useMutation(
    (data: FormField[]) => api.contentType.save(id!, data),
    {
      onSuccess: () =>
        message.success(contentTypeMessages.contentTypeSuccess("saved")),
      onError: (error: Error | ErrorProperty[]) =>
        error instanceof Error ? showErrorMessage(error) : handleErrors(error)
    }
  );

  const handleClick = () => {
    modalState.current = {
      action: "edit",
      ...normalizeContentTypes([contentType!])[0]
    };
    setModalOpen(true);
  };

  return (
    <>
      <Spin spinning={isLoading}>
        <Row className="mb-20" align="middle" justify="space-between">
          <Col>
            <Typography.Title className="m-0" level={4}>
              {contentType?.name || ""}
              <Tooltip title="Edit">
                <span
                  onClick={handleClick}
                  style={{ cursor: "pointer", margin: "0 10px" }}
                >
                  <Edit />
                </span>
              </Tooltip>
            </Typography.Title>
          </Col>

          <Col>
            <Space>
              <Button onClick={() => navigate("/content-types")}>Cancel</Button>
              <Button
                loading={saveMutation.isLoading}
                htmlType="submit"
                form={formId}
                type="primary"
              >
                Save
              </Button>
            </Space>
          </Col>
        </Row>
      </Spin>

      {!isLoading && (
        <FormBuilder
          formId={formId}
          items={sidebarComponents}
          formData={contentType?.mapping}
          onSubmit={(items) => saveMutation.mutate(items)}
        />
      )}
      <ContentTypesModalForm
        isOpen={modalOpen}
        modalState={modalState.current}
        onClose={() => {
          refetchContentType();
          setModalOpen(false);
        }}
      />
    </>
  );
};
