import { message } from "antd";
import { ApiConfig } from "types";
import { getForms, CKEDITOR as CKEDITORTYPE } from ".";

export const ebsFormPlugin = async (
  evt: CKEDITORTYPE.eventInfo,
  apiConfig?: ApiConfig
) => {
  const name = evt.data.name;

  if (name !== "ebsFormDialog" || !apiConfig) return;

  const dialog = evt.data.definition.dialog;
  const selectedElement = dialog.getSelectedElement();

  try {
    const data = await getForms(apiConfig);
    const selectElement = dialog.getContentElement("ebs-form", "form-ebs-id");

    selectElement.clear();

    data?.forEach(({ id, name }: any) => {
      selectElement.add(name, id);
    });

    if (selectedElement) {
      const id = selectedElement.getAttribute("data-id");
      selectElement.setValue(id);
    }
  } catch (error) {
    dialog.hide();
    error instanceof Error && message.error(error.message);
  }
};
