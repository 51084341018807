import React from "react";
import { Button, Form, Card, Flex } from "antd";
import { DeleteFilled, HolderOutlined } from "@ant-design/icons";
import { NamePath } from "antd/lib/form/interface";

import { useDnDItem } from "components/FormBuilder/hooks/useDndItem";
import { FormField } from "../../interface";
import { renderFormItem } from "./render-form-item";
import type { Identifier } from "dnd-core";

export interface DnDListItemProps {
  label: string;
  name: NamePath;
  fields: FormField[];

  index: number;
  /**
   * Item type in case of nested lists
   */
  itemType: Identifier;

  /**
   * While the user is still dragging change the position visually
   */
  onMovePreview: (dragIndex: number, hoverIndex: number) => void;
  /**
   * After the user is finished dragging (on drop) save the state
   */
  onMoveFinish: () => void;
  onRemove: () => void;
}

const DnDListItem: React.FC<DnDListItemProps> = ({
  name,
  itemType,
  index,
  fields,
  onRemove,
  onMovePreview,
  onMoveFinish,
  label
}) => {
  const {
    itemRef: ref,
    preview,
    drop,
    drag,
    isDragging
  } = useDnDItem({
    index,
    itemType,
    onMoveFinish,
    onMovePreview
  });

  preview(drop(ref));

  const opacity = isDragging ? 0.3 : 1;
  return (
    <Card
      size="small"
      title={
        <div style={{ cursor: "move" }} ref={drag}>
          <Flex align="center" gap={8}>
            <HolderOutlined style={{ fontSize: "1rem" }} />
            <div>
              New {String(label).toLowerCase()} - {index + 1}
            </div>
          </Flex>
        </div>
      }
      extra={<Button size="small" icon={<DeleteFilled />} onClick={onRemove} />}
      ref={ref}
      style={{ opacity, marginBottom: 16 }}
    >
      <Form.Item
        noStyle
        dependencies={[name]}
        // eslint-disable-next-line react/no-children-prop
        children={(form) =>
          fields.map((field, idx) => (
            <React.Fragment key={idx}>
              {renderFormItem(field, [index], form)}
            </React.Fragment>
          ))
        }
      />
    </Card>
  );
};

export default DnDListItem;
