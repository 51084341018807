import { useCallback } from "react";
import { Form, Input, Modal } from "antd";
import { useForm } from "antd/es/form/Form";

import { TableSetting } from "../utils";

interface Props {
  visible: boolean;
  onOk: (value: TableSetting) => void;
  onCancel: () => void;
  setting: TableSetting;
}

export const SettingsPageUpdateModal = ({
  visible,
  onOk,
  onCancel,
  setting
}: Props) => {
  const [form] = useForm();

  const handleOk = useCallback(() => {
    form.validateFields(["value"]).then(() => {
      onOk({ ...setting, ...form.getFieldsValue() });
    });
  }, [form, onOk, setting]);

  return (
    <Modal
      width={420}
      title={`Edit ${setting.codeName}`}
      open={visible}
      onOk={handleOk}
      onCancel={onCancel}
      closable
      destroyOnClose
    >
      <Form form={form} initialValues={setting}>
        <Form.Item
          name="value"
          label="Value"
          rules={[
            {
              required: true,
              message: `Please input ${setting.codeName} value`
            }
          ]}
        >
          <Input />
        </Form.Item>
      </Form>
    </Modal>
  );
};
