import React, { useState } from "react";
import { Form, Button } from "antd";

import HelpTextOrError from "../../HelpTextOrError";

import { FormFieldProps } from "../interface";

import ModalCodeEditor from "./ModalCodeEditor";

const CodeField: React.FC<FormFieldProps> = ({ data, className, currentItem, changeCode }) => {
  const [codeEditorVisible, setCodeEditorVisible] = useState(false);

  const { name, label, properties, initialValue } = data;

  return (
    <Form.Item
      name={name}
      initialValue={initialValue}
      rules={[
        {
          required: properties.required
        }
      ]}
      className={className}
      label={label}
      help={<HelpTextOrError name={name} data={data} />}
    >
      <Button
        className={className}
        type="primary"
        size="small"
        ghost
        onClick={(): void => setCodeEditorVisible(!codeEditorVisible)}
      >
        Open Code Editor
      </Button>
      <ModalCodeEditor
        visible={codeEditorVisible}
        setCodeEditorVisible={setCodeEditorVisible}
        currentItem={currentItem}
        changeCode={changeCode}
        properties={properties}
      />
    </Form.Item>
  );
};

export default CodeField;
