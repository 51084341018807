import moment from "moment";
import { Locale } from "types";

export interface TableLocales {
  id: string;
  key: string;
  value: string;
  label: string;
  updatedDate: string;
}

export function normalizeLocalesList(locales: Locale[]): TableLocales[] {
  return locales.map(({ id, value, label, modified_at }) => {
    const updatedDate = modified_at
      ? moment(modified_at).format("DD/MM/YYYY HH:mm")
      : "";

    return {
      key: id,
      id,
      value,
      label,
      updatedDate
    };
  });
}
