// tslint:disable max-line-length
import React from "react";

const Left: React.FC = () => (
  <svg width="1em" height="1em" viewBox="0 0 18 16">
    <path
      fillRule="evenodd"
      fill="currentColor"
      d="M16.837,2.313 L1.163,2.313 C0.522,2.313 0.000,1.794 0.000,1.157 C0.000,0.519 0.522,-0.000 1.163,-0.000 L16.837,-0.000 C17.478,-0.000 18.000,0.519 18.000,1.157 C18.000,1.794 17.478,2.313 16.837,2.313 ZM1.163,6.844 L10.837,6.844 C11.478,6.844 12.000,7.362 12.000,8.000 C12.000,8.638 11.478,9.156 10.837,9.156 L1.163,9.156 C0.522,9.156 0.000,8.638 0.000,8.000 C0.000,7.362 0.522,6.844 1.163,6.844 ZM1.163,13.687 L16.837,13.687 C17.478,13.687 18.000,14.206 18.000,14.843 C18.000,15.481 17.478,16.000 16.837,16.000 L1.163,16.000 C0.522,16.000 0.000,15.481 0.000,14.843 C0.000,14.206 0.522,13.687 1.163,13.687 Z"
    />
  </svg>
);

const Align = { Left };

export default Align;
