interface DocumentEntity {
  [key: string]: any;
}
interface DocumentResponse {
  count: number;
  next: number | null;
  previous: number | null;
  results: DocumentEntity[];
}

interface SourceOptionsData {
  search: string | null;
  include: string[];
  id_field: string | null;
  display_fields: string[] | null;
}

export const normalizeResponseOptions = (response: DocumentResponse, data: SourceOptionsData) => {
  const { results } = response;

  return results.map((item: DocumentEntity) => {
    const fields = data.display_fields ? data.display_fields : ["name"];
    const byKey = data.id_field || "id";
    const label: string[] = [];

    // Compose label from item
    fields.map((k: string) => label.push(item[k]));

    return {
      key: item[byKey],
      label: label.join(" ")
    };
  });
};
