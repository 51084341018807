// tslint:disable max-line-length
import React from "react";

const Phone = () => (
  <svg width="1em" height="1em" viewBox="0 0 32 31">
    <path
      fillRule="evenodd"
      fill="currentColor"
      d="M3.769,31.000 C2.833,31.000 1.921,30.661 1.225,30.046 C0.446,29.359 -0.000,28.379 -0.000,27.358 L-0.000,22.469 C-0.000,20.614 1.429,19.059 3.325,18.852 C4.440,18.730 5.548,18.508 6.618,18.191 C7.957,17.794 9.399,18.138 10.381,19.090 L12.426,21.070 C16.343,18.995 19.608,15.831 21.751,12.038 L19.705,10.057 C18.723,9.106 18.368,7.708 18.778,6.411 C19.105,5.374 19.335,4.301 19.460,3.221 C19.674,1.385 21.279,-0.000 23.194,-0.000 L28.241,-0.000 C29.294,-0.000 30.306,0.432 31.015,1.186 C31.728,1.943 32.081,2.963 31.984,3.985 C31.651,7.493 30.691,10.880 29.131,14.053 C27.615,17.137 25.578,19.930 23.075,22.354 C20.573,24.778 17.690,26.752 14.506,28.221 C11.231,29.732 7.735,30.662 4.114,30.985 C3.999,30.995 3.884,31.000 3.769,31.000 ZM7.730,20.451 C7.604,20.451 7.475,20.469 7.349,20.507 C6.132,20.867 4.873,21.120 3.604,21.259 C2.975,21.327 2.500,21.848 2.500,22.469 L2.500,27.358 C2.500,27.705 2.645,28.024 2.910,28.257 C3.176,28.493 3.523,28.605 3.885,28.573 C17.473,27.361 28.244,16.927 29.495,3.763 C29.528,3.413 29.412,3.077 29.169,2.818 C28.928,2.563 28.598,2.422 28.241,2.422 L23.194,2.422 C22.553,2.422 22.015,2.882 21.944,3.492 C21.802,4.720 21.540,5.941 21.168,7.120 C21.030,7.558 21.146,8.028 21.473,8.345 L24.803,11.570 L24.402,12.350 C21.906,17.211 17.767,21.220 12.748,23.639 L11.943,24.027 L8.614,20.802 C8.378,20.574 8.060,20.451 7.730,20.451 Z"
    />
  </svg>
);

export default Phone;
