import React from "react";
import { Form } from "antd";

import { makeJSONSerializable } from "components/FormBuilder/utils/json";
import HelpTextOrError from "../../HelpTextOrError";
import UploadFieldContent from "../../UploadFieldContent";
import { FormFieldProps } from "../interface";

import "./ImageField.css";

const ImageField: React.FC<FormFieldProps> = ({ data, className }) => {
  const { name, label, properties, initialValue } = data;

  return (
    <Form.Item
      name={name}
      initialValue={makeJSONSerializable(initialValue)}
      normalize={makeJSONSerializable}
      rules={[{ required: properties.required }]}
      className={className}
      label={label}
      help={<HelpTextOrError name={name} data={data} />}
    >
      <UploadFieldContent
        listType="picture"
        contentType="image"
        uploadText="Upload image"
        selectText="Select image"
      />
    </Form.Item>
  );
};

export default ImageField;
