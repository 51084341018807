import React from "react";
import { Form, Input } from "antd";

import HelpTextOrError from "../../HelpTextOrError";

import { FormFieldProps } from "../interface";
import { normalize, normalizeProperties } from "../utils";

const TextField: React.FC<FormFieldProps> = ({ data, className }) => {
  const { name, label, properties, initialValue } = data;
  const props = normalizeProperties(properties);

  return (
    <Form.Item
      name={name}
      initialValue={initialValue}
      normalize={normalize}
      rules={[
        {
          required: properties.required
        }
      ]}
      className={className}
      label={label}
      help={<HelpTextOrError name={name} data={data} />}
    >
      <Input.TextArea {...props} />
    </Form.Item>
  );
};

export default TextField;
