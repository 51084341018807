import { default as access } from "./access";
import { default as actions } from "./actions";
import { default as content } from "./content";
import { default as contentType } from "./content-type";
import { default as forms } from "./forms";
import { default as locales } from "./locales";
import { default as media } from "./media";
import { default as settings } from "./settings";

const api = {
  access,
  actions,
  content,
  contentType,
  forms,
  locales,
  media,
  settings
};

export * from "./queries";
export * from "./axios";

export default api;
