import { Table } from "antd";

import { normalizeFormResponseList } from "features/forms/utils";
import { FormResponse } from "types";

import { getTableColumns } from "./tableColumns";

interface Props {
  data: FormResponse[];
  paginationConfig?: any;
  loading?: boolean;
}

export const FormsDataTable = ({ data, paginationConfig, loading }: Props) => {
  const formResponses = normalizeFormResponseList(data);
  const tableColumns = getTableColumns(formResponses);

  return (
    <Table
      loading={loading}
      columns={tableColumns}
      dataSource={formResponses}
      pagination={{ hideOnSinglePage: true, ...paginationConfig }}
    />
  );
};
