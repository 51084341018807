import { ColumnProps } from "antd/es/table";

import { PermissionEntity } from "types";

import { PermissionSelect } from "../PermissionSelect";

export const getTableColumns = (
  onChangeAccess: (items: PermissionEntity[], category: string) => void,
  items: PermissionEntity[],
  category: string
): ColumnProps<PermissionEntity>[] => {
  return [
    { title: "Name", dataIndex: "target_doc_name", key: "target_doc_id" },
    {
      title: "Action",
      key: "action",
      width: 150,
      render: (item: PermissionEntity) => {
        return (
          <PermissionSelect
            onChangeAccess={onChangeAccess}
            category={category}
            items={items}
            item={item}
          />
        );
      }
    }
  ];
};
