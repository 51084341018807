import { createQueryKeys } from "@lukemorales/query-key-factory";

import { Setting, SettingsUpdate } from "types";
import { axios } from "./axios";

const getAll = async () => {
  const { data } = await axios.get<Setting[]>("/settings/");

  return data;
};

const getById = async (id: string) => {
  const { data } = await axios.get<Setting[]>(`/settings/${id}/`);

  return data;
};

const update = async (id: string, setting: SettingsUpdate) => {
  const { data } = await axios.put(`/settings/${id}/`, setting);

  return data;
};

const settings = {
  getAll,
  getById,
  update
};

export const settingsQueries = createQueryKeys("settings", {
  all: () => ({
    queryKey: [""],
    queryFn: () => getAll()
  }),
  byId: (id) => ({
    queryKey: [id],
    queryFn: () => getById(id)
  })
});

export default settings;
