export enum ContentTypeCategory {
  COLLECTION = "collection",
  COMPONENT = "component",
  PAGE_TYPE = "page_type"
}

export const contentTypeCategories = [
  {
    value: ContentTypeCategory.COLLECTION,
    label: "Collection"
  },
  {
    value: ContentTypeCategory.COMPONENT,
    label: "Component"
  },
  {
    value: ContentTypeCategory.PAGE_TYPE,
    label: "Page Type"
  }
];
