import React from "react";
import { Form, Checkbox } from "antd";
import classNames from "classnames";

import HelpTextOrError from "../../HelpTextOrError";

import { FormFieldProps } from "../interface";

import "./BooleanField.css";

const BooleanField: React.FC<FormFieldProps> = ({ className, data }) => {
  const { name, label, properties, initialValue } = data;

  return (
    <Form.Item
      name={name}
      initialValue={initialValue}
      valuePropName="checked"
      rules={[
        {
          required: properties.required
        }
      ]}
      className={classNames("ant-row-flex", className)}
      help={<HelpTextOrError name={name} data={data} />}
    >
      <Checkbox>{label}</Checkbox>
    </Form.Item>
  );
};

export default BooleanField;
