import React, { useCallback, useContext } from "react";
import { message, Modal } from "antd";
import { ModalProps } from "antd/es/modal";
import { APIContext } from "../../context";
import FormMediaUpload from "./FormMediaUpload";
import { createMedia } from "./utils";

const formId = "form-media-upload-modal";

interface FormMediaProps extends ModalProps {
  visible: boolean;
  onOk: (data: any) => void;
}

const FormMediaModal: React.FC<FormMediaProps> = ({
  visible,
  onOk,
  ...props
}) => {
  // Set api config and get references from api
  const apiConfig = useContext(APIContext);

  // Form handlers
  const handleFormSubmit = useCallback(
    async (values: any) => {
      try {
        if (apiConfig) {
          createMedia(apiConfig, values).then((response: any) => {
            if (response) {
              onOk(response);
            }
          });
        }
      } catch (e: any) {
        message.error(e.message);
      }
    },
    [apiConfig, onOk]
  );

  return (
    <Modal
      title="Upload media file"
      width={500}
      open={visible}
      okButtonProps={{
        form: formId,
        htmlType: "submit"
      }}
      destroyOnClose={true}
      {...props}
    >
      <FormMediaUpload
        formId={formId}
        onSubmit={handleFormSubmit}
        isModal={true}
      />
    </Modal>
  );
};

export default FormMediaModal;
