import React from "react";
import { Select, Space, SpaceProps } from "antd";
import {
  PlusOutlined,
  SelectOutlined,
  UploadOutlined
} from "@ant-design/icons";

export enum SelectType {
  EXIST = "EXIST",
  NEW = "NEW"
}

interface MediaSwitchProps {
  uploadText?: React.ReactNode;
  selectText?: React.ReactNode;
  onClick?: SpaceProps["onClick"];
  state: [
    SelectType | null,
    React.Dispatch<React.SetStateAction<SelectType | null>>
  ];
}

const MediaSwitch: React.FC<MediaSwitchProps> = ({
  uploadText,
  selectText,
  onClick,
  state
}) => {
  const [, setSelectType] = state;

  return (
    <Space direction="vertical">
      <Select
        value={null}
        popupMatchSelectWidth={false}
        placeholder={
          <Space>
            <PlusOutlined />
            <span style={{ fontWeight: 500 }}>Add media</span>
          </Space>
        }
        options={[
          {
            value: SelectType.EXIST,
            label: (
              <Space onClick={onClick}>
                <SelectOutlined /> {selectText}
              </Space>
            )
          },
          {
            value: SelectType.NEW,
            label: (
              <Space onClick={onClick}>
                <UploadOutlined /> {uploadText}
              </Space>
            )
          }
        ]}
        onChange={setSelectType}
      />
    </Space>
  );
};

export default MediaSwitch;
