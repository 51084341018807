import { message } from "antd";

export const handleErrors = (error: any) => {
  let errorMessage = null;

  if (error && error.hasOwnProperty("exception")) {
    errorMessage = error.exception;
  }

  if (error && error.hasOwnProperty("value")) {
    errorMessage = error.value.detail;
  }

  if (errorMessage) {
    message.error(errorMessage);
  }
};
