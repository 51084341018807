import { Button } from "antd";

import { Error } from "assets/svg";

interface Props {
  resetError?: () => void;
}

export const FallBack = ({ resetError }: Props) => {
  return (
    <div
      style={{
        gap: "1rem",
        margin: "auto"
      }}
      className="d-flex flex-column justify-content-center align-items-center vh-100"
    >
      <Error />
      <div className="d-flex flex-column justify-content-center align-items-center">
        <h1 style={{ textAlign: "center" }}>
          Oops! An unexpected error occurred
        </h1>
        <Button onClick={resetError} size="large" type="primary">
          Try again
        </Button>
      </div>
    </div>
  );
};
