import { Modal } from "antd";

export const showDeleteConfirm = (onOk: () => Promise<void>) => {
  const text = "There are no entries that link to this asset.";

  Modal.confirm({
    title: "Are you sure?",
    content: text,
    okText: "Yes, delete asset",
    okType: "danger",
    cancelText: "Cancel",
    okButtonProps: {
      className: "btn"
    },
    cancelButtonProps: {
      className: "btn btn-default"
    },
    onOk
  });
};
