// tslint:disable max-line-length
import React from "react";

const Url = () => (
  <svg width="1em" height="1em" viewBox="0 0 18 18">
    <path
      fillRule="evenodd"
      fill="currentColor"
      d="M15.364,2.636 C13.664,0.936 11.404,-0.000 9.000,-0.000 C6.596,-0.000 4.336,0.936 2.636,2.636 C0.936,4.336 -0.000,6.596 -0.000,9.000 C-0.000,11.404 0.936,13.664 2.636,15.364 C4.336,17.064 6.596,18.000 9.000,18.000 C11.404,18.000 13.664,17.064 15.364,15.364 C17.064,13.664 18.000,11.404 18.000,9.000 C18.000,6.596 17.064,4.336 15.364,2.636 ZM16.561,8.297 L14.153,8.297 C14.063,6.229 13.583,4.299 12.776,2.800 C12.669,2.600 12.556,2.411 12.440,2.231 C14.705,3.387 16.316,5.647 16.561,8.297 ZM8.297,1.554 L8.297,8.297 L5.255,8.297 C5.341,6.463 5.762,4.768 6.462,3.466 C6.985,2.495 7.628,1.832 8.297,1.554 ZM8.297,9.703 L8.297,16.446 C7.628,16.168 6.985,15.505 6.462,14.534 C5.762,13.232 5.341,11.537 5.255,9.703 L8.297,9.703 ZM9.703,16.446 L9.703,9.703 L12.745,9.703 C12.659,11.537 12.238,13.232 11.538,14.534 C11.015,15.505 10.371,16.168 9.703,16.446 ZM9.703,8.297 L9.703,1.554 C10.371,1.832 11.015,2.495 11.538,3.466 C12.238,4.768 12.659,6.463 12.745,8.297 L9.703,8.297 ZM5.560,2.231 C5.443,2.411 5.331,2.600 5.224,2.800 C4.417,4.299 3.936,6.229 3.847,8.297 L1.439,8.297 C1.684,5.647 3.295,3.387 5.560,2.231 ZM1.439,9.703 L3.847,9.703 C3.936,11.771 4.417,13.701 5.224,15.200 C5.331,15.400 5.443,15.589 5.560,15.769 C3.295,14.613 1.684,12.353 1.439,9.703 ZM12.440,15.769 C12.556,15.589 12.669,15.400 12.776,15.200 C13.583,13.701 14.063,11.771 14.153,9.703 L16.561,9.703 C16.316,12.353 14.705,14.613 12.440,15.769 Z"
    />
  </svg>
);

export default Url;
