import React from "react";
import { Route, Routes, useLocation } from "react-router-dom";

import { ApiConfig } from "types";
import { contentTypeFormat, Entries } from "../components/Editor";
import { FormMediaSelectModal } from "../components/FormBuilder/FormGenerator";

interface Props {
  apiConfig: ApiConfig;
}

function withCKEditorMediaRoute<T extends React.PropsWithChildren<Props>>(
  Component: React.ComponentType<T>,
  path = "/cke-media-select"
) {
  // eslint-disable-next-line react/display-name
  return ({ apiConfig, ...props }: Props & T) => {
    const { search } = useLocation();
    const CKEDITOR = window?.opener?.CKEDITOR;

    const getParam = () => {
      if (!CKEDITOR) return;
      const name = CKEDITOR.dialog.getCurrent()?.definition.dialog._.name;

      const keyParam = (
        Object.entries(contentTypeFormat) as Entries<typeof contentTypeFormat>
      ).find(([_, { plugins }]) => plugins.includes(name))?.[0];

      return keyParam;
    };

    const contentType =
      new URLSearchParams(search).get("content_type") || getParam();

    const onOk = (data: any) => {
      const fnNum = new URLSearchParams(search).get("CKEditorFuncNum");
      CKEDITOR && CKEDITOR.tools.callFunction(fnNum, data?.url || data);
      window.close();
    };

    return (
      <>
        <Routes>
          <Route
            path={path}
            element={
              <FormMediaSelectModal
                apiModalConfig={apiConfig}
                width="100%"
                visible
                title={null}
                closeIcon={<></>}
                onCancel={() => window.close()}
                onOk={onOk}
                style={{ maxWidth: "100%", top: 0, padding: 0 }}
                bodyStyle={{ maxHeight: "unset", height: "calc(100vh - 53px)" }}
                transitionName=""
                contentType={contentType}
              />
            }
          />
        </Routes>
        <Component {...(props as T)} />
      </>
    );
  };
}

export default withCKEditorMediaRoute;
