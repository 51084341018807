import React from "react";
import { Form } from "antd";

import Editor from "../../../Editor";
import HelpTextOrError from "../../HelpTextOrError";

import { FormFieldProps } from "../interface";

const RichTextField: React.FC<FormFieldProps> = ({ data, className }) => {
  const { name, label, properties, initialValue } = data;

  return (
    <Form.Item
      name={name}
      initialValue={initialValue}
      normalize={(value: any) => {
        if (value && typeof value === "object") {
          return value.editor.getData();
        }

        return value;
      }}
      rules={[
        {
          required: properties.required
        }
      ]}
      className={className}
      label={label}
      help={<HelpTextOrError name={name} data={data} />}
      valuePropName="data"
    >
      <Editor />
    </Form.Item>
  );
};

export default RichTextField;
