import { FormField, FormTabs } from "components/FormBuilder";
import { FormSchema } from "components/FormBuilder/interface";
import { Action } from "types";
export interface ActionValueProps {
  key: number;
  value: string;
}

export const apiValue = (value: string) =>
  value
    .split(" ")
    .map((item: string) => String(item).toLowerCase())
    .join("_");

export const isFormTabsArray = (schema: any[]): schema is FormTabs[] => {
  return schema[0] && schema[0].form;
};

export const createFieldClassName = (field: FormField) =>
  `form-field__item field-item__${String(field.type).toLowerCase()}`;

export const extractActionValue = (
  actions: ActionValueProps[],
  key: number
): string | undefined => {
  return actions.find((item) => item.key === key)?.value;
};

export const formData = (
  actions: ActionValueProps[],
  key: number,
  actionsQuery?: Action[]
): FormSchema | undefined => {
  return actionsQuery?.find(
    (x) => x.code_name === extractActionValue(actions, key)
  )?.form;
};
