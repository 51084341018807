import React, { useState } from "react";
import { DndProvider } from "react-dnd";
import { HTML5Backend } from "react-dnd-html5-backend";

import { Button, List } from "antd";
import { PlusOutlined } from "@ant-design/icons";
import { NamePath } from "antd/lib/form/interface";

import { useListOrder } from "components/FormBuilder/hooks/useListOrder";
import { makeNamePath } from "components/FormBuilder/utils/form";

import ContentTypeSelectModal from "./ContentTypeSelectModal";
import DnDListItem from "./DnDListItem";

export interface DnDListProps {
  name: NamePath;
  label: string;

  /**
   * Length of the Form.List value
   */
  listLength: number;

  onRemove: React.Dispatch<number>;
  onAdd: (id: string) => void;
  onReorder: (order: number[]) => void;
  parentName?: NamePath;
}

const DnDList: React.FC<DnDListProps> = ({
  listLength,
  label,
  name,
  onRemove,
  onAdd,
  onReorder,
  parentName
}) => {
  const listOrder = useListOrder(listLength);
  const [contentTypeModalOpen, setContentTypeModalOpen] = useState(false);

  const handleAdd = (id: string) => {
    onAdd(id);
    listOrder.handleAdd();
    setContentTypeModalOpen(false);
  };

  return (
    <>
      <DndProvider backend={HTML5Backend}>
        <List
          size="small"
          itemLayout="horizontal"
          dataSource={listOrder.currentOrder}
          renderItem={({ id, index: initialIndex }, index) => (
            <DnDListItem
              key={id}
              name={makeNamePath(name, initialIndex)}
              parentName={parentName}
              itemType={makeNamePath(name).join(".")}
              index={initialIndex}
              onMovePreview={listOrder.handleMovePreview}
              onMoveFinish={() => {
                onReorder(listOrder.currentOrder.map((item) => item.index));
                listOrder.handleFinish();
              }}
              onRemove={() => {
                onRemove(initialIndex);
                listOrder.handleRemove(index);
              }}
            />
          )}
          footer={
            <Button
              onClick={() => {
                setContentTypeModalOpen(true);
              }}
              icon={<PlusOutlined />}
            >
              Add {String(label || "").toLowerCase()}
            </Button>
          }
        />
      </DndProvider>
      <ContentTypeSelectModal
        open={contentTypeModalOpen}
        onCancel={() => setContentTypeModalOpen(false)}
        onOk={handleAdd}
      />
    </>
  );
};

export default DnDList;
