import React, { useCallback, useEffect } from "react";
import { Form, Input, Upload, Button } from "antd";
import { UploadOutlined } from "@ant-design/icons";
import classNames from "classnames";
import {
  formItemLayout,
  normalizeValues,
  normalizeFile,
  UPLOAD_ENDPOINT
} from "./utils";
import "./FormMediaUpload.css";

interface FormMediaProps {
  formId?: string;
  formData?: any;
  onSubmit: (values: any, reset?: () => void) => void;
  isModal?: boolean;
}

const FormMediaUpload: React.FC<FormMediaProps> = ({
  onSubmit,
  formId,
  formData,
  isModal = false
}) => {
  const [form] = Form.useForm();
  const {
    getFieldError,
    getFieldValue,
    isFieldTouched,
    setFieldsValue,
    resetFields
  } = form;

  const formSubmitHandler = useCallback(
    (values: any) => {
      const data: any = normalizeValues(values);

      if (data) {
        onSubmit(data, resetFields);
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [form]
  );

  useEffect(() => {
    if (formData) {
      setFieldsValue({
        name: formData.name,
        description: formData.description,
        file: [
          {
            uid: formData.id || "uid-media",
            url: formData.url,
            name: formData.name
          }
        ]
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [formData]);

  // Only show error after a field is touched.
  const nameError = isFieldTouched("name") && getFieldError("name");
  const descriptionError =
    isFieldTouched("description") && getFieldError("description");
  const fileError = isFieldTouched("file") && getFieldError("file");

  const handleUploadChange = (data: any) => {
    if (!getFieldValue("name")) {
      setFieldsValue({
        name: data.file.name
      });
    }
  };

  return (
    <Form
      className={classNames("form-media-upload", {
        "form-media__modal": isModal
      })}
      form={form}
      id={formId}
      onFinish={formSubmitHandler}
      {...formItemLayout}
    >
      <Form.Item
        label="Name"
        name="name"
        rules={[{ required: true }]}
        validateStatus={nameError ? "error" : ""}
        help={nameError || ""}
      >
        <Input type="text" placeholder="Enter name..." />
      </Form.Item>

      <Form.Item
        label="Description"
        name="description"
        validateStatus={descriptionError ? "error" : ""}
        help={descriptionError || ""}
      >
        <Input.TextArea placeholder="Enter description..." />
      </Form.Item>

      <Form.Item
        label="Upload"
        name="file"
        valuePropName="fileList"
        rules={[{ required: true }]}
        getValueFromEvent={normalizeFile}
        validateStatus={fileError ? "error" : ""}
        help={fileError || ""}
      >
        <Upload
          name="file"
          action={UPLOAD_ENDPOINT}
          listType="picture"
          onChange={handleUploadChange}
        >
          <Button>
            <UploadOutlined /> Upload
          </Button>
        </Upload>
      </Form.Item>
    </Form>
  );
};

export default FormMediaUpload;
