import React, { useState, useCallback, useEffect } from "react";

import { Upload } from "antd";
import { UploadListType } from "antd/lib/upload/interface";

import { MediaData } from "./FormFields/interface";
import { FormMediaSelectModal } from "./FormGenerator";
import { FormMediaModal } from "./FormGenerator/FormMediaUpload";
import { MediaSwitch, SelectType } from "./FormGenerator/MediaSwitch";

interface ImageFieldContentProps {
  value?: MediaData | null | undefined;
  onChange?: React.Dispatch<MediaData | null | undefined>;
  listType?: UploadListType;
  contentType?: string;
  uploadText?: React.ReactNode;
  selectText?: React.ReactNode;
}
const UploadFieldContent: React.FC<ImageFieldContentProps> = ({
  value,
  onChange,
  listType,
  contentType,
  uploadText,
  selectText
}) => {
  const [visible, setVisibility] = useState<boolean>(false);
  const [selectType, setSelectType] = useState<SelectType | null>(null);

  /**
   * This effect will run once and will assure that the value is being normalized with `makeJSONSerializable`
   */
  useEffect(() => {
    if (value) onChange?.(value);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  // Media modal handlers
  const handleUploadClick = () => setVisibility(true);
  const handleCancel = () => setVisibility(false);

  const handleOk = useCallback(
    (image: any) => {
      onChange?.(image);

      setVisibility(false);
    },
    [onChange]
  );

  // Reset image data and field value on remove image
  const handleFileRemove = useCallback(() => {
    onChange?.(null);
  }, [onChange]);

  return (
    <>
      {!value ? (
        <MediaSwitch
          uploadText={uploadText || "Upload"}
          selectText={selectText || "Select"}
          onClick={handleUploadClick}
          state={[selectType, setSelectType]}
        />
      ) : (
        <Upload
          listType={listType}
          fileList={[value]}
          onRemove={handleFileRemove}
        />
      )}
      {selectType === SelectType.NEW ? (
        <FormMediaModal
          visible={visible}
          onOk={handleOk}
          onCancel={handleCancel}
        />
      ) : (
        <FormMediaSelectModal
          contentType={contentType}
          visible={visible}
          onOk={handleOk}
          onCancel={handleCancel}
        />
      )}
    </>
  );
};

export default UploadFieldContent;
