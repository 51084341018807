import { Link } from "react-router-dom";
import { MenuProps, Dropdown, Button } from "antd";
import { EllipsisOutlined } from "@ant-design/icons";
import { ColumnProps } from "antd/es/table";
import moment from "moment";

import { Form } from "types";

export const getTableColumns = (
  getActionColumnMenu: (content: Form) => MenuProps["items"]
): ColumnProps<Form>[] => {
  return [
    {
      title: "Name",
      dataIndex: "title",
      render: (name: string, item) => <Link to={`${item.id}/data`}>{name}</Link>
    },
    {
      title: "API NAME",
      dataIndex: "code_name"
    },
    {
      title: "Updated",
      dataIndex: "modified_at",
      render: (data) => moment(data).format("DD/MM/YYYY HH:mm")
    },
    {
      title: "",
      dataIndex: "",
      align: "right",
      render: (_, item) => (
        <Dropdown
          trigger={["click"]}
          placement="bottomRight"
          menu={{ items: getActionColumnMenu(item) }}
        >
          <Button type="text" icon={<EllipsisOutlined />} />
        </Dropdown>
      )
    }
  ];
};
