import React from "react";
import { useNavigate, useParams } from "react-router-dom";
import { Spin, Alert, Button, Typography, Table } from "antd";

import { AccessFormTable } from "features/access/components";
import { useAccessEntities } from "features/access/hooks";
import { capitalize } from "utils";

/**
 * AccessForm is used to set the permissions for users and teams
 */
export const AccessForm = () => {
  const navigate = useNavigate();
  const { group } = useParams();
  const { data, isError, error, isLoading, handleChange, handleSave } =
    useAccessEntities();

  if (isError) {
    return (
      <Alert
        type="error"
        message="Error"
        description={error?.message}
        closable
        onClose={() => navigate("/access")}
      />
    );
  }

  if (isLoading) {
    return (
      <div className="page-spin">
        <Spin />
      </div>
    );
  }

  return (
    <div className="page-form">
      {data.length ? (
        data.map(({ category, permissions }, index) => {
          return (
            <React.Fragment key={index}>
              <Typography.Title className="m-0" level={5}>
                {`${capitalize(category.replace("_", " "))} access ${
                  group && `for ${group}`
                }`}
              </Typography.Title>

              <div className="my-20">
                <AccessFormTable
                  key={index}
                  items={permissions}
                  category={category}
                  onChangeAccess={handleChange}
                />
              </div>
            </React.Fragment>
          );
        })
      ) : (
        <Table />
      )}
      {!!data.length && (
        <div className="d-flex justify-content-between">
          <Button onClick={() => navigate("/access")}>Cancel</Button>
          <Button
            disabled={!data.length}
            type="primary"
            onClick={() => {
              handleSave.mutate(data);
            }}
            loading={handleSave.isLoading}
          >
            Save
          </Button>
        </div>
      )}
    </div>
  );
};
