import fetch from "cross-fetch";
import moment from "moment";
import { ApiConfig } from "types";
import { Media } from "../../FormFields/interface";

export interface FormMediaValues {
  name: string;
  description: string;
  file: any;
}

// Normalize data for endpoint
export const normalizeValues = (values: FormMediaValues): any => {
  const { name, description, file } = values;

  // Waiting for file to be uploaded
  if (file && file[0].response) {
    const { type, response, size } = file[0];

    return {
      name,
      content_type: type,
      url: response.url,
      description,
      size,
      width: response.attributes.width,
      height: response.attributes.height
    };
  }

  return values;
};

export const checkImageURL = (url?: string) => {
  return url?.toLowerCase().match(/\.(jpeg|jpg|gif|png)$/) != null;
};

export interface TableContentType
  extends Omit<Media, "modified_at" | "modified_by"> {
  updatedDate: string;
  updatedBy: string;
}

export function normalizeMediaList(mediaList: Media[]): TableContentType[] {
  if (!mediaList) {
    return [];
  }

  return mediaList.map(({ modified_at, modified_by, ...rest }) => {
    const updatedDate = modified_at
      ? moment(modified_at).format("DD/MM/YYYY HH:mm")
      : "";

    const updatedBy = `${modified_by.first_name} ${modified_by.last_name}`;

    return {
      updatedDate,
      updatedBy,
      ...rest
    };
  });
}

export const getListMedia = async (
  api: ApiConfig,
  queryParams: URLSearchParams
): Promise<any> => {
  const reqInit: RequestInit = {
    method: "GET",
    headers: {
      Authorization: `Token ${api.tokens?.access}`
    }
  };
  let response = null;

  try {
    const res = await fetch(
      `${api.baseUrl}/media/list/?${queryParams}`,
      reqInit
    );

    if (res.ok) {
      response = await res.json();
    }
  } catch (e) {
    throw e;
  }

  return response;
};
