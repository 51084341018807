import React from "react";
import { Form, Input } from "antd";

import { regexpFromString } from "components/FormBuilder/utils/regexp";
import HelpTextOrError from "../../HelpTextOrError";

import { FormFieldProps } from "../interface";
import { normalize } from "../utils";

const URLField: React.FC<FormFieldProps> = ({ data, className }) => {
  const { name, label, properties, initialValue } = data;

  return (
    <Form.Item
      name={name}
      initialValue={initialValue}
      normalize={normalize}
      rules={[
        {
          type: "url",
          required: properties.required
        },
        {
          pattern: properties.regex
            ? regexpFromString(properties.regex)
            : undefined
        }
      ]}
      className={className}
      label={label}
      help={<HelpTextOrError name={name} data={data} />}
    >
      <Input placeholder={properties.placeholder} />
    </Form.Item>
  );
};

export default URLField;
