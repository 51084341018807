import { message, Table, MenuProps } from "antd";
import { useMutation, useQueryClient } from "@tanstack/react-query";

import api, { queries } from "api";
import { Form } from "types";
import { showErrorMessage } from "utils";

import { getTableColumns } from "./tableColumns";
import { showDeleteConfirm } from "./utils";

interface Props {
  loading: boolean;
  data: Form[];
  handleClick: (value: Form) => void;
}

export const FormsPageTable = ({ handleClick, data, loading }: Props) => {
  const queryClient = useQueryClient();

  const mutationDelete = useMutation(api.forms.remove, {
    onSuccess: () => {
      queryClient.invalidateQueries(queries.forms.all().queryKey);
      message.success("Form was deleted successfully");
    },
    onError: showErrorMessage
  });

  const getActionColumnMenu = (context: Form): MenuProps["items"] => [
    {
      key: "edit",
      label: "Edit",
      onClick: () => handleClick(context)
    },
    {
      key: "delete",
      label: "Delete",
      onClick: () => {
        const onOk = () => mutationDelete.mutateAsync(context.id);
        showDeleteConfirm(onOk);
      }
    }
  ];

  const tableColumns = getTableColumns(getActionColumnMenu);

  return (
    <Table
      columns={tableColumns}
      rowKey="id"
      dataSource={data}
      loading={loading}
      pagination={false}
      bordered
    />
  );
};
