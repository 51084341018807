import { FormField } from "./interface";

export interface FormBuilderState {
  sidebarItems?: any;
  formItems?: any;
  currentItem?: FormField | null;
}

export enum ReducerActionType {
  SET_SIDEBAR_ITEMS,
  SET_FORM_ITEMS,
  SET_CURRENT_ITEM
}

export interface ReducerAction {
  type: ReducerActionType;
  sidebarItems?: any;
  formItems?: any;
  currentItem?: FormField | null;
}

export const initialState: FormBuilderState = {
  sidebarItems: [],
  formItems: [],
  currentItem: null
};

export const reducer = (state: FormBuilderState, action: ReducerAction): FormBuilderState => {
  switch (action.type) {
    case ReducerActionType.SET_SIDEBAR_ITEMS: {
      if (!action.sidebarItems) {
        throw new Error("Form fields array missing");
      }

      return {
        ...state,
        sidebarItems: action.sidebarItems
      };
    }

    case ReducerActionType.SET_FORM_ITEMS: {
      if (!action.formItems) {
        throw new Error("Form fields array missing");
      }

      return {
        ...state,
        formItems: action.formItems
      };
    }

    case ReducerActionType.SET_CURRENT_ITEM: {
      return {
        ...state,
        currentItem: action.currentItem
      };
    }

    default:
      throw new Error("Unexpected action type");
  }
};
