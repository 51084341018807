import BooleanField from "./BooleanField";
import CodeField from "./CodeField";
import ComponentField from "./ComponentField";
import ContentTypeMappingField from "./ContentTypeMappingField";
import DateField from "./DateField";
import DateTimeField from "./DateTimeField";
import DocumentReferenceField from "./DocumentReferenceField";
import EmailField from "./EmailField";
import FileField from "./FileField";
import ImageField from "./ImageField";
import MultipleComponentField from "./MultipleComponentField";
import MultipleReferenceField from "./MultipleReferenceField";

import NoTypeField from "./NoTypeField";
import NumberField from "./NumberField";
import PhoneField from "./PhoneField";
import ReferenceField from "./ReferenceField";
import RepeaterField from "./RepeaterField";
import RichTextField from "./RichTextField";
import SelectChoicesField from "./SelectChoicesField";
import SelectField from "./SelectField";
import StringField from "./StringField";
import TextField from "./TextField";
import URLField from "./URLField";

// Common styles
import "./styles.css";

const FormFields: any = {};

FormFields.String = StringField;
FormFields.Text = TextField;
FormFields.Boolean = BooleanField;
FormFields.Number = NumberField;
FormFields.URL = URLField;
FormFields.Phone = PhoneField;
FormFields.Email = EmailField;
FormFields.RichText = RichTextField;
FormFields.Date = DateField;
FormFields.DateTime = DateTimeField;
FormFields.Reference = ReferenceField;
FormFields.MultipleReference = MultipleReferenceField;
FormFields.DocumentReference = DocumentReferenceField;
FormFields.File = FileField;
FormFields.Image = ImageField;
FormFields.Select = SelectField;
FormFields.Code = CodeField;
FormFields.ContentTypeMapping = ContentTypeMappingField;
FormFields.SelectChoices = SelectChoicesField;
FormFields.Repeater = RepeaterField;
FormFields.Component = ComponentField;
FormFields.MultipleComponent = MultipleComponentField;
// Set default type
FormFields.Default = NoTypeField;

export default FormFields;
