import { useNavigate, useParams } from "react-router-dom";
import { Tabs, TabsProps } from "antd";

import { FormsData } from "../components";
import { FormsCreateUpdate } from "../components/FormsCreateUpdate";

export const FormsEditPage = () => {
  const navigate = useNavigate();
  const { id, tab = "definition" } = useParams();

  const items: TabsProps["items"] = [
    { label: "Definition", key: "definition", children: <FormsCreateUpdate /> },
    { label: "Data", key: "data", children: <FormsData /> }
  ];

  return (
    <Tabs
      defaultActiveKey={tab}
      activeKey={tab}
      onTabClick={(key: string) => navigate(`/forms/${id}/${key}`)}
      items={items}
    />
  );
};
