import React from "react";
import { TableProps, Table } from "antd";

import { Media } from "../../FormFields/interface";

import { getTableColumns, normalizeMediaList } from ".";

import "./FormMediaSelect.css";

interface FormMediaSelectProps extends TableProps<Media> {
  media?: Media[];
  onClick: (file: Media) => void;
  selectedMedia?: Media;
}

const FormMediaSelect: React.FC<FormMediaSelectProps> = ({
  media = [],
  onClick,
  selectedMedia,
  ...props
}) => {
  const columns = getTableColumns();
  const data = normalizeMediaList(media);

  return (
    <Table
      className="form-media-select__table"
      columns={columns}
      dataSource={data as any}
      rowKey="id"
      scroll={{ x: true }}
      onRow={(record) => ({
        onClick: () => onClick?.(record)
      })}
      rowSelection={{
        type: "radio",
        selectedRowKeys: [selectedMedia?.id || ""],
        onSelect: onClick
      }}
      {...props}
    />
  );
};

export default FormMediaSelect;
