import { nanoid } from "nanoid";
import { FormField, Field, FieldProperties } from "../interface";

// Normalized form item after it was dragged from sidebar
export const normalizedFormItem = (item: Field): FormField => {
  const properties: FieldProperties = {};
  const { properties_form } = item;

  properties_form.forEach((prop: FormField) => {
    properties[prop.name] = prop.properties.default;
  });

  return {
    id: nanoid(), // specify unique id for drag and drop the same type
    label: "",
    name: "",
    isNameDisabled: false,
    type: item.type,
    fieldType: item.label,
    properties,
    properties_form
  };
};

const regex = /(auto|scroll)/;

const scroll = (element: HTMLElement) =>
  regex.test(getComputedStyle(element, null).getPropertyValue("overflow-y"));

export const scrollparent = (element: HTMLElement | null): any =>
  !element || element === document.body
    ? document
    : scroll(element)
    ? element
    : scrollparent(element.parentElement);
