import { Modal } from "antd";

import moment from "moment";
import { ContentEntity } from "types";

const { confirm } = Modal;

export interface TableContentType {
  id: string;
  key: string;
  name: string | null;
  contentType: {
    id: string;
    name: string;
  };
  publishedDate: string;
  updatedDate: string;
  updatedBy: string;
  status: string;
}

export function normalizeContentList(
  contentList: ContentEntity[]
): TableContentType[] {
  return contentList.map(
    ({
      id,
      title,
      type_name,
      type_id,
      publish_date,
      modified_at,
      modified_by,
      status
    }: ContentEntity): TableContentType => {
      const updatedDate = modified_at
        ? moment(modified_at).format("DD/MM/YYYY HH:mm")
        : "";
      const publishedDate = publish_date
        ? moment(publish_date).format("DD/MM/YYYY HH:mm")
        : "";

      const updatedBy = `${modified_by.first_name} ${modified_by.last_name}`;

      return {
        key: id,
        id,
        name: title,
        publishedDate,
        updatedDate,
        updatedBy,
        status,
        contentType: {
          id: type_id,
          name: type_name
        }
      };
    }
  );
}

// Confirm delete content type utils
export const showDeleteConfirm = (onOk: () => Promise<void>) => {
  const text = `There are no other entries that link to this entry.`;

  confirm({
    title: "Are you sure?",
    content: text,
    okText: "Yes, delete entry",
    okType: "danger",
    cancelText: "Cancel",
    okButtonProps: {
      className: "btn"
    },
    cancelButtonProps: {
      className: "btn btn-default"
    },
    onOk
  });
};

export const showDuplicateConfirm = (
  onOk: () => Promise<void>,
  title?: string | null
) => {
  const text = `Are you sure you want to duplicate "${title || "this entry"}"?`;

  confirm({
    title: "Are you sure?",
    content: text,
    okText: "Yes, duplicate",
    okType: "primary",
    cancelText: "Cancel",
    okButtonProps: { className: "btn" },
    cancelButtonProps: { className: "btn btn-default" },
    onOk
  });
};
