import React from "react";
import { Form, Input } from "antd";

import HelpTextOrError from "../../HelpTextOrError";

import { FormFieldProps } from "../interface";
import { normalize, normalizeProperties, propertiesToRule } from "../utils";

const StringField: React.FC<FormFieldProps> = ({ data, className }) => {
  const { name, label, properties, initialValue } = data;
  const props = normalizeProperties(properties);

  return (
    <Form.Item
      name={name}
      initialValue={initialValue}
      normalize={normalize}
      rules={[
        {
          required: properties.required,
          pattern: properties.regex,
          ...propertiesToRule(properties)
        }
      ]}
      className={className}
      label={label}
      help={<HelpTextOrError name={name} data={data} />}
    >
      <Input type="text" {...props} />
    </Form.Item>
  );
};

export default StringField;
