import { Select } from "antd";

import { PermissionEntity } from "types";

import { permissions, AccessOption } from "./utils";

interface Props {
  onChangeAccess: (items: PermissionEntity[], category: string) => void;
  item: PermissionEntity;
  items: PermissionEntity[];
  category: string;
}

export const PermissionSelect = ({
  onChangeAccess,
  item,
  items,
  category
}: Props) => {
  const handleChange = (permission: number) => {
    if (item.target_doc_id) {
      const newItems = items?.map((permissionItem: PermissionEntity) =>
        permissionItem.target_doc_id === item.target_doc_id
          ? { ...permissionItem, permission }
          : permissionItem
      );

      onChangeAccess(newItems, category);
    } else {
      const newItems = items?.map((permissionItem: PermissionEntity) => ({
        ...permissionItem,
        permission
      }));

      onChangeAccess(newItems, category);
    }
  };

  return (
    <Select
      style={{ width: 240 }}
      defaultValue={item.permission}
      placeholder="Select Access"
      onChange={handleChange}
    >
      {permissions.map((permission: AccessOption) => (
        <Select.Option key={permission.value} value={permission.value}>
          {permission.label}
        </Select.Option>
      ))}
    </Select>
  );
};
