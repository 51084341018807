import { accessQueries as access } from "./access";
import { actionsQueries as actions } from "./actions";
import { contentQueries as content } from "./content";
import { contentTypeQueries as contentType } from "./content-type";
import { formsQueries as forms } from "./forms";
import { localesQueries as locales } from "./locales";
import { mediaQueries as media } from "./media";
import { settingsQueries as settings } from "./settings";

export const queries = {
  access,
  actions,
  content,
  contentType,
  forms,
  locales,
  media,
  settings
};
