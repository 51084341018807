import { AxiosInstance } from "axios";
import { Session } from "types";

interface AppendAxiosHeadersProps {
  axiosInstance: AxiosInstance;
  headers?: Record<string, any>;
  session?: Session | null;
  serviceToken?: string | null;
}

export const appendAxiosHeaders = ({
  axiosInstance,
  headers = {},
  session,
  serviceToken
}: AppendAxiosHeadersProps): AxiosInstance => {
  axiosInstance.defaults.headers["Content-Type"] = "application/json";

  for (const header in headers) {
    axiosInstance.defaults.headers[header] = headers[header];
  }

  if (session)
    axiosInstance.defaults.headers[
      "Authorization"
    ] = `Token ${session.tokens.access}`;

  if (serviceToken)
    axiosInstance.defaults.headers["Saas-app-token"] = serviceToken;

  return axiosInstance;
};

export const getService = (apiConfig, serviceType) => {
  if (!apiConfig) {
    throw new Error(
      "Services configuration was not set, please check Bootstrap component"
    );
  }

  const service = apiConfig[serviceType];
  if (!service) {
    throw new Error(`Service config missing for ${serviceType}`);
  }

  return service;
};

export const queryParamsWrapper =
  <T = any>(fn: (query: string | URLSearchParams) => T) =>
  (filters?: Record<string, string>) => {
    const params = new URLSearchParams(filters);
    const query = !params?.keys().next().done ? `?${params}` : params;

    return fn(query);
  };

export const preventQueryRefetch = () => ({
  refetchOnMount: false,
  refetchOnWindowFocus: false,
  staleTime: Infinity,
  cacheTime: Infinity
});
