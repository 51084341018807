import React, { useCallback, useContext, useRef } from "react";
import { Form, Modal } from "antd";
import { ModalProps } from "antd/lib/modal";

import { FieldsValidationContextValue } from "../../FieldsValidation";
import FormBuilder from "../../FormBuilder";
import { FormBuilderContext } from "../../context";
import { FormField } from "../../interface";
import { FormFieldProps } from "../interface";

interface RepeaterModalProps extends ModalProps, FormFieldProps {
  formId: string;
  setValidation: React.Dispatch<
    React.SetStateAction<FieldsValidationContextValue | undefined>
  >;
}

// eslint-disable-next-line react/display-name
export const RepeaterModal = React.forwardRef<
  HTMLDivElement,
  RepeaterModalProps
>(
  (
    { currentItem, data, onChange, className, formId, setValidation, ...props },
    ref
  ) => {
    const { initialValue: initialValueData, name } = data;

    const { builderProps } = useContext(FormBuilderContext);

    const refItems = useRef(builderProps?.items);
    const refValue = useRef<unknown>(
      initialValueData
        ? Array.isArray(initialValueData)
          ? initialValueData
          : [initialValueData]
        : []
    );

    const handleChange = useCallback(
      (data) => {
        onChange?.(undefined, {
          ...currentItem?.properties,
          mapping: data
        });
      },
      [currentItem?.properties, onChange]
    );

    return (
      <Modal
        width="90%"
        centered
        style={{ top: 20 }}
        title={currentItem?.label || currentItem?.fieldType}
        {...props}
      >
        <div ref={ref}>
          <Form.Item name={name} noStyle initialValue={refValue.current}>
            <FormBuilder
              formId={formId}
              formData={refValue.current as FormField[]}
              items={refItems.current}
              onChange={handleChange}
              setValidation={setValidation}
            />
          </Form.Item>
        </div>
      </Modal>
    );
  }
);
