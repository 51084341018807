import React, { createContext, useReducer, useContext } from "react";

import { ContentType, ContentTypeTuple } from "types";
import { showErrorMessage } from "utils";

// Reducer
export interface ContentTypeState {
  contentTypes: ContentType[];
  contentTypesTuple: ContentTypeTuple[];
}

export enum ReducerActionType {
  SET_CONTENT_TYPES,
  SET_CONTENT_TYPES_TUPLE
}

export interface ReducerAction {
  type: ReducerActionType;
  contentTypes?: ContentType[];
  contentTypesTuple?: ContentTypeTuple[];
}

export const initialState: ContentTypeState = {
  contentTypes: [],
  contentTypesTuple: []
};

export const reducer = (
  state: ContentTypeState,
  action: ReducerAction
): ContentTypeState => {
  switch (action.type) {
    case ReducerActionType.SET_CONTENT_TYPES: {
      if (!action.contentTypes) {
        throw new Error("Content types array missing");
      }

      return {
        ...state,
        contentTypes: action.contentTypes
      };
    }

    case ReducerActionType.SET_CONTENT_TYPES_TUPLE: {
      if (!action.contentTypesTuple) {
        throw new Error("Content types array missing");
      }

      return {
        ...state,
        contentTypesTuple: action.contentTypesTuple
      };
    }

    default:
      throw new Error("Unexpected action type");
  }
};

// Context
const ContentTypeStateContext = createContext<ContentTypeState>({
  contentTypes: [],
  contentTypesTuple: []
});

type Dispatch = (action: ReducerAction) => void;

const ContentTypeDispatchContext = createContext<Dispatch>(() => {});

const ContentTypeProvider: React.FC<React.PropsWithChildren<{}>> = ({
  children
}) => {
  const [state, dispatch] = useReducer(reducer, initialState);

  return (
    <ContentTypeStateContext.Provider value={state}>
      <ContentTypeDispatchContext.Provider value={dispatch}>
        {children}
      </ContentTypeDispatchContext.Provider>
    </ContentTypeStateContext.Provider>
  );
};

// Hooks
const useContentTypeState = () => {
  const context = useContext(ContentTypeStateContext);

  if (context === undefined) {
    throw new Error(
      "useContentTypeState must be used within a ContentTypeProvider"
    );
  }
  return context;
};

const useContentTypeDispatch = () => {
  const context = useContext(ContentTypeDispatchContext);

  if (context === undefined) {
    throw new Error(
      "useContentTypeDispatch must be used within a ContentTypeProvider"
    );
  }

  return context;
};

const useContentType = (): [ContentTypeState, Dispatch] => {
  return [useContentTypeState(), useContentTypeDispatch()];
};

const getContentTypes = async (
  dispatch: Dispatch,
  fetchContentType: Promise<ContentType[] | null>
) => {
  try {
    const contentTypeList = await fetchContentType;

    if (contentTypeList !== null) {
      dispatch({
        type: ReducerActionType.SET_CONTENT_TYPES,
        contentTypes: contentTypeList
      });

      // Set content type tuple with id and name
      const tuples = contentTypeList.map((contentType) => ({
        key: contentType.id,
        title: contentType.name
      }));

      dispatch({
        type: ReducerActionType.SET_CONTENT_TYPES_TUPLE,
        contentTypesTuple: tuples
      });

      return contentTypeList;
    }
  } catch (e) {
    showErrorMessage(e);
  }
};

export {
  ContentTypeProvider,
  useContentType,
  useContentTypeDispatch,
  useContentTypeState,
  getContentTypes
};
