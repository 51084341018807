import React, { useContext, useEffect, useState } from "react";

import { Form, Collapse, CollapseProps } from "antd";
import { NamePath } from "antd/lib/form/interface";

import { CollapseEmitterContext } from "../../../contexts";
import { FormField } from "../interface";
import { renderFormItem } from "./render-form-item";

interface ComponentFieldCollapseProps extends CollapseProps {
  name: NamePath;
  label: React.ReactNode;
  initialValue: any;
  helpText?: string;
  subFormFields: FormField[];
  panelExtra?: React.ReactNode;
  parentName?: NamePath;
}

const KEY = "key";

const ComponentFieldCollapse: React.FC<ComponentFieldCollapseProps> = ({
  name,
  label,
  initialValue,
  helpText,
  subFormFields,
  panelExtra,
  parentName,
  ...props
}) => {
  // Collapse should be opened by default if there is no initial value
  const defaultActiveKey =
    initialValue instanceof Object &&
    Object.values(initialValue).some((value) => value)
      ? undefined
      : KEY;

  const [activeKey, setActiveKey] = useState<string | undefined>(
    defaultActiveKey
  );

  const collapseEmitter = useContext(CollapseEmitterContext);

  useEffect(() => {
    const subscriptions = [
      collapseEmitter.subscribe("collapse", () => {
        setActiveKey(undefined);
      }),
      collapseEmitter.subscribe("expand", () => {
        setActiveKey(KEY);
      })
    ];

    collapseEmitter.emit("subscription-change");

    return () => {
      subscriptions.forEach((sub) => sub.unsubscribe());
      collapseEmitter.emit("subscription-change");
    };
  }, [collapseEmitter]);

  return (
    <Collapse
      {...props}
      activeKey={activeKey}
      onChange={(key) => setActiveKey(Array.isArray(key) ? key[0] : key)}
      defaultActiveKey={defaultActiveKey}
      style={{ marginBottom: 16 }}
    >
      <Collapse.Panel
        forceRender
        header={
          <>
            <div>{label}</div>
            {helpText && (
              <div>
                <small>{helpText}</small>
              </div>
            )}
          </>
        }
        extra={panelExtra}
        key={KEY}
      >
        {/* Set initial value to field */}
        <Form.Item name={name} noStyle initialValue={initialValue} />

        <Form.Item
          noStyle
          dependencies={[name]}
          // eslint-disable-next-line react/no-children-prop
          children={(form) =>
            subFormFields.map((field, idx) => (
              <React.Fragment key={idx}>
                {renderFormItem(field, name, form, parentName)}
              </React.Fragment>
            ))
          }
        />
      </Collapse.Panel>
    </Collapse>
  );
};

export default ComponentFieldCollapse;
