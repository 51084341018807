import { useEffect, useState } from "react";
import { useParams, useNavigate, useLocation } from "react-router-dom";
import { Button, message, Space, Spin, Row, Col, Typography } from "antd";
import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import dayjs, { Dayjs } from "dayjs";

import api, { queries } from "api";
import { preventQueryRefetch } from "api/utils";
import { CKEDITOR_FILE_PATH } from "app-constants";
import { FormGenerator } from "components/FormBuilder";
import { FormSchema } from "components/FormBuilder/interface";
import {
  ContentPublishDate,
  ContentFormStatus,
  ContentTypesProvider
} from "features/content/components";
import { NoFieldsFallback } from "features/content/components/NoFieldsFallback";
import { useApiConfig } from "hooks";
import { contentMessages, showErrorMessage, Status, StatusType } from "utils";

const formId = "form-generator";

export const ContentFormPage = () => {
  const { typeId, id } = useParams();
  const navigate = useNavigate();
  const location = useLocation();
  const queryClient = useQueryClient();
  const apiConfig = useApiConfig();
  const ckeditorFilePath =
    process.env.PUBLIC_URL + "/" + apiConfig.appToken + CKEDITOR_FILE_PATH;

  const [status, setStatus] = useState<StatusType>(Status.DRAFT);
  const [publishDate, setPublishDate] = useState<Dayjs | null>(null);

  const { data: formTabsData, isFetching: isFetchingTabs } = useQuery(
    queries.content.formTabs(typeId)
  );

  const {
    data,
    isFetching,
    status: contentStatus,
    error
  } = useQuery({
    ...queries.content.byId(typeId, id),
    enabled: !!id,
    ...preventQueryRefetch()
  });

  useEffect(() => {
    if (contentStatus === "success" && data?.status) {
      setStatus(data.status);
      setPublishDate(data.publish_date ? dayjs(data.publish_date) : null);
    }

    if (contentStatus === "error") {
      showErrorMessage(error);
    }
  }, [contentStatus, data?.publish_date, data?.status, error]);

  const submitMutation = useMutation(
    (formData: FormData) => {
      const updateData = {
        status,
        data: formData,
        publish_date: publishDate?.toISOString()
      };
      return !!id
        ? api.content.update(typeId!, id!, updateData)
        : api.content.create(typeId!, updateData);
    },
    {
      onSuccess: (response) => {
        if (id) {
          message.success(contentMessages.contentSuccess("saved"));
        } else {
          message.success(contentMessages.contentSuccess("created"));
          navigate(`/content/edit/${response.type_id}/${response.id}`);
        }
        queryClient.invalidateQueries(
          queries.content.byId(typeId, id).queryKey
        );
        queryClient.invalidateQueries(["content"]);
      },
      onError: (error) => showErrorMessage(error)
    }
  );
  const handleChangeStatus = (newStatus: StatusType) => {
    setStatus(newStatus);

    if (newStatus === Status.PUBLISHED && publishDate?.isAfter(dayjs()) && id) {
      setPublishDate(dayjs());
    }
  };

  const handleChangePublishDate = (date: Dayjs | null) => {
    setPublishDate(date);

    if (!!id) {
      setStatus(Status.DRAFT);
    } else {
      if (
        date &&
        dayjs(date).isSame(dayjs(), "day") &&
        dayjs(date).isSame(dayjs(), "hour") &&
        dayjs(date).isSame(dayjs(), "minute")
      ) {
        setStatus(Status.PUBLISHED);
      } else {
        setStatus(Status.DRAFT);
      }
    }
  };

  return (
    <Spin spinning={isFetching || isFetchingTabs}>
      <Row
        {...(!id && { justify: "space-between" })}
        align="middle"
        gutter={15}
        wrap={false}
        className="mb-20"
      >
        <Col>
          <Space style={{ marginRight: "auto" }}>
            <ContentFormStatus status={status} onChange={handleChangeStatus} />
            <ContentPublishDate
              publishDate={publishDate}
              onChange={handleChangePublishDate}
            />
            {!!id && (
              <Button onClick={() => navigate(`/content/create/${typeId}`)}>
                Create new content
              </Button>
            )}
          </Space>
        </Col>

        {!!id && (
          <Col flex={1}>
            <Typography.Title className="m-0" level={5}>
              {location.state?.name || ""}
            </Typography.Title>
          </Col>
        )}

        <Col>
          <Space>
            <Button onClick={() => navigate("/content")}>Cancel</Button>
            <Button
              type="primary"
              htmlType="submit"
              form={formId}
              loading={submitMutation.isLoading}
            >
              Save
            </Button>
          </Space>
        </Col>
      </Row>

      {(id ? data : formTabsData?.length !== 0) && (
        <ContentTypesProvider>
          <FormGenerator
            key={id || typeId}
            formData={data}
            formId={formId}
            apiConfig={apiConfig}
            formConfig={{ ckeditorFilePath }}
            formSchema={formTabsData as FormSchema}
            onSubmit={(data) => submitMutation.mutate(data as FormData)}
          />
        </ContentTypesProvider>
      )}

      {formTabsData?.length === 0 && (
        <NoFieldsFallback contentTypeId={typeId} />
      )}
    </Spin>
  );
};
