import {
  createQueryKeys,
  mergeQueryKeys
} from "@lukemorales/query-key-factory";
import { AccessEntity, Users } from "types";

import { mainClient, axios } from "./axios";

const APP_TOKEN = process.env.REACT_APP_SAAS_TOKEN;

const access = {
  getAllUsers: async () => {
    const response = await mainClient.get<Users>(
      `/applications/user/${APP_TOKEN}/list/`
    );
    return response.data;
  },
  getUser: async (id: string) => {
    const response = await axios.get<AccessEntity[]>(
      `/access/access-user/${id}/`
    );
    return response.data;
  },
  updateUser: async (id: string | undefined, data: AccessEntity[]) => {
    const response = await axios.post<AccessEntity[]>(
      `/access/access-user/${id}/`,
      data
    );
    return response.data;
  }
};

const usersQueries = createQueryKeys("users", {
  all: () => ({
    queryKey: ["users"],
    queryFn: access.getAllUsers
  }),
  byId: (userId) => ({
    queryKey: ["users", userId],
    queryFn: () => access.getUser(userId)
  })
});

export const accessQueries = mergeQueryKeys(usersQueries);

export default access;
