import RadioButtons from "./RadioButtons";

export interface RadioButton {
  value: string;
  label: string;
}

export interface RadioButtonsProps {
  defaultValue: string;
  items: RadioButton[];
  onChange?: (value: string) => void;
}

export default RadioButtons;
