export const normalizeTitle = (title: string) => {
  return title.split("_").join(" ");
};

export const normalizeValue = (value: unknown) => {
  if (typeof value === "boolean") return value ? "Yes" : "No";
  if (
    value === undefined ||
    value === null ||
    (Array.isArray(value) && !value.length)
  )
    return "-";
  return JSON.stringify(value).replace(/(^"|"$)/g, "");
};
