import { Modal } from "antd";

import moment from "moment";
import { Media } from "types";

const { confirm } = Modal;

export interface TableContentType {
  id: string;
  key: string;
  preview: string;
  name: string;
  type: string;
  updatedDate: string;
  updatedBy: string;
  status: string;
}

export function normalizeMediaList(mediaList: Media[]): TableContentType[] {
  if (!mediaList) {
    return [];
  }

  return mediaList.map(
    ({ id, name, content_type, modified_at, modified_by, status, url }) => {
      const updatedDate = modified_at
        ? moment(modified_at).format("DD/MM/YYYY HH:mm")
        : "";

      const updatedBy = `${modified_by.first_name} ${modified_by.last_name}`;

      return {
        key: id,
        id,
        name,
        type: content_type,
        updatedDate,
        updatedBy,
        status,
        preview: url
      };
    }
  );
}

export const checkImageURL = (url: string) => {
  return url.toLowerCase().match(/\.(jpeg|jpg|gif|png)$/) != null;
};

// Confirm delete content type utils
export const showDeleteConfirm = (onOk: () => Promise<void>) => {
  const text = "There are no entries that link to this asset.";

  confirm({
    title: "Are you sure?",
    content: text,
    okText: "Yes, delete asset",
    okType: "danger",
    cancelText: "Cancel",
    okButtonProps: {
      className: "btn"
    },
    cancelButtonProps: {
      className: "btn btn-default"
    },
    onOk
  });
};
