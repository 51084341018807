import { Button, Dropdown, MenuProps } from "antd";
import { EllipsisOutlined } from "@ant-design/icons";

import { ColumnProps } from "antd/es/table";
import { TableSetting } from "features/settings/utils";

export const getTableColumns = (
  getActionColumnMenu?: (setting: TableSetting) => MenuProps["items"]
) => {
  const columns: ColumnProps<TableSetting>[] = [
    {
      title: "Code name",
      dataIndex: "codeName",
      key: "codeName"
    },
    {
      title: "Value",
      dataIndex: "value",
      key: "value"
    },
    {
      title: "Updated",
      dataIndex: "updatedDate",
      key: "updatedDate"
    }
  ];

  if (!!getActionColumnMenu) {
    columns.push({
      title: "",
      dataIndex: "",
      key: "actions",
      align: "right",
      render: (_, item) => (
        <Dropdown
          placement="bottomRight"
          trigger={["click"]}
          menu={{ items: getActionColumnMenu(item) }}
        >
          <Button icon={<EllipsisOutlined />} type="text" />
        </Dropdown>
      )
    });
  }

  return columns;
};
