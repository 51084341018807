import React, { useEffect, useMemo, useState, useContext } from "react";
import { Button, Input, Menu, Modal, ModalProps } from "antd";

import { ContentTypesContext } from "components/FormBuilder/contexts";

export interface ContentTypeSelectModalProps
  extends Omit<ModalProps, "onOk" | "onCancel"> {
  onOk: (contentTypeId: string) => void;
  onCancel?: () => void;
}

const ContentTypeSelectModal: React.FC<ContentTypeSelectModalProps> = ({
  open,
  onOk,
  onCancel,
  ...props
}) => {
  const contentTypesContext = useContext(ContentTypesContext);

  const [search, setSearch] = useState("");
  const [selectedKey, setSelectedKey] = useState<string>();

  const filteredContentTypes = useMemo(
    () =>
      contentTypesContext?.contentTypes.filter((contentType) =>
        contentType.name.toLowerCase().includes(search.toLowerCase().trim())
      ) || [],
    [contentTypesContext?.contentTypes, search]
  );

  const handleArrowDown = () => {
    const idx = filteredContentTypes.findIndex(
      (contentType) => contentType.id === selectedKey
    );
    setSelectedKey(
      filteredContentTypes[(idx + 1) % filteredContentTypes.length]?.id
    );
  };

  const handleArrowUp = () => {
    let idx = filteredContentTypes.findIndex(
      (contentType) => contentType.id === selectedKey
    );
    idx = idx === -1 ? 0 : idx;
    setSelectedKey(
      filteredContentTypes[
        (idx - 1 + filteredContentTypes.length) % filteredContentTypes.length
      ]?.id
    );
  };

  const handleEnter = () => {
    if (selectedKey) {
      onOk(selectedKey);
    } else if (filteredContentTypes.length) {
      onOk(filteredContentTypes[0].id);
    }
  };

  const handleKeyUp = (e: React.KeyboardEvent<HTMLInputElement>) => {
    if (e.key === "ArrowUp") {
      handleArrowUp();
    }
    if (e.key === "ArrowDown") {
      handleArrowDown();
    }
    if (e.key === "Enter") {
      handleEnter();
    }
  };

  useEffect(() => {
    setSelectedKey(undefined);
  }, [filteredContentTypes]);

  // Reset states after closing modal
  useEffect(() => {
    if (open) {
      // cleanup
      return () => {
        setSearch("");
        setSelectedKey(undefined);
      };
    }
  }, [open]);

  return (
    <Modal
      open={open}
      title="Choose content type"
      onCancel={onCancel}
      footer={<Button onClick={onCancel}>Cancel</Button>}
      destroyOnClose
      {...props}
    >
      <Input
        onKeyUp={handleKeyUp}
        autoFocus
        placeholder="Search content type"
        value={search}
        onChange={(e) => setSearch(e.target.value)}
      />
      <small>
        While searching use up/down arrows to select and ↪ (Enter) to confirm,
        or click on the needed one
      </small>
      <Menu
        onClick={(e) => {
          onOk(e.key);
        }}
        selectedKeys={selectedKey ? [selectedKey] : []}
        items={[
          { type: "divider" },
          ...filteredContentTypes.map((contentType) => ({
            key: contentType.id,
            label: contentType.name
          }))
        ]}
      ></Menu>
    </Modal>
  );
};

export default ContentTypeSelectModal;
