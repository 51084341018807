import { GlobalToken } from "antd";
import { css } from "@emotion/react";

export const styles = (token: GlobalToken) => ({
  item: css`
    border: 1px dashed ${token.colorBorder};
    border-radius: 8px;
    display: flex;
    align-items: center;
    padding: 12px 10px;
    transition: all 0.3s linear;
    cursor: grab;
    //background-color: ${token.colorBgLayout};
    background-color: #f6f6f6;

    &:hover {
      border-color: ${token.colorPrimary};
      background-color: ${token.colorPrimary};
      box-shadow: 0px 15px 29.4px 0.6px rgba(53, 114, 176, 0.22),
        0px 2px 20px 0px rgba(53, 114, 176, 0.15);

      & * {
        color: ${token.colorWhite};
      }
    }
  `,
  icon: css`
    text-align: center;
    transition: color 0.3s linear;
    line-height: 9px;
    svg {
      color: ${token.colorTextTertiary};
      transition: color 0.3s linear;
      font-size: 18px;
    }
  `,
  title: css`
    text-align: center;
    transition: color 0.3s linear;
    font-size: 13px;
    color: ${token.colorText};
    font-weight: ${token.fontWeightStrong};
    margin: 0 0 0 12px;
    line-height: 18px;
  `
});
