import { createQueryKeys } from "@lukemorales/query-key-factory";
import queryString from "query-string";
import { FormTabs } from "components/FormBuilder";

import { ContentListResponse, ContentResponse, PinedContentType } from "types";
import { StatusType } from "utils";
import { axios } from "./axios";

export interface ContentFormData {
  data: any;
  status: StatusType;
  publish_date?: string;
}

const create = async (contentTypeId: string, data: ContentFormData) => {
  const response = await axios.post(
    `/content-type/model/crud/${contentTypeId}/`,
    data
  );
  return response.data;
};

const duplicate = async (contentTypeId: string, id: string) => {
  const response = await axios.post(
    `/content-type/model/duplicate/${contentTypeId}/${id}/`
  );
  return response.data;
};

const remove = async (contentTypeId: string, id: string) => {
  const response = await axios.delete(
    `/content-type/model/crud/${contentTypeId}/${id}/`
  );
  return response.data;
};

const update = async (
  contentTypeId: string,
  id: string,
  data: ContentFormData
) => {
  const response = await axios.put(
    `/content-type/model/crud/${contentTypeId}/${id}/`,
    data
  );
  return response.data;
};

const getById = async (contentTypeId: string, id: string) => {
  const response = await axios.get<ContentResponse>(
    `/content-type/model/crud/${contentTypeId}/${id}/`
  );
  return response.data;
};

const getPinned = async () => {
  const response = await axios.get<PinedContentType[]>("/content-type/pined/");
  return response.data;
};

const search = async (params) => {
  const response = await axios.get<ContentListResponse>(
    queryString.stringifyUrl({
      url: "/content-type/model/list/",
      query: params
    })
  );
  return response.data;
};

const getFormTabs = async (contentTypeId: string) => {
  const response = await axios.get<FormTabs[]>(
    `/content-type/build-form/${contentTypeId}/`
  );
  return response.data;
};

const content = {
  create,
  remove,
  update,
  duplicate,
  getById,
  getPinned,
  search,
  getFormTabs
};

export const contentQueries = createQueryKeys("content", {
  search: (params) => ({
    queryKey: [params],
    queryFn: () => search(params)
  }),
  byId: (contentTypeId, contentId) => ({
    queryKey: [contentTypeId, contentId],
    queryFn: () => getById(contentTypeId, contentId)
  }),
  pinned: () => ({
    queryKey: ["pinned"],
    queryFn: () => getPinned()
  }),
  formTabs: (contentTypeId) => ({
    queryKey: ["form-tabs", contentTypeId],
    queryFn: () => getFormTabs(contentTypeId)
  })
});

export default content;
