// tslint:disable max-line-length
import React from "react";

const SingleLine = () => (
  <svg width="1em" height="1em" viewBox="0 0 32 24">
    <path
      fillRule="evenodd"
      fill="currentColor"
      d="M30.718,19.432 L10.662,19.432 L10.662,21.994 L11.338,21.994 C11.675,21.994 11.949,22.258 11.949,22.584 L11.949,23.410 C11.949,23.736 11.675,24.000 11.338,24.000 L10.662,24.000 L8.586,24.000 L7.910,24.000 C7.573,24.000 7.300,23.736 7.300,23.410 L7.300,22.584 C7.300,22.258 7.573,21.994 7.910,21.994 L8.586,21.994 L8.586,19.432 L1.282,19.431 C0.575,19.431 -0.000,18.876 -0.000,18.193 L-0.000,5.807 C-0.000,5.124 0.575,4.568 1.282,4.568 L8.586,4.568 L8.586,2.006 L7.910,2.006 C7.573,2.006 7.300,1.742 7.300,1.416 L7.300,0.590 C7.300,0.264 7.573,-0.000 7.910,-0.000 L8.586,-0.000 L10.662,-0.000 L11.338,-0.000 C11.675,-0.000 11.949,0.264 11.949,0.590 L11.949,1.416 C11.949,1.742 11.675,2.006 11.338,2.006 L10.662,2.006 L10.662,4.568 L30.718,4.568 C31.425,4.568 32.000,5.124 32.000,5.807 L32.000,18.193 C32.000,18.876 31.425,19.432 30.718,19.432 ZM2.565,7.046 L2.565,16.954 L8.586,16.954 L8.586,7.046 L2.565,7.046 ZM29.435,7.046 L10.662,7.046 L10.662,16.954 L29.435,16.954 L29.435,7.046 Z"
    />
  </svg>
);

export default SingleLine;
