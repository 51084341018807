import React from "react";
import { nanoid } from "nanoid";

const make0ToNArray = (length: number) => Array.from(Array(length).keys());

interface OrderItem {
  /**
   * Initial index
   */
  index: number;
  id: string;
}

/**
 * Provide helpers for keeping the preview position in an array while reordering using drag and drop
 * Example: We moved third element on the first position the order will look like
 * [2, 0, 1, 3, 4, 5]
 */
export function useListOrder(initialListLength = 0) {
  const [currentOrder, setCurrentOrder] = React.useState<OrderItem[]>([]);

  React.useLayoutEffect(() => {
    setCurrentOrder(
      make0ToNArray(initialListLength).map((index) => ({
        index,
        id: nanoid()
      }))
    );
  }, [initialListLength]);

  return {
    currentOrder: currentOrder,
    handleAdd: () => {
      setCurrentOrder((prev) => [
        ...prev,
        {
          id: nanoid(),
          index: prev.length
        }
      ]);
    },
    handleRemove: (index: number) => {
      setCurrentOrder((prev) => {
        let newValue = [...prev];
        newValue.splice(index, 1);
        newValue = newValue.map((item, index) => ({ ...item, index })); // reset indexes
        return newValue;
      });
    },
    handleMovePreview: (dragIndex: number, hoverIndex: number) => {
      setCurrentOrder((prev) => {
        const newValue = [...prev];
        newValue.splice(dragIndex, 1);
        newValue.splice(hoverIndex, 0, prev[dragIndex]);
        return newValue;
      });
    },
    handleFinish: () => {
      /**
       * Reset order
       */
      setCurrentOrder((prev) =>
        prev.map((item, index) => ({ ...item, index }))
      );
    }
  };
}
