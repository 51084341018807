// tslint:disable max-line-length
import React from "react";

const MultipleReference = () => (
  <svg width="1em" height="1em" viewBox="0 0 512 512">
    <path
      fillRule="evenodd"
      fill="currentColor"
      d="m512 322v-134h-134v48h-55v-47h-134v47h-55v-48h-46v-54h46v-134h-134v134h48v54h-48v134h134v-46h55v47h47v55h-47v134h134v-48h55v48h134v-134h-134v46h-55v-46h-47v-55h47v-47h55v46zm-472-282h54v54h-54zm54 242h-54v-54h54zm324 136h54v54h-54zm-135 54h-54v-54h54zm0-189h-54v-54h54zm135-55h54v54h-54zm0 0"
    />
  </svg>
);

export default MultipleReference;
