import { Tag } from "antd";
import { statusesType, StatusType } from "utils";

type Props = {
  status: StatusType;
};

const Status = ({ status }: Props) => {
  return (
    <Tag color={statusesType[status].color}>{statusesType[status].label}</Tag>
  );
};

export default Status;
