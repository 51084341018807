// tslint:disable max-line-length
import React from "react";

const Drag = () => (
  <svg width="1em" height="1em" viewBox="0 0 4 16">
    <path
      fillRule="evenodd"
      fill="currentColor"
      d="M2.000,10.000 C0.900,10.000 -0.000,9.100 -0.000,8.000 C-0.000,6.900 0.900,6.000 2.000,6.000 C3.100,6.000 4.000,6.900 4.000,8.000 C4.000,9.100 3.100,10.000 2.000,10.000 ZM2.000,4.000 C0.900,4.000 -0.000,3.100 -0.000,2.000 C-0.000,0.900 0.900,-0.000 2.000,-0.000 C3.100,-0.000 4.000,0.900 4.000,2.000 C4.000,3.100 3.100,4.000 2.000,4.000 ZM2.000,12.000 C3.100,12.000 4.000,12.900 4.000,14.000 C4.000,15.100 3.100,16.000 2.000,16.000 C0.900,16.000 -0.000,15.100 -0.000,14.000 C-0.000,12.900 0.900,12.000 2.000,12.000 Z"
    />
  </svg>
);

export default Drag;
