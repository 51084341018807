import { LabeledValue } from "antd/lib/select";
import fetch from "cross-fetch";
import queryString from "query-string";
import { ApiConfig } from "types";

interface DataProps {
  type_id?: string;
  include?: string[];
  search?: string;
}

export const fetchReferenceOptions = async (
  api: ApiConfig,
  props: { [key: string]: any },
  data?: DataProps
): Promise<LabeledValue[] | null> => {
  const { source } = props;

  if (!source) {
    throw new Error("Reference field doesn't have source property!");
  }

  const reqInit: RequestInit = {
    method: "GET",
    headers: {
      Authorization: `Token ${api.tokens?.access}`
    }
  };

  let response: LabeledValue[] = [];
  const queryData = {
    ...data,
    type_id: source?.options?.data?.type_id
  };

  const query = queryString.stringify(queryData, { skipNull: true });

  try {
    const res = await fetch(
      `${api.baseUrl}${source?.options?.endpoint}?${query}`,
      reqInit
    );

    if (res.ok) {
      response = await res.json();
    }
  } catch (e) {
    throw e;
  }

  return response;
};
