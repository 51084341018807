import moment from "moment";
import { FormResponse } from "types";

export interface TableFormResponse {
  id: string;
  key: string;
  created: string;
}

export function normalizeFormResponseList(
  formResponses?: FormResponse[]
): TableFormResponse[] {
  if (!formResponses) return [];

  return formResponses.map(
    ({
      id,
      created_at,
      publish_date,
      modified_at,
      status,
      type_id,
      ...rest
    }) => {
      const created = modified_at
        ? moment(modified_at).format("DD/MM/YYYY HH:mm")
        : "";

      return {
        id,
        key: id,
        created,
        ...rest
      };
    }
  );
}
