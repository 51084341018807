import { useMemo } from "react";
import { useLocation } from "react-router-dom";
import queryString from "query-string";

export const useActivePathname = () => {
  const { pathname, search } = useLocation();
  const searchParam = queryString.parse(search);

  return useMemo(() => {
    const activeKeys = pathname.split("/").filter(Boolean);
    if (typeof searchParam.type === "string") {
      activeKeys.push(searchParam.type);
    }

    return activeKeys;
  }, [pathname, searchParam.type]);
};
