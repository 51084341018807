import React from "react";

import { MenuProps, Table } from "antd";
import { TableLocales, normalizeLocalesList } from "features/locales/utils";
import { Locale } from "types";

import getTableColumns from "./tableColumns";

type ContentListTableProps = {
  locales: Locale[];
  columns: (content: TableLocales) => MenuProps["items"];
  loading?: boolean;
};

const LocalesPageTable = ({
  locales,
  columns,
  loading
}: ContentListTableProps) => {
  const tableColumns = getTableColumns(columns);
  const data = normalizeLocalesList(locales);

  return (
    <Table
      loading={loading}
      columns={tableColumns}
      dataSource={data}
      pagination={{ hideOnSinglePage: true, defaultPageSize: 100 }}
      bordered
    />
  );
};

export default LocalesPageTable;
