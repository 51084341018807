import { Link, Location } from "react-router-dom";
import { Button, Dropdown, MenuProps } from "antd";
import { EllipsisOutlined } from "@ant-design/icons";
import { ColumnProps } from "antd/es/table";

import Status from "components/Status";

import { File } from "./File";
import { TableContentType, checkImageURL } from "./utils";

export const getTableColumns = (
  getActionColumnMenu: (media: TableContentType) => MenuProps["items"],
  location: Location
): ColumnProps<TableContentType>[] => [
  {
    title: "Preview",
    dataIndex: "preview",
    key: "preview",
    render: (_: string, item: TableContentType) => {
      const isImage = checkImageURL(item.preview);

      return (
        <div
          style={{ alignContent: "center" }}
          className="d-flex justify-content-center"
        >
          {isImage ? (
            <img
              style={{ maxHeight: 44, maxWidth: 88 }}
              src={item.preview}
              alt={item.name}
            />
          ) : (
            <File size={3} />
          )}
        </div>
      );
    }
  },
  {
    title: "Name",
    dataIndex: "name",
    key: "name",
    render: (name: string, item: TableContentType) => {
      return (
        <Link
          to={`/media-form/edit/${item.id}`}
          state={{ background: location }}
        >
          {name}
        </Link>
      );
    }
  },
  {
    title: "Type",
    dataIndex: "type",
    key: "type"
  },
  {
    title: "Updated",
    dataIndex: "updatedDate",
    key: "updatedDate"
  },
  {
    title: "By",
    key: "updatedBy",
    dataIndex: "updatedBy"
  },
  {
    title: "Status",
    dataIndex: "status",
    key: "status",
    render: (status) => <Status status={status} />
  },
  {
    title: "",
    dataIndex: "",
    align: "right",
    key: "actions",
    render: (_, item) => (
      <Dropdown
        placement="bottomRight"
        trigger={["click"]}
        menu={{ items: getActionColumnMenu(item) }}
      >
        <Button icon={<EllipsisOutlined />} type="text" />
      </Dropdown>
    )
  }
];
