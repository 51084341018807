export interface PageSettings {
  current: number;
  size: number;
}
export interface FilterState {
  type?: string;
  status?: string;
  searchTerm?: string;
  page?: string | number;
  pageSettings?: PageSettings;
}

export enum Filters {
  SORT = "type",
  FILTER = "status",
  SEARCH = "searchTerm",
  PAGE = "page",
  PAGE_SETTINGS = "pageSettings"
}
