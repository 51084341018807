import React from "react";

import { FallBack } from ".";

interface ErrorBoundaryState {
  hasError: boolean;
}

export interface ErrorBoundaryProps extends React.PropsWithChildren {
  logger?: ErrorHandlerFunc;
}

type ErrorHandlerFunc = (error: Error, errorInfo: React.ErrorInfo) => void;
const defaultLogger: ErrorHandlerFunc = (error, errorInfo) => {
  if (process.env.NODE_ENV !== "production") {
    console.error(error);
    console.log(errorInfo);
  }
};

export class ErrorBoundary extends React.PureComponent<
  ErrorBoundaryProps,
  ErrorBoundaryState
> {
  constructor(props: ErrorBoundaryProps) {
    super(props);
    this.state = { hasError: false };
  }

  public static defaultProps: ErrorBoundaryProps = {
    logger: defaultLogger
  };

  public static getDerivedStateFromError(): Partial<ErrorBoundaryState> {
    return { hasError: true };
  }

  public componentDidCatch(error: Error, errorInfo: React.ErrorInfo): void {
    if (typeof this.props.logger === "function") {
      this.props.logger(error, errorInfo);
    }
  }

  public render(): React.ReactNode {
    if (!this.state.hasError) {
      return this.props.children;
    }

    return <FallBack resetError={() => this.setState({ hasError: false })} />;
  }
}
