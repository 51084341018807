// tslint:disable max-line-length
import React from "react";

const Layout = () => (
  <svg width="1em" height="1em" viewBox="0 0 409.6 409.6">
    <path
      fillRule="evenodd"
      fill="currentColor"
      d="M392.535,0H256c-9.385,0-17.065,7.685-17.065,17.065v102.4c0,9.385,7.68,17.07,17.065,17.07h51.2V256H170.665v-34.135
c0-9.38-7.68-17.065-17.065-17.065h-51.2v-68.265h51.2c9.385,0,17.065-7.685,17.065-17.07v-102.4C170.665,7.685,162.985,0,153.6,0
H17.065C7.685,0,0,7.685,0,17.065v102.4c0,9.385,7.685,17.07,17.065,17.07h51.2V204.8h-51.2C7.685,204.8,0,212.485,0,221.865
v102.4c0,9.385,7.685,17.07,17.065,17.07H153.6c9.385,0,17.065-7.685,17.065-17.07v-34.13H307.2V409.6h34.135V136.535h51.2
c9.38,0,17.065-7.685,17.065-17.07v-102.4C409.6,7.685,401.915,0,392.535,0z M34.135,102.4V34.135h102.4V102.4H34.135z
M136.535,307.2h-102.4v-68.265h102.4V307.2z M375.465,102.4h-102.4V34.135h102.4V102.4z"
    />
  </svg>
);

export default Layout;
