import { PermissionType } from "types";

export interface AccessOption {
  value: PermissionType;
  label: string;
}

export const permissions: AccessOption[] = [
  {
    value: 0,
    label: "None"
  },
  {
    value: 1,
    label: "Read"
  },
  {
    value: 2,
    label: "Create"
  },
  {
    value: 4,
    label: "Edit"
  },
  {
    value: 8,
    label: "Design"
  }
];
