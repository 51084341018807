import { createQueryKeys } from "@lukemorales/query-key-factory";
import { Media, MediaData, MediaEditResponse, Paginated } from "types";
import { axios } from "./axios";

const create = async (data: MediaData) => {
  const response = await axios.post("/media/create/", data);
  return response.data;
};

const search = async (params?: string) => {
  const response = await axios.get<Paginated<Media[]>>(
    `/media/list/?${params}`
  );
  return response.data;
};

const getById = async (id: string) => {
  const response = await axios.get<MediaEditResponse>(`/media/get/${id}/`);
  return response.data;
};

const update = async (id: string, data: MediaData) => {
  const response = await axios.put(`/media/edit/${id}/`, data);
  return response.data;
};

const remove = async (id: string) => {
  const response = await axios.delete(`/media/delete/${id}/`);
  return response.data;
};

const media = {
  create,
  search,
  getById,
  update,
  remove
};

export const mediaQueries = createQueryKeys("locales", {
  search: (params) => ({
    queryKey: [params],
    queryFn: () => search(params)
  }),
  byId: (mediaId) => ({
    queryKey: [mediaId],
    queryFn: () => getById(mediaId)
  })
});

export default media;
