import { Space, Typography } from "antd";

import { AccessUsersTable } from "features/access/components";

export const AccessPage = () => {
  return (
    <>
      <Space className="d-flex justify-content-between mb-20">
        <Typography.Title level={5} className="mb-0">
          Users
        </Typography.Title>
      </Space>
      <AccessUsersTable />
    </>
  );
};
