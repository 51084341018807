import { useMemo } from "react";

import { getLocalStorageTokens } from "api";

export const useApiConfig = () => {
  return useMemo(
    () => ({
      tokens: getLocalStorageTokens(),
      appToken: process.env.REACT_APP_SAAS_TOKEN,
      baseUrl: process.env.REACT_APP_BASE_API
    }),
    []
  );
};
