import React from "react";
import { Form, Collapse } from "antd";

import StandaloneFieldError from "../../StandaloneFieldError";

import { FormField } from "../../interface";
import { FormFieldProps } from "../interface";
import { requiredListRule } from "../utils";
import DnDList from "./DnDList";

const RepeaterField: React.FC<FormFieldProps> = ({ data }) => {
  const { name, label, initialValue, properties } = data;
  const subFormFields: FormField[] = properties?.mapping;
  const required = properties.required;

  if (!subFormFields)
    return (
      <h4>
        Type <code>{data.type}</code> with <code>{name}</code> does not have the
        needed <code>mapping</code> field in <code>properties</code>. Please
        contact support!
      </h4>
    );

  // Collapse should be opened by default if there is no initial value
  const defaultActiveKey =
    Array.isArray(initialValue) && initialValue.length > 0 ? undefined : name;

  return (
    <Collapse
      className="mb-12"
      defaultActiveKey={defaultActiveKey}
      items={undefined}
    >
      {/* Need to forceRerender because there Form.Item's inside the collapse and when clicking submit they need to be sent too */}
      <Collapse.Panel
        forceRender
        header={
          <>
            <div>{label}</div>
            {data.properties?.help_text && (
              <div>
                <small>{data.properties?.help_text}</small>
              </div>
            )}
            <StandaloneFieldError name={name} />
          </>
        }
        key={name}
        style={{ marginBottom: 16 }}
      >
        <Form.Item
          dependencies={[name]}
          noStyle
          // eslint-disable-next-line react/no-children-prop
          children={(form) => (
            <Form.List
              initialValue={initialValue as any[]}
              name={name}
              rules={required ? [requiredListRule] : []}
              // eslint-disable-next-line react/no-children-prop
              children={(list, { add, remove }) => (
                <DnDList
                  name={name}
                  label={label}
                  listLength={list.length}
                  fields={subFormFields}
                  onAdd={() => add()}
                  onRemove={remove}
                  onReorder={(order) => {
                    const prevValue: any[] = form.getFieldValue(name);
                    form.setFieldValue(
                      name,
                      prevValue.map((_, i) => prevValue[order[i]])
                    );
                  }}
                />
              )}
            />
          )}
        />
      </Collapse.Panel>
    </Collapse>
  );
};

export default RepeaterField;
