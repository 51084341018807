import React from "react";
import classnames from "classnames";
import { ApiConfig } from "types";
import { APIContext, FormGeneratorContext } from "../context";
import { FormData, FormGeneratorContextValue, FormSchema } from "../interface";
import FormProperties from "./FormProperties";
import "./FormGenerator.css";

interface FormGeneratorProps {
  className?: string;
  formId: string;
  formData?: FormData | null;
  formSchema: FormSchema;
  onSubmit?: (formData: FormData) => void;
  onValuesChange?: (changedValues: FormData, values: FormData) => void;
  apiConfig?: ApiConfig;
  formConfig?: FormGeneratorContextValue;
}

/**
 * FormGenerator generate a form from formSchema and fill with data from formData
 */
const FormGenerator: React.FC<FormGeneratorProps> = ({
  apiConfig,
  className,
  formId,
  formData = null,
  formSchema,
  onSubmit,
  onValuesChange,
  formConfig = {}
}) => {
  return (
    <APIContext.Provider value={{ ...apiConfig }}>
      <FormGeneratorContext.Provider value={formConfig}>
        <div className={classnames("form-generator", className)}>
          <FormProperties
            formId={formId}
            data={formData}
            items={formSchema}
            onSubmit={onSubmit}
            onValuesChange={onValuesChange}
          />
        </div>
      </FormGeneratorContext.Provider>
    </APIContext.Provider>
  );
};

export default FormGenerator;
