import { useNavigate } from "react-router-dom";
import { Button } from "antd";

export const NoFieldsFallback = ({ contentTypeId }) => {
  const navigate = useNavigate();

  return (
    <div
      style={{ gap: 16 }}
      className="d-flex flex-column justify-content-center align-items-center vh-100"
    >
      <span>Content type does not have any fields</span>
      <Button
        type="primary"
        onClick={() => navigate(`/content-types/${contentTypeId}`)}
      >
        Edit Content Type
      </Button>
    </div>
  );
};
