import { useRef } from "react";
import { useDrag, useDrop } from "react-dnd";
import type { Identifier, XYCoord } from "dnd-core";

export interface UseDnDItemOptions {
  itemType: Identifier;
  index: number;
  onMovePreview: (dragIndex: number, hoverIndex: number) => void;
  onMoveFinish: () => void;
}

interface DragItem {
  index: number;
}

export function useDnDItem({
  itemType,
  index,
  onMovePreview,
  onMoveFinish
}: UseDnDItemOptions) {
  const ref = useRef<HTMLDivElement>(null);

  const [, drop] = useDrop<DragItem, void, { handlerId: Identifier | null }>({
    accept: itemType,

    hover(item, monitor) {
      if (!ref.current) {
        return;
      }
      const dragIndex = item.index;
      const hoverIndex = index;

      if (dragIndex === hoverIndex) return;

      const hoverBoundingRect = ref.current?.getBoundingClientRect();
      const hoverMiddleY =
        (hoverBoundingRect.bottom - hoverBoundingRect.top) / 2;
      const clientOffset = monitor.getClientOffset();
      const hoverClientY = (clientOffset as XYCoord).y - hoverBoundingRect.top;

      if (dragIndex < hoverIndex && hoverClientY < hoverMiddleY) return;
      if (dragIndex > hoverIndex && hoverClientY > hoverMiddleY) return;

      onMovePreview(dragIndex, hoverIndex);

      item.index = hoverIndex;
    },
    drop() {
      onMoveFinish();
    }
  });

  const [{ isDragging }, drag, preview] = useDrag({
    type: itemType,
    item: () => {
      return { index };
    },
    collect: (monitor: any) => ({
      isDragging: monitor.isDragging()
    })
  });

  return {
    itemRef: ref,
    isDragging,
    drag,
    preview,
    drop
  };
}
