import React, { useRef } from "react";
import { Form, Input, Button } from "antd";
import { PlusOutlined, CloseOutlined } from "@ant-design/icons";
import classNames from "classnames";

import { FormFieldProps } from "../interface";
import { helpMessage, normalize } from "../utils";
import "./SelectChoicesField.css";

interface SelectChoicesFieldProps {
  value?: string[][];
}

const SelectChoicesField: React.FC<
  FormFieldProps & SelectChoicesFieldProps
> = ({ data, className }) => {
  const [form] = Form.useForm();
  const { name, properties, initialValue, label } = data;
  const help = helpMessage(form, data);
  const listContainerRef = useRef<HTMLDivElement>(null);
  let normalizedInitialValue: string[][] = [];

  if (typeof initialValue === "string") {
    normalizedInitialValue = initialValue
      .split(/\r?\n/)
      .map((value) => [value, value]);
  } else if (
    initialValue &&
    typeof initialValue !== "boolean" &&
    typeof initialValue !== "number"
  ) {
    normalizedInitialValue = initialValue as string[][];
  }

  const scrollToBottom = (elementRef: typeof listContainerRef) => {
    const $el = elementRef.current;
    if (!$el) return;
    $el.scrollTop = $el.scrollHeight;
  };

  return (
    <Form.Item
      className={classNames("key-value-field", className)}
      label={label}
      normalize={normalize}
    >
      <Form.List initialValue={normalizedInitialValue} name={name}>
        {(fields, { add, remove }) => (
          <>
            <div ref={listContainerRef} className="key-value-field__list">
              {fields.map(({ key, name }) => {
                return (
                  <div key={key} className="key-value-field__input-container">
                    <Form.Item
                      name={[name, 0]}
                      rules={[
                        {
                          required: properties.required
                        }
                      ]}
                      className={className}
                      help={help}
                      noStyle
                    >
                      <Input
                        className="key-value-field__input"
                        placeholder="Key"
                      />
                    </Form.Item>
                    <Form.Item
                      name={[name, 1]}
                      rules={[
                        {
                          required: properties.required
                        }
                      ]}
                      className={className}
                      help={help}
                      noStyle
                    >
                      <Input
                        className="key-value-field__input"
                        placeholder="Value"
                      />
                    </Form.Item>
                    <CloseOutlined
                      className="key-value-field__remove-button"
                      onClick={() => remove(name)}
                    />
                  </div>
                );
              })}
            </div>
            <Form.Item>
              <Button
                type="dashed"
                className="key-value-field__add-button"
                onClick={() => {
                  add();
                  scrollToBottom(listContainerRef);
                }}
                block
                icon={<PlusOutlined />}
              >
                Add choice
              </Button>
            </Form.Item>
          </>
        )}
      </Form.List>
    </Form.Item>
  );
};

export default SelectChoicesField;
