import React, { useCallback, useState } from "react";

import { Modal, Select, Space } from "antd";

import { useQuery } from "@tanstack/react-query";
import { queries } from "api";
import { showErrorMessage } from "utils";

const { Option } = Select;

type LocalesPageModalProps = {
  visible: boolean;
  onOk: (value: string) => void;
  onCancel: () => void;
  defaultValue?: string;
};

/**
 * LocalesPageModal is used to add or edit a locale
 */
const LocalesPageModal = ({
  visible,
  onOk,
  onCancel,
  defaultValue
}: LocalesPageModalProps) => {
  const [selectedValue, setValue] = useState<string>("");

  // Fetch locales
  const { data: locales, isLoading } = useQuery({
    ...queries.locales.available(),
    onError: (error) => showErrorMessage(error)
  });

  const onChange = (value: string) => setValue(value);

  const handleOk = useCallback(() => {
    onOk(selectedValue);
  }, [onOk, selectedValue]);

  const renderOptions = () => {
    return (
      locales &&
      Object.entries(locales).map((locale: string[]) => {
        const label = `${locale[1]} (${locale[0]})`;

        return (
          <Option key={locale[0]} value={locale[0]}>
            {label}
          </Option>
        );
      })
    );
  };

  return (
    <Modal
      width={370}
      title={defaultValue ? "Edit locale" : "Add locale"}
      open={visible}
      onOk={handleOk}
      onCancel={onCancel}
      closable
      destroyOnClose
    >
      <Space>
        <span className="fw-500">Locales:</span>
        <Select
          style={{ width: "266px" }}
          loading={isLoading}
          showSearch
          defaultValue={defaultValue}
          placeholder="Select a locale"
          optionFilterProp="children"
          onChange={onChange}
          filterOption={(input: string, option) =>
            option?.props?.children
              .toLowerCase()
              .indexOf(input.toLowerCase()) >= 0
          }
        >
          {locales && renderOptions()}
        </Select>
      </Space>
    </Modal>
  );
};

export default LocalesPageModal;
