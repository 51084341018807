import { FormInstance } from "antd";
import moment, { MomentInput } from "moment";
import { ValidatorRule } from "rc-field-form/lib/interface";
import { FieldItemProps } from "./interface";

export const DATE_FORMAT = "YYYY-MM-DD";

export const helpMessage = (form: FormInstance, data: FieldItemProps) => {
  return form.getFieldError(data.name)
    ? form.getFieldError(data.name)
    : data.properties.help_text;
};

export const normalizeDateTime = (
  date: any,
  defaultNow: boolean
): MomentInput | null => {
  if (!date && defaultNow) {
    return moment(moment.now());
  }

  return date !== null ? moment(date) : date;
};

export const normalize = (value: any) => {
  if (String(value).trim() === "") {
    return null;
  }

  return value;
};

// Get step from decimal
// Example: if decimal = 3 then step = 0.001
export const getStepFromDecimal = (decimal = 0): number => {
  return decimal !== 0
    ? decimal / 10 / Number(String(decimal).padEnd(decimal, "0"))
    : 1;
};

// Normalize media value to return id
export const normalizeMedia = (media: any): string => {
  if (media && typeof media === "object") {
    return media.id;
  }
  return media;
};

// If properties has values, return prop with value
// if not, then don't return prop at all, because antd
// doesn't accept null value
interface Property {
  [key: string]: any;
}

const mapFieldProps: Property = {
  min_value: "min",
  max_value: "max",
  min_length: "minLength",
  max_length: "maxLength",
  placeholder: "placeholder",
  rows: "rows"
};

export const normalizeProperties = (properties: Property) => {
  const newProperties: Property = {};

  Object.keys(properties).forEach((prop) => {
    if (mapFieldProps[prop]) {
      newProperties[mapFieldProps[prop]] =
        properties[prop] === null ? undefined : properties[prop];
    }
  });

  return newProperties;
};

export const propertiesToRule = (properties: Property) => ({
  min: properties.min || properties.min_length,
  max: properties.max || properties.max_length
});

export const requiredListRule: ValidatorRule = {
  validator(_, value) {
    if (value?.length) return Promise.resolve();
    return Promise.reject("Add at least 1 item");
  }
};

// Check if url is valid
export const isValidURL = (url: string): boolean => {
  const pattern = new RegExp(
    "^(https?:\\/\\/)?" + // protocol
      "((([a-z\\d]([a-z\\d-]*[a-z\\d])*)\\.)+[a-z]{2,}|" + // domain name
      "((\\d{1,3}\\.){3}\\d{1,3}))" + // OR ip (v4) address
      "(\\:\\d+)?(\\/[-a-z\\d%_.~+]*)*" + // port and path
      "(\\?[;&a-z\\d%_.~+=-]*)?" + // query string
      "(\\#[-a-z\\d_]*)?$",
    "i"
  ); // fragment locator

  return !!pattern.test(url);
};
